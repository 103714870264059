import * as React from 'react';

import { Animation } from '../../layout';
import './partner-logos.scss';

interface PartnerLogosProperties {
  children: JSX.Element[];
}

export const PartnerLogos = ({ children }: PartnerLogosProperties) => (
  <div className="component-partnerlogos">{children}</div>
);

export const PartnerLogosAnimated = ({ children }: PartnerLogosProperties) => (
  <div className="component-partnerlogos">
    {children.map((logo, index) => (
      <Animation key={`logo-${index}`}>{logo}</Animation>
    ))}
  </div>
);
