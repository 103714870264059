import * as React from 'react';

import './custom-component-wrap.scss';

interface CustomComponentWrapProperties {
  className?: string[];
  style?: object;
  medium?: string;
  small?: string;
  children: JSX.Element | JSX.Element[];
}

export const CustomComponentWrap = ({
  className = [],
  medium,
  style,
  small,
  children,
}: CustomComponentWrapProperties) => (
  <div
    className={[
      'container',
      medium !== undefined ? 'container--medium' : '',
      small !== undefined ? 'container--small' : '',
      ...className,
    ]
      .filter(x => x)
      .join(' ')}
    style={style}
  >
    {children}
  </div>
);
