import { assoc } from 'ramda';
import { MarkdownRemarkElement } from '../../../interfaces';

export const IframeSizeTransformer = (element: MarkdownRemarkElement): MarkdownRemarkElement => {
  if (element.tagName !== 'iframe' || (element.properties && element.properties.height)) {
    return element;
  }

  const size = element.properties.wide !== undefined ? 400 : 300;
  element.properties = assoc('height', size, element.properties);
  return element;
};
