import { omit } from 'ramda';
import * as React from 'react';
import { Animation } from '../../components';
import { MarkdownRemark, MarkdownRemarkChild, MarkdownRemarkElement } from '../../interfaces';
import { alterClassNameProperty } from './alter-classname-property';
import { applyTransformers, TransformerConfig } from './apply-transformers';
import { isCustomComponent } from './is-custom-component';
import { renderCustomComponent } from './render-custom-component';

type isAnimateable = (element: React.DOMElement<any, any>) => boolean;

const animatableHtmlElements: isAnimateable[] = [
  element => element.type === 'a' && (element.props.className || '').includes('btn'),
  element => element.type.startsWith('h'),
];

const wrapWithAnimation = (element: React.DOMElement<any, any>) => {
  if (animatableHtmlElements.every(fn => !fn(element))) {
    return element;
  }
  return React.createElement(Animation, {} as any, element);
};

const renderHTMLElement = (element: MarkdownRemarkElement, config: MarkdownRemarkConfig) => {
  const childrens = element.children.map(renderRemarkChild(config));

  if (childrens.length > 0) {
    return React.createElement(
      element.tagName,
      alterClassNameProperty(element.properties),
      childrens,
    );
  }
  return React.createElement(element.tagName, alterClassNameProperty(element.properties));
};

const renderRemarkChild = (config: MarkdownRemarkConfig) => (
  element: MarkdownRemarkChild,
): React.ReactElement<any> | string | null => {
  if (element.type === 'element') {
    if (isCustomComponent(element)) {
      return renderCustomComponent(element, config, renderRemarkChild);
    }
    if (config.animation) {
      return wrapWithAnimation(renderHTMLElement(element, config));
    }
    return renderHTMLElement(element, omit(['animation'], config));
  }
  if (element.type === 'text') {
    return element.value;
  }

  return null;
};

export interface MarkdownRemarkConfig {
  animation?: boolean;
  customComponentAnimation?: boolean;
}

export const renderMarkdownRemark = (
  markdown: MarkdownRemark,
  config: MarkdownRemarkConfig & TransformerConfig,
) => {
  return (applyTransformers(markdown, config) as MarkdownRemark).children.map(
    renderRemarkChild(config),
  );
};
