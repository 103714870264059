import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { MarkdownRemarkRenderer } from '../../../utils';
import { LinkLocalizer } from '../../layout/link-localizer';

import './image-desc-overlay.scss';

interface ImageDescOverlyProperties {
  image: MarkdownRemark;
  title: MarkdownRemark;
  subtitle?: MarkdownRemark;
  darkTheme?: boolean;
  link?: string;
}

export const ImageDescOverlay = ({
  title,
  image,
  subtitle,
  darkTheme,
  link,
}: ImageDescOverlyProperties) => {
  if (link) {
    return (
      <LinkLocalizer className={`image-desc-overlay ${darkTheme ? 'theme-dark' : ''}`} to={link}>
        <MarkdownRemarkRenderer markdown={image} />
        <div className="image-desc-overlay-desc">
          <div className="image-desc-overlay-title">
            <MarkdownRemarkRenderer markdown={title} />
          </div>
          {subtitle && (
            <div className="image-desc-overlay-subtitle">
              <MarkdownRemarkRenderer markdown={subtitle} />
            </div>
          )}
        </div>
      </LinkLocalizer>
    );
  }
  return (
    <div className={`image-desc-overlay ${darkTheme ? 'theme-dark' : ''}`}>
      <MarkdownRemarkRenderer markdown={image} />
      <div className="image-desc-overlay-desc">
        <div className="image-desc-overlay-title">
          <MarkdownRemarkRenderer markdown={title} />
        </div>
        {subtitle && (
          <div className="image-desc-overlay-subtitle">
            <MarkdownRemarkRenderer markdown={subtitle} />
          </div>
        )}
      </div>
    </div>
  );
};
