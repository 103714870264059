interface SliderContentfulProperties {
  children: any[];
  'rotate-slides'?: boolean;
}

export const SliderContentfulPropertyTransformer = ({
  children,
  ...rest
}: SliderContentfulProperties) => {
  return {
    items: children,
    itemsPerSlide: 1,
    roateteSlides: rest['rotate-slides'],
    itemTemplate: SingleItemTemplate,
  };
};

const SingleItemTemplate = ({ items }) => items;
