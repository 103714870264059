import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { MarkdownRemarkRenderer } from '../../../utils';

import './column-features.scss';

export interface ColumnFeatureItem {
  icon: MarkdownRemark;
  title: MarkdownRemark;
  text: MarkdownRemark;
}

export interface ColumnFeaturesProperties {
  items: ColumnFeatureItem[];
}

const ColumnItem = React.forwardRef(({ item }: { item: ColumnFeatureItem }, ref) => (
  <div className="feature" ref={ref as any}>
    <div className="feature-icon">
      <MarkdownRemarkRenderer markdown={item.icon} />
    </div>
    <div className="feature-title">
      <MarkdownRemarkRenderer markdown={item.title} />
    </div>
    <div className="feature-text">
      <MarkdownRemarkRenderer markdown={item.text} />
    </div>
  </div>
));

export const ColumnFeatures = ({ items }: ColumnFeaturesProperties) => (
  <div className="component-columnfeatures box-shadow">
    {items.map((item, index) => (
      <ColumnItem item={item} key={index} />
    ))}
  </div>
);
