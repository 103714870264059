import * as React from 'react';
import './tabs.scss';
import * as Qs from 'query-string';
import { useQueryParam, StringParam } from 'use-query-params';

interface TabsProperties {
  children: JSX.Element[];
  tabs: string;
}

export const Tabs = ({ ...props }: TabsProperties) => {
  let currentState;
  let [tabParam] = useQueryParam('tab', StringParam);
  let initialTab;

  const tabs = props.tabs.split('|');

  tabs.forEach(tab => {
    if (tab.replace(/\s/g, '').toLowerCase() === tabParam) {
      initialTab = tab;
    }
  });

  if (!initialTab) {
    initialTab = tabs[0];
  }

  const [activeTab, setActiveTab] = React.useState(initialTab);

  const changeTab = tab => {
    setActiveTab(tab);
  };
  React.useEffect(() => {
    let activeTabEl = document.getElementsByClassName('active-tab')[0];
    let newActiveTabEl = document.getElementById(activeTab);

    if (!activeTabEl) {
      newActiveTabEl?.classList.add('active-tab');
    } else if (activeTabEl.innerHTML !== activeTab) {
      activeTabEl.classList.remove('active-tab');
      newActiveTabEl?.classList.add('active-tab');
    }
  });

  React.useEffect(() => {
    window.addEventListener('popstate', ev => {
      if (ev.state && currentState !== ev.state.key) {
        currentState = ev.state.key;
        let urlTab = Qs.parseUrl(location.search).query.tab;
        if (urlTab) {
          tabs.forEach(tab => {
            if (tab.replace(/\s/g, '').toLowerCase() === urlTab) {
              setActiveTab(tab);
            }
          });
        }
      }
    });
  });

  return (
    <div className={`component-tabs`}>
      <ul id="tabs" className={'tabs-navigation'}>
        {tabs.map((tab, index) => {
          return (
            <li
              key={index}
              id={tab}
              onClick={() => {
                window.history.replaceState('', '', '?tab=' + tab.replace(/\s/g, '').toLowerCase());
                changeTab(tab);
              }}
              className={activeTab === tab ? 'active-tab' : undefined}
            >
              {tab}
            </li>
          );
        })}
      </ul>

      <div className={`internal-tabs`}>
        <div style={{ paddingTop: '48px' }}>
          {props.children.map((child, index) => (
            <div className={`tab`} key={index}>
              {activeTab === child.props.name ? child : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
