// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-oauth-callback-tsx": () => import("./../../../src/pages/oauth-callback.tsx" /* webpackChunkName: "component---src-pages-oauth-callback-tsx" */),
  "component---src-templates-blog-landing-page-blog-landing-page-tsx": () => import("./../../../src/templates/blog-landing-page/blog-landing-page.tsx" /* webpackChunkName: "component---src-templates-blog-landing-page-blog-landing-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-frame-page-tsx": () => import("./../../../src/templates/frame-page.tsx" /* webpackChunkName: "component---src-templates-frame-page-tsx" */),
  "component---src-templates-page-fragments-recent-blogposts-tsx": () => import("./../../../src/templates/page-fragments/recent-blogposts.tsx" /* webpackChunkName: "component---src-templates-page-fragments-recent-blogposts-tsx" */),
  "component---src-templates-search-page-tsx": () => import("./../../../src/templates/search-page.tsx" /* webpackChunkName: "component---src-templates-search-page-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/simple-page.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */),
  "component---src-templates-table-of-contents-page-tsx": () => import("./../../../src/templates/table-of-contents-page.tsx" /* webpackChunkName: "component---src-templates-table-of-contents-page-tsx" */)
}

