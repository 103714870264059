import * as React from 'react';
import { Icon, Translate } from '../..';
import { OS } from '../../../interfaces';
import { License } from '../../../services';
import { LicenseService } from '../../../services/license-manager';
import { getCurrentOS } from '../../../utils/get-current-os';
import './software-version-banner.scss';

const pix4dMapperCode = 'pix4d_mapper';

interface SoftwareVersionBannerProperties {
  softwareCode: string;
}

export const SoftwareVersionBanner = ({
  softwareCode = pix4dMapperCode,
}: SoftwareVersionBannerProperties) => {
  const [detectedOs, setDetectedOs] = React.useState(getCurrentOS());
  const licenseService = new LicenseService();
  const [link, setLink] = React.useState<string>(
    `${process.env.GATSBY_CLOUD_API_URL}${
      softwareCode === pix4dMapperCode ? 'download/' : 'download_release/'
    }${softwareCode}/`,
  );
  const [licenselink] = React.useState<string>(
    `${process.env.GATSBY_CLOUD_API_URL}account/licenses/`,
  );
  const [placeholders, setPlaceholders] = React.useState<object>({
    version: 'version',
  });
  const [show, setshow] = React.useState<boolean>(false);

  React.useEffect(() => {
    licenseService.getLatestReleaseForUser(softwareCode).then((license: License | void): void => {
      if (detectedOs !== OS.Mac && license && !license.canDownloadLatest) {
        const version = detectedOs === OS.Windows ? license.windows : license.linux;
        setshow(true);
        setPlaceholders({ version: version });
        setLink(`${link}${version}/${detectedOs}`);
      }
    });
  }, []);

  return (
    <div>
      <div className={`software-version-banner ${show ? '' : 'hide'} oneline`}>
        <Icon icon="info" color="orange" />{' '}
        <span>
          <div className="main-message">
            <Translate
              id="component.softwareVersionBanner.versionMessage"
              placeholders={placeholders}
            />{' '}
            <Translate id="component.softwareVersionBanner.checkMessage" />{' '}
            <a href={licenselink}>
              <Translate id="component.softwareVersionBanner.licenceMessage" />
            </a>{' '}
            <Translate id="component.softwareVersionBanner.detailsMessage" />
          </div>
          <div>
            <a href={link}>
              <Translate id="component.softwareVersionBanner.link" />
            </a>
          </div>
        </span>
      </div>
    </div>
  );
};
