import { decode } from 'querystring';
import { isArray } from 'util';
import { Session, User } from './types';
import { deserializeSession, serializeSession, deserializeUser } from './auth.io';

const jwtDecode = require('jwt-decode');
export const isBrowser = typeof window !== `undefined`;

const CLOUD_API = process.env.GATSBY_CLOUD_API_URL;
const CLIENT_ID = process.env.GATSBY_OAUTH_CLIENT_ID;

const CURRENT_USER_URL = `${CLOUD_API}user_account/api/v3/users/current/`;
const AUTHORIZE_URL = `${CLOUD_API}oauth2/authorize`;

const WELCOME_PAGE_PATH = `/welcome-to-pix4d`;

export const SESSION_STORAGE_KEY = 'pix4d-session';
export const USER_STORAGE_KEY = 'pix4d-user';

const getValidSession = (session: Session): Session | undefined => {
  const jwt = jwtDecode(session.accessToken);

  const expiry = new Date(0);
  expiry.setUTCSeconds(jwt.exp);
  return expiry.valueOf() > new Date().valueOf() ? session : undefined;
};

export const getLoginUrl = (enableWelcomePage: boolean = false): string => {
  const { origin, href } = window.location;

  if (!origin || !href) {
    return '#';
  }

  const redirectHref = enableWelcomePage ? `${origin}${WELCOME_PAGE_PATH}` : href;

  const token = Math.random().toString(24).substring(2);
  const url = `${AUTHORIZE_URL}?redirect_uri=${encodeURIComponent(
    `${origin}/oauth-callback`,
  )}&response_type=token&state=${encodeURIComponent(
    `${token}-${redirectHref}`,
  )}&client_id=${CLIENT_ID}`;
  return url;
};

export const getLogoutUrl = (isGated: boolean = false): string => {
  const { origin, href } = window.location;

  if (!origin || !href) {
    return '#';
  }

  const redirectHref = isGated ? origin : href;
  const url = `${CLOUD_API}logout?redirect_uri=${redirectHref}&client_id=${CLIENT_ID}`;
  return url;
};

export class AuthService {
  session: Session | undefined;
  user: any | undefined;

  get isAuthenticated(): boolean {
    return Boolean(this.session);
  }

  async init() {
    this.session = this.get();
    this.user = this.getUser();
  }

  get(): Session | undefined {
    if (!isBrowser) {
      return undefined;
    }
    try {
      const data: string | null = window.localStorage.getItem(SESSION_STORAGE_KEY);
      if (!data) {
        return undefined;
      }
      const session = deserializeSession(JSON.parse(data));
      return getValidSession(session);
    } catch (e) {
      console.log('unable to get session from storage', e);
      return undefined;
    }
  }

  sessionFromFragment = (fragment): Record<string, string> => {
    const data = decode(fragment);
    return ['access_token', 'token_type', 'state'].reduce((res, k) => {
      if (isArray(data[k])) {
        throw new Error(`Multiple value returned for param ${k}`);
      }
      res[k] = data[k];
      return res;
    }, {});
  };

  pathFromState = (state): string => {
    if (!state) {
      return '';
    }
    const matches = state.match(/(^[^-]+)-(.*$)/);
    if (!matches) {
      return '';
    }
    return matches[2];
  };

  parse(location: Location) {
    const fragment = location.hash.substr(1);
    const params = this.sessionFromFragment(fragment);
    const path = this.pathFromState(params.state);

    this.set({
      accessToken: params.access_token,
      tokenType: params.token_type,
    });

    this.fetchUser()
      .then(user => {
        if (user) {
          this.setUser(user);
          window.location.replace(path);
        }
      })
      .catch(err => {
        this.user = null;
        console.error(err);
      });
  }

  private set(session: Session): void {
    try {
      this.session = session;
      window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(serializeSession(session)));
    } catch (e) {
      console.log('unable to persist session in storage', e);
    }
  }

  getUser(): User | undefined {
    if (!isBrowser) {
      return undefined;
    }
    try {
      const data: string | null = window.localStorage.getItem(USER_STORAGE_KEY);
      if (!data) {
        return undefined;
      }
      return JSON.parse(data);
    } catch (e) {
      console.log('unable to get session from storage', e);
      return undefined;
    }
  }

  private setUser(user: User): void {
    try {
      this.user = user;
      window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    } catch (e) {
      console.log('unable to persist session in storage', e);
    }
  }

  private fetchUser = (): Promise<User | null> => {
    if (!this.session) {
      return Promise.resolve(null);
    }
    const token = this.session.accessToken;
    return fetch(`${CURRENT_USER_URL}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(userJson => deserializeUser(userJson));
  };

  clear() {
    try {
      window.localStorage.removeItem(SESSION_STORAGE_KEY);
      window.localStorage.removeItem(USER_STORAGE_KEY);
    } catch (e) {
      console.log('unable to remove session from storage', e);
    }
  }
}
