import * as React from 'react';
import { AuthService } from '../services/auth';

function useComponentDidMount(onMounted: any) {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);

    onMounted();
  }, [mounted, onMounted]);
}

const auth = new AuthService();

const AuthCallbackPage = () => {
  useComponentDidMount(() => {
    auth.parse(window.location);
  });

  return <></>;
};

export default AuthCallbackPage;
