import { OS } from '../interfaces';

const macosPlatforms = 'Mac';
const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

export function getCurrentOS(): OS | null {
  if (typeof window === 'undefined') {
    return null;
  }

  const { userAgent } = window.navigator;
  const { platform } = window.navigator;

  if (platform.indexOf(macosPlatforms) !== -1) {
    return OS.Mac;
  }
  if (iosPlatforms.indexOf(platform) !== -1) {
    return OS.iOS;
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return OS.Windows;
  }
  if (/Android/.test(userAgent)) {
    return OS.Android;
  }
  if (/Linux/.test(userAgent)) {
    return OS.Linux;
  }

  return null;
}
