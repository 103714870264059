const MOBILE_BREAKPOINT = 480;
const TABLET_BREAKPOINT = 850;

export enum DeviceMode {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  Server = 'server-side',
}

export const getDeviceMode = (): DeviceMode => {
  if (typeof window === 'undefined') {
    return DeviceMode.Server;
  }

  if (window.innerWidth <= MOBILE_BREAKPOINT) {
    return DeviceMode.Mobile;
  }
  if (window.innerWidth <= TABLET_BREAKPOINT) {
    return DeviceMode.Tablet;
  }

  return DeviceMode.Desktop;
};
