import { componentMap } from '../../components/components-map';

export const getCustomComponentByName = (tagName: string) => {
  let componentName = 'c-';
  componentName += tagName.slice(2).toLowerCase().replace('-', '');
  const comp = componentMap[componentName];

  if (!comp || !comp.component) {
    console.error(`Requested component does not exist: ${componentName}`);
    return null;
  }

  return comp;
};
