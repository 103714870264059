import * as React from 'react';
import { Animation } from '../../layout';
import { SliderDisplay } from './slider-display';

export interface SliderProperties<T> {
  items: T[];
  itemTemplate: (props: { items: T[] }) => JSX.Element | JSX.Element[];
  itemsPerSlide: number;
  rotateSlides?: boolean;
}

interface SliderState {
  currentIndex: number;
}

export class Slider<T> extends React.Component<SliderProperties<T>, SliderState> {
  state = {
    currentIndex: 0,
  };

  get Items(): JSX.Element[] | T[] {
    return (this.props.children as JSX.Element[]) || this.props.items;
  }

  get numberOfPages() {
    return Math.ceil(this.Items.length / this.props.itemsPerSlide);
  }

  setIndex = (index: number) => {
    let slideIndex;
    if (this.props.rotateSlides) {
      slideIndex = (index + this.numberOfPages) % this.numberOfPages;
    } else {
      slideIndex = Math.min(Math.max(index, 0), this.numberOfPages - 1);
    }
    this.setState(() => ({ currentIndex: slideIndex }));
  };

  get Slides(): JSX.Element[] {
    const slides: JSX.Element[] = [];
    const ItemTemplate = this.props.itemTemplate as any;

    for (let i = 0; i < this.numberOfPages; i++) {
      const items = this.Items.slice(
        this.props.itemsPerSlide * i,
        this.props.itemsPerSlide * i + this.props.itemsPerSlide,
      );
      slides.push(<ItemTemplate items={items} />);
    }

    return slides;
  }

  render() {
    const isActive = this.numberOfPages > 1;

    return (
      <SliderDisplay
        slides={this.Slides}
        pagerElements={this.numberOfPages}
        isActive={isActive}
        currentIndex={this.state.currentIndex}
        setIndex={this.setIndex}
      />
    );
  }
}

export const SliderAnimated = (props: SliderProperties<any>) => (
  <Animation>
    <Slider {...props} />
  </Animation>
);
