import { MarkdownRemarkElement } from '../../../interfaces';

export const LinkLocalizerTransformer = (element: MarkdownRemarkElement): MarkdownRemarkElement => {
  if (element.tagName === 'a') {
    if (element.properties && element.properties.href && element.properties.href[0] === '/') {
      return {
        children: element.children,
        type: 'element',
        tagName: 'c-linklocalizer',
        properties: { to: element.properties.href, ...element.properties },
      };
    }
  }

  return element;
};
