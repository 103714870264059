import * as React from 'react';

import { getHeaderHeight, getSecondaryMenuHeight } from '../../utils';
import './sticky-left-sidebar.scss';

interface StickyLeftSidebarProperties {
  children: JSX.Element;
  contentRef: React.RefObject<HTMLElement>;
  hideOnTablet?: boolean;
}

interface StickyLeftSidebarState {
  marginTop: number;
  contentHeight: number;
}

export class StickyLeftSidebar extends React.Component<
  StickyLeftSidebarProperties,
  StickyLeftSidebarState
> {
  sidebarContentRef: HTMLDivElement | null = null;

  state = {
    marginTop: 0,
    contentHeight: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.setState(() => ({ contentHeight: this.getContentHeight() }));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  get HeaderHeight() {
    return document.querySelector('header')!.getBoundingClientRect().height;
  }

  get SecondaryMenuHeight() {
    const secondaryMenu = document.querySelector('.secondary-menu .secondary-menu-label-wrap');
    if (secondaryMenu) {
      return secondaryMenu.getBoundingClientRect().height;
    }
    return NaN;
  }

  getSidebarContentClientRect = () => {
    if (this.sidebarContentRef) {
      return this.sidebarContentRef.getBoundingClientRect();
    }

    return { top: NaN, bottom: NaN, height: NaN };
  };

  getTopDistance = () => {
    return this.getSidebarContentClientRect().top - this.HeaderHeight - this.SecondaryMenuHeight;
  };

  getBottomDistance = () => {
    return this.getSidebarContentClientRect().bottom - window.innerHeight;
  };

  getPageBodyHeight = () => {
    return window.innerHeight - getHeaderHeight() - getSecondaryMenuHeight();
  };

  getContentHeight = () => {
    if (this.props.contentRef) {
      const e = this.props.contentRef.current;
      if (e) {
        return e.getBoundingClientRect().height;
      }
    }

    return NaN;
  };

  getNewTopPosition = () => {
    const bottomDistance = this.getBottomDistance();
    const topDistance = this.getTopDistance();

    if (topDistance > 0) {
      return Math.max(this.state.marginTop - topDistance, 0);
    }
    if (bottomDistance < 0 && topDistance < 0) {
      if (
        this.getSidebarContentClientRect().height + this.state.marginTop >
        this.state.contentHeight
      ) {
        return this.state.marginTop;
      }
      if (this.getSidebarContentClientRect().height > this.getPageBodyHeight()) {
        return this.state.marginTop - bottomDistance;
      }
      return this.state.marginTop - topDistance;
    }

    return this.state.marginTop;
  };

  onScroll = () => {
    const newMarginTop = this.getNewTopPosition();

    if (this.state.marginTop !== newMarginTop) {
      this.setState(() => ({ marginTop: newMarginTop }));
    }
  };

  render() {
    return (
      <div
        className={`sticky-left-sidebar ${
          this.props.hideOnTablet ? 'sticky-left-sidebar--hide-on-tablet' : ''
        }`}
        style={{ marginTop: this.state.marginTop }}
      >
        <div className="sticky-left-sidebar__content" ref={ref => (this.sidebarContentRef = ref)}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
