import * as React from 'react';
import ReactDOM from 'react-dom';
import { Price } from '../price';
import './product-card.scss';

export const ProductCard = ({ btnlink, btntext, ...props }) => {
  return (
    <div className={'product-card box-shadow margin-small'}>
      <div className={'oneline font-large padding-small center'} style={{ paddingTop: '45px' }}>
        {props.children[0]}
        {props.children[1]}
      </div>
      <div className={'product-price center padding-small'}>
        {props.children[2]}
        {props.children[3]}
      </div>
      <div className={'description padding-medium'}>{props.children[4]}</div>

      <div className="center" style={{ paddingBottom: '15px' }}>
        <a className="btn btn--primary but center" href={btnlink}>
          {btntext}
        </a>
      </div>
    </div>
  );
};
