import { iframeResizer } from 'iframe-resizer';
import * as React from 'react';

export interface IframeProperties {
  src: string;
}

export class Iframe extends React.Component<IframeProperties> {
  componentDidMount() {
    iframeResizer({ checkOrigin: false, log: false }, '.component-iframe iframe');
  }

  /* eslint-disable jsx-a11y/iframe-has-title */
  render() {
    return (
      <div className="component-iframe">
        <iframe {...this.props} />
      </div>
    );
  }
  /* eslint-enable jsx-a11y/iframe-has-title*/
}
