import { assoc } from 'ramda';
import { MarkdownRemarkChild, MarkdownRemarkElement } from '../../../interfaces';

const createDivWrap = (child: MarkdownRemarkChild): MarkdownRemarkElement => {
  return {
    type: 'element',
    tagName: 'div',
    children: [child],
    properties: {},
  };
};

const transformBlockqute = (element: MarkdownRemarkElement): MarkdownRemarkElement => {
  const p = element.children[0];

  if (p && p.type === 'element') {
    const [textNode, firstStrongNode, secondStrondNode] = p.children;
    if (textNode) {
      const newChildrens = [createDivWrap(textNode), firstStrongNode, secondStrondNode].filter(
        x => x,
      );
      return assoc('children', newChildrens, element);
    }
  }

  return element;
};

export const BlockquoteTransformer = (element: MarkdownRemarkElement): MarkdownRemarkElement => {
  if (element.type === 'element' && element.tagName === 'blockquote') {
    return transformBlockqute(element);
  }

  return element;
};
