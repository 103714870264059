import * as React from 'react';
import { getLocaleFromPath } from '../../utils';
import { Icon } from '../dynamic-components/icon';

import './search-bar.scss';

interface SearchBarProps {
  active: boolean; //active props coming from header and keep search-box in open position in mobile view.
  changeOpenState: any;
}

export const SearchBar = (props: SearchBarProps) => {
  const [state, setState] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  const changeOpenState = (value: boolean) => {
    props.changeOpenState(value);
  };

  const resetSearchQuery = () => {
    setSearchQuery('');
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (value !== '') {
      if (event.keyCode === 13) {
        let locale =
          getLocaleFromPath(window.location.pathname) === 'en-US'
            ? ''
            : getLocaleFromPath(window.location.pathname);
        let path = `/search?query=${value.replace(/\s+/g, '-')}`;
        window.location.href = locale === '' ? `${path}` : `/${locale}${path}`;
      }
    }
  };

  return (
    <>
      {!state && (
        <button
          onClick={() => {
            setState(true);
            changeOpenState(true);
          }}
          className="search-button"
          type="button"
        >
          <Icon icon="search-16" />
        </button>
      )}
      {(state || props.active) && (
        <div className="search-box">
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            onKeyDown={handleKeyDown}
            autoFocus={true}
            value={searchQuery}
            onChange={event => setSearchQuery(event.target.value)}
          />
          <button
            className="search-cancel"
            onClick={() => {
              setState(false);
              changeOpenState(false);
              resetSearchQuery();
            }}
            type="button"
          >
            <Icon icon="cancel_circle" />
          </button>
        </div>
      )}
    </>
  );
};
