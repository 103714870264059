import * as React from 'react';
import { Currency, Localization } from './types';
export const LocalizationContext = React.createContext<Localization | null>(null);

export const useLocalization = () => {
  const [localization, setLocalization] = React.useState<Localization | null>(null);
  const defaultCountry: string = 'US';

  React.useEffect(() => {
    const updateLocalization = () => {
      try {
        const store = localStorage.getItem('localization');
        const storeCurrency = store && JSON.parse(store).currency.toLowerCase();
        const storeCountry = store && JSON.parse(store).country;
        setLocalization(
          storeCurrency && storeCountry
            ? { country: storeCountry, currency: storeCurrency }
            : localization,
        );
      } catch {
        setLocalization({ country: defaultCountry, currency: Currency.USD });
      }
    };

    updateLocalization();

    window.addEventListener('localizationUpdated', updateLocalization);
    return () => window.removeEventListener('localizationUpdated', updateLocalization);
  }, [localization?.country || localization?.currency]);

  return { localization };
};
