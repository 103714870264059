import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Block, Icon, Layout, OpenGraphHelmet, RelatedArticles, Translate } from '../components';
import { ContentfulBlogPost, ContentfulEdges, SiteConfig } from '../interfaces';
import {
  ContentfulMarkdownRemarkRenderer,
  fixContentfulAssetPath,
  formatDate,
  localizeLink,
} from '../utils';
import { BlogLandingLink } from './blog-landing-page';

import './blog-post.scss';

interface BlogPostProperties {
  pageContext: { englishCanonicalUrl: string };
  data: {
    relatedArticles: ContentfulEdges<ContentfulBlogPost>;
    contentfulBlogPost: ContentfulBlogPost;
    site: SiteConfig;
  };
}

const unwrapRelatedArticles = ({ edges }): ContentfulBlogPost[] => edges.map(x => x.node);

const BlogPost = ({
  pageContext: { englishCanonicalUrl },
  data: { contentfulBlogPost: blogpost, site, relatedArticles },
}: BlogPostProperties) => {
  const locale = blogpost.fields.locale;
  const localizedLink = localizeLink(locale, `/blog/${blogpost.slug}`);
  const canonicalUrl = `${site.siteMetadata.siteUrl}${localizedLink}`;
  const blogpostHeroImageUrl =
    blogpost.heroImage && fixContentfulAssetPath(blogpost.heroImage.file.url);

  const socialImageUrl =
    (blogpost.socialImage && fixContentfulAssetPath(blogpost.socialImage.file.url)) ||
    blogpostHeroImageUrl;

  return (
    <Layout
      headerDarkTheme={false}
      title={blogpost.title}
      englishCanonicalUrl={englishCanonicalUrl}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="description" content={blogpost.metaDescription} />
      </Helmet>
      <OpenGraphHelmet
        description={blogpost.metaDescription}
        socialImage={socialImageUrl}
        title={blogpost.title}
        url={localizedLink}
        type="article"
      />
      <div className="blogpost">
        <div>
          <div className="blogpost-hero">
            {blogpost.heroImage && (
              <img
                alt={blogpost.heroImage.title}
                src={`${blogpostHeroImageUrl}?w=1200&fm=jpg&q=80`}
                srcSet={`
                  ${blogpostHeroImageUrl}?w=480&fm=jpg&q=80 480w,
                  ${blogpostHeroImageUrl}?w=850&fm=jpg&q=80 850w,
                  ${blogpostHeroImageUrl}?w=1200&fm=jpg&q=80 1200w,
                  ${blogpostHeroImageUrl}?w=1600&fm=jpg&q=80 1600w
                `}
              />
            )}
            <Block>
              <div className="white-box" />
            </Block>
          </div>
          <article className="blogpost-article">
            <Block>
              <Block small extraClasses={['blogpost-content']}>
                <div className="block-header">
                  <h1>{blogpost.title}</h1>
                  {blogpost.labels !== null && (
                    <ul className="tags">
                      {blogpost.labels.map(x => (
                        <li key={x}>
                          <BlogLandingLink labels={x}>{x}</BlogLandingLink>
                        </li>
                      ))}
                    </ul>
                  )}
                  <time itemProp="datePublished" dateTime={blogpost.time}>
                    {formatDate(new Date(blogpost.time), locale)}
                  </time>
                  <div className="excerpt">{blogpost.excerpt}</div>
                  {blogpost.pdfAttachment && (
                    <span className="pdf-file mobile-hide">
                      <a
                        className="oneline"
                        download
                        target="_blank"
                        href={blogpost.pdfAttachment.file.url}
                        rel="noopener noreferrer"
                      >
                        <Icon icon="pdf_file" />{' '}
                        <span>
                          <Translate id="page.blog.downloadPdf" />
                        </span>
                      </a>
                    </span>
                  )}
                </div>

                <ContentfulMarkdownRemarkRenderer
                  markdown={blogpost.content}
                  zoomableImages
                  imageCaption
                  lazyLoadImages
                />
              </Block>
              {relatedArticles && (
                <Block wide>
                  <h2 style={{ textAlign: 'center' }}>
                    <Translate id="page.blog.relatedArticles" />
                  </h2>
                  <RelatedArticles blogposts={unwrapRelatedArticles(relatedArticles)} />
                </Block>
              )}
            </Block>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  fragment blogpostBaseFields on ContentfulBlogPost {
    title
    excerpt
    fields {
      locale
    }
    slug
    socialImage: socialImage {
      file {
        url
      }
    }
    heroImage: image {
      title
      file {
        url
      }
    }
  }

  fragment blogpostFields on ContentfulBlogPost {
    ...blogpostBaseFields
    labels
    time
    pdfAttachment {
      file {
        url
      }
    }
    content {
      childMarkdownRemark {
        htmlAst
      }
    }
    metaDescription
  }

  query blogPostQuery($slug: String!, $locale: String!, $relatedArticlesSlugs: [String]!) {
    contentfulBlogPost(slug: { eq: $slug }, fields: { locale: { eq: $locale } }) {
      ...blogpostFields
    }
    relatedArticles: allContentfulBlogPost(
      filter: { slug: { in: $relatedArticlesSlugs }, fields: { locale: { eq: $locale } } }
      sort: { fields: time, order: DESC }
    ) {
      edges {
        node {
          ...blogpostBaseFields
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
