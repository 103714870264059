import { MarkdownRemark } from '../../../interfaces';
import { ColumnFeatureItem } from './column-features';

interface ColumnFeaturesContentfulProperties {
  items: {
    icon: MarkdownRemark;
    title: MarkdownRemark;
    text: MarkdownRemark;
  }[];
}

export const ColumnFeaturesContentfulPropertiesTransformer = ({
  items = [],
}: ColumnFeaturesContentfulProperties) => {
  const transformedTiles: ColumnFeatureItem[] = items.map(x => ({
    icon: x.icon,
    title: x.title,
    text: x.text,
  }));

  return {
    items: transformedTiles,
  };
};
