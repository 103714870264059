import * as React from 'react';
import { Translate } from '../../layout';

import './countdown.scss';

interface CountdownProperties {
  date: string;
}

interface CountdownState {
  remainingMilliseconds: number;
}

export class Countdown extends React.Component<CountdownProperties, CountdownState> {
  timer = 0;

  state = {
    remainingMilliseconds: 0,
  };

  componentDidMount() {
    this.update();
    this.timer = setInterval(this.update, 1000) as any;
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = 0;
  }

  update = () => {
    const remainingMilliseconds = new Date(`${this.props.date}Z`).getTime() - new Date().getTime();
    this.setState(() => ({ remainingMilliseconds }));
  };

  render() {
    if (this.state.remainingMilliseconds > 0) {
      const remaininTime = parseDateToObject(new Date(this.state.remainingMilliseconds));
      return <CountdownDisplay {...remaininTime} />;
    }
    return <CountdownDisplay hh="00" ss="00" mm="00" dd="00" />;
  }
}

const formatTwoDigits = (n: number) => (n < 10 ? `0${n}` : n) as string;
function parseDateToObject(d: Date) {
  const dd = formatTwoDigits(d.getUTCDate() - 1);
  const hh = formatTwoDigits(d.getUTCHours());
  const mm = formatTwoDigits(d.getUTCMinutes());
  const ss = formatTwoDigits(d.getUTCSeconds());
  return { dd, hh, mm, ss };
}

const CountdownDisplay = ({
  dd,
  hh,
  mm,
  ss,
}: {
  dd: string;
  hh: string;
  mm: string;
  ss: string;
}) => (
  <div className="component-countdown">
    <span className="fragment">
      <span className="time">{dd}</span>
      <div className="label" style={{ transform: 'uppercase' }}>
        <Translate id="component.countdown.days" />
      </div>
    </span>
    <span className="fragment">
      <span className="time">{hh}</span>
      <div className="label" style={{ transform: 'uppercase' }}>
        <Translate id="component.countdown.hours" />
      </div>
    </span>
    <span className="fragment">
      <span className="time">{mm}</span>
      <div className="label" style={{ transform: 'uppercase' }}>
        <Translate id="component.countdown.minutes" />
      </div>
    </span>
    <span className="fragment">
      <span className="time">{ss}</span>
      <div className="label" style={{ transform: 'uppercase' }}>
        <Translate id="component.countdown.seconds" />
      </div>
    </span>
  </div>
);
