import * as React from 'react';
import { batcher } from '../../utils';
import { DeviceMode, getDeviceMode } from '../../utils/get-device-mode';

interface ResponsiveTemplateProps {
  children: JSX.Element | JSX.Element[];
}

interface ResponsiveTemplateState {
  display: DeviceMode;
}

export class ResponsiveTemplate extends React.Component<
  ResponsiveTemplateProps,
  ResponsiveTemplateState
> {
  state = {
    display: DeviceMode.Server,
  };

  static Mobile: any = 'mobile';

  static Tablet: any = 'tablet';

  static Desktop: any = 'desktop';

  update = batcher(() => {
    const display = getDeviceMode();
    if (this.state.display !== display) {
      this.setState(() => ({ display }));
    }
  }, 200);

  componentDidMount() {
    window.addEventListener('resize', this.update, { passive: true });
    this.setState(() => ({ display: getDeviceMode() }));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  render() {
    if (!Array.isArray(this.props.children)) {
      return this.props.children.props.children;
    }

    const children = this.props.children as JSX.Element[];

    const mobileNode = children.find(x => x.type === ResponsiveTemplate.Mobile);
    const tabletNode = children.find(x => x.type === ResponsiveTemplate.Tablet);
    const desktopNode = children.find(x => x.type === ResponsiveTemplate.Desktop);

    if (this.state.display === ResponsiveTemplate.Mobile && mobileNode) {
      return mobileNode.props.children;
    }
    if (this.state.display === ResponsiveTemplate.Mobile && tabletNode) {
      return tabletNode.props.children;
    }
    if (this.state.display === ResponsiveTemplate.Tablet && tabletNode) {
      return tabletNode.props.children;
    }

    return desktopNode!.props.children;
  }
}
