import { keys } from 'ramda';
import * as React from 'react';
import { Locale } from '../../translations';
import { LocaleContext } from '../contexts';
import { LinkLocalizer } from './link-localizer';
import { Translate } from './translate';

import './language-selector.scss';

interface LanguageSelectorProperties {
  languages: Record<Locale, string>;
  englishCanonicalUrl: string;
}
export const LanguageSelector = ({
  languages,
  englishCanonicalUrl,
}: LanguageSelectorProperties) => {
  const otherLanguages = (currentLocale: Locale) =>
    keys(languages)
      .filter(locale => locale !== currentLocale)
      .map(locale => [locale, languages[locale]] as const);

  return (
    <LocaleContext.Consumer>
      {locale => (
        <div className="language-selector-wrap">
          <input
            type="checkbox"
            id="language-selector-mobile"
            className="language-selector-mobile"
          />

          <label htmlFor="language-selector-mobile" className="active-language">
            <span className="language">
              <Translate id={languages[locale] as any} locale={locale} />
            </span>
            <i className="icon-arrow arrow arrow--down" />
          </label>

          <ul className="language-list">
            <li className="active-language tablet-hide mobile-hide">
              <span className="active-language">
                <Translate id={languages[locale] as any} locale={locale} />
                <i className="icon-arrow arrow arrow--up" />
              </span>
            </li>
            {otherLanguages(locale).map(([itemLocale, itemLanguage], index) => (
              <li key={`locale-${index}`}>
                <LinkLocalizer to={englishCanonicalUrl} defaultTo="/" lang={itemLocale}>
                  <Translate id={itemLanguage as any} locale={itemLocale} />
                </LinkLocalizer>
              </li>
            ))}
            <li>
              <a href="https://pix4d.com.cn/" target="_blank" rel="noopener noreferrer">
                中文
              </a>
            </li>
          </ul>
        </div>
      )}
    </LocaleContext.Consumer>
  );
};
