import { SvgIcon } from '@pix4d/pix4d-design-system-lib';
import * as React from 'react';
import { TRANSLATION_KEYS } from '../../../../translations';
import { Translate } from '../../../layout';
import { DoubleLink } from '../../double-link';
import { Icon } from '../../icon';

interface iconDisplayProperties {
  downloadLink: string;
  icon: SvgIcon | undefined;
  title: TRANSLATION_KEYS;
  redirectLink?: string;
  color?: string;
  className?: string;
}

export const IconDisplay = ({
  downloadLink,
  redirectLink,
  icon,
  title,
  color,
  className,
}: iconDisplayProperties) => {
  if (redirectLink) {
    return (
      <DoubleLink className={className} downloadLink={downloadLink} to={redirectLink}>
        {icon && <Icon icon={icon} color={color} />}
        <Translate id={title} />
      </DoubleLink>
    );
  }

  return (
    <a className={className} href={downloadLink}>
      {icon && <Icon icon={icon} color={color} />}
      <Translate id={title} />
    </a>
  );
};
