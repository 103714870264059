import { graphql, useStaticQuery } from 'gatsby';
import { assocPath, groupBy, mergeAll, toPairs } from 'ramda';
import { edgeLanguageFilter } from '../../pages/utils/edge-language-filter';
import { Locale } from '../translations';

interface Edge {
  node: {
    contentful_id: string;
    fields: {
      locale: Locale;
    };
    slug: string;
    activeTranslations: Locale[];
  };
}

interface Data {
  allContentfulBlogPost: {
    edges: Edge[];
  };
  allContentfulPage: {
    edges: Edge[];
  };
}

/* eslint-disable react-hooks/rules-of-hooks, no-sequences */
export function getLocalizedPages() {
  const data = useStaticQuery<Data>(graphql`
    query allSitePages {
      allContentfulPage {
        edges {
          node {
            contentful_id
            slug
            fields {
              locale
            }
            activeTranslations
          }
        }
      }
      allContentfulBlogPost {
        edges {
          node {
            contentful_id
            slug
            fields {
              locale
            }
            activeTranslations
          }
        }
      }
    }
  `);

  function addBlogSlugPrefix(edge: Edge) {
    const newSlug = `blog/${edge.node.slug}`;

    return assocPath(['node', 'slug'], newSlug, edge);
  }

  function addSlashPrefixToSlug(edge: Edge) {
    const newSlug = `/${edge.node.slug}`;

    return assocPath(['node', 'slug'], newSlug, edge);
  }

  const customPages = {
    '/blog': {
      [Locale.English]: '/blog',
      [Locale.French]: '/blog',
      [Locale.German]: '/blog',
      [Locale.Japanese]: '/blog',
      [Locale.Portuguese]: '/blog',
      [Locale.Russian]: '/blog',
      [Locale.Spanish]: '/blog',
    },
  };

  function group(edges: Edge[]) {
    const nodes = edges.map(x => x.node);
    const groups = groupBy(x => x.contentful_id, nodes);
    const pairs = toPairs(groups);
    const groupsKeyed = pairs.map(([_, x]) => {
      const enItem = x.find(y => y.fields.locale === Locale.English);

      const newX = x.reduce(
        (acc, current) => ((acc[current.fields.locale] = current.slug), acc),
        {},
      );

      if (!enItem) {
        return {};
      }

      return { [enItem.slug]: newX };
    });

    return mergeAll(groupsKeyed);
  }

  const filteredEdgesBlogs = data.allContentfulBlogPost.edges
    .filter(edgeLanguageFilter)
    .map(addBlogSlugPrefix)
    .map(addSlashPrefixToSlug);

  const filteredEdgesPages = data.allContentfulPage.edges
    .filter(edgeLanguageFilter)
    .map(addSlashPrefixToSlug);

  const blogs = group(filteredEdgesBlogs);
  const pages = group(filteredEdgesPages);

  return mergeAll([blogs, pages, customPages]) as any;
}
/* eslint-enable react-hooks/rules-of-hooks, no-sequences */
