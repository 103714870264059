import { MarkdownRemark, MarkdownRemarkElement } from '../interfaces';

export const getMarkdownText = (
  element: MarkdownRemark | MarkdownRemarkElement,
): string | undefined => {
  if (!element || element.children === undefined || element.children.length === 0) {
    return undefined;
  }

  return (element.children[0] as any).value as string;
};
