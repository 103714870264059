import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { MarkdownRemarkRenderer } from '../../../utils';
import { Animation } from '../../layout';
import { LinkLocalizer } from '../../layout/link-localizer';
import { Tiles } from '../tiles';

import './grid.scss';

export interface GridItem {
  icon: MarkdownRemark;
  text: MarkdownRemark;
  iconText: MarkdownRemark;
  link?: string;
}

interface GridProperties {
  items: GridItem[];
  row: number;
}

const GridItemWithLink = React.forwardRef(({ icon, iconText, text, link }: GridItem, ref) => (
  <LinkLocalizer className="griditem" to={link} ref={ref as any}>
    <div className="griditem-icon">
      <MarkdownRemarkRenderer markdown={icon} />
    </div>
    <div className="griditem-content">
      <div className="griditem-title title">
        <MarkdownRemarkRenderer markdown={iconText} />
      </div>
      <div className="griditem-text">
        <MarkdownRemarkRenderer markdown={text} />
      </div>
    </div>
  </LinkLocalizer>
)) as (props: GridItem) => React.ReactElement<any>;

const GridItem = React.forwardRef(({ icon, iconText, text }: GridItem, ref) => (
  <div className="griditem" ref={ref as any}>
    <div className="griditem-icon">
      <MarkdownRemarkRenderer markdown={icon} />
    </div>
    <div className="griditem-content">
      <div className="griditem-title title">
        <MarkdownRemarkRenderer markdown={iconText} />
      </div>
      <div className="griditem-text">
        <MarkdownRemarkRenderer markdown={text} />
      </div>
    </div>
  </div>
)) as (props: GridItem) => React.ReactElement<any>;

export const Grid = ({ items, row = 3 }: GridProperties) => (
  <div className="component-grid">
    <Tiles
      items={items.map((tile, index) => {
        if (tile.link) {
          return <GridItemWithLink key={`tile-${index}`} {...tile} />;
        }
        return <GridItem key={`tile-${index}`} {...tile} />;
      })}
      row={row}
    />
  </div>
);

export const GridAnimated = (props: GridProperties) => (
  <Animation>
    <Grid {...props} />
  </Animation>
);
