import { MarkdownRemarkElement } from '../../../interfaces';
import { parseStyleString } from '../../parse-style-string';

export const StylePropertyTransformer = (element: MarkdownRemarkElement): MarkdownRemarkElement => {
  if (element.properties && 'style' in element.properties) {
    element.properties.style = parseStyleString(element.properties.style || '');
  }

  return element;
};
