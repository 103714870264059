interface LocationalDisplayContentfulProperties {
  allowedcountries: string;
  children: JSX.Element[];
}

export const LocationalDisplayContentfulPropertiesTransformer = ({
  allowedcountries,
  children,
}: LocationalDisplayContentfulProperties) => {
  return {
    allowedCountries: allowedcountries,
    children,
  };
};
