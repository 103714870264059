import { SvgIcon } from '@pix4d/pix4d-design-system-lib';

export enum OS {
  Mac = 'mac',
  Windows = 'windows',
  Linux = 'linux',
  Android = 'android',
  iOS = 'ios',
}

export const OS_ICONS: Partial<Record<OS, SvgIcon>> = {
  [OS.Mac]: 'apple',
  [OS.Windows]: 'windows',
  [OS.Linux]: 'linux',
  [OS.Android]: 'android',
};
