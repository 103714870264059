import { Locale } from '../translations';

const languages = require('../data/languages');

export function getLocaleFromPath(path: string) {
  const split = path.split('/');
  const locale = split[1] as Locale;

  if (Object.keys(languages.enabledLanguages).indexOf(locale) > -1) {
    return locale;
  }
  return Locale.English;
}
