import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { AspectRatioBox, Ratio } from '../aspect-ratio-box';
import { ImageDescOverlay } from '../image-desc-overlay';

import { Animation } from '../../layout';
import './image-tiles.scss';

export interface ImageTile {
  title: MarkdownRemark;
  subtitle?: MarkdownRemark;
  image: MarkdownRemark;
  link?: string;
}

export interface ImageTilesProperties {
  items: ImageTile[];
}

export const ImageTiles = ({ items }: ImageTilesProperties) => (
  <div className="component-imagetiles">
    {items.map((tile, index) => (
      <div key={`tile-${index}`}>
        <AspectRatioBox desktopRatio={Ratio.Wide} tabletRatio={Ratio.Normal}>
          <ImageDescOverlay {...tile} />
        </AspectRatioBox>
      </div>
    ))}
  </div>
);

export const ImageTilesAnimated = ({ items }: ImageTilesProperties) => (
  <div className="component-imagetiles">
    {items.map((tile, index) => (
      <div key={`animated-tile-${index}`}>
        <Animation>
          <AspectRatioBox desktopRatio={Ratio.Wide} tabletRatio={Ratio.Normal}>
            <ImageDescOverlay {...tile} />
          </AspectRatioBox>
        </Animation>
      </div>
    ))}
  </div>
);
