require('dotenv').config({
  path: `.env.production`,
});

const queries = require('./src/utils/algolia');
const languages = require('./src/data/languages');
const sitemapSerializer = require('./pages/utils/sitemap-serializer').sitemapSerializer;
var process = require('process');

const siteGlobals = {
  contentful: {
    spaceId: process.env.GATSBY_CONTENTFUL_APP_ID,
    accessToken: process.env.GATSBY_CONTENTFUL_API_KEY,
  },
};

const config = {
  siteMetadata: {
    title: 'Pix4D',
    languages,
    contentful: siteGlobals.contentful,
    siteUrl: 'https://www.pix4d.com',
    twitterHandle: '@pix4d',
  },
  plugins: [
    'gatsby-plugin-use-query-params',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-transformer-remark',
    'pix4d-japanese-locale-transformer',
    {
      resolve: `gatsby-source-contentful`,
      options: siteGlobals.contentful,
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        serialize: sitemapSerializer,
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allSitePage {
              edges {
                node {
                  path
                  context {
                    locale
                    englishCanonicalUrl
                    noIndex
                  }
                }
              }
            }
          }`,
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.pix4d.com/',
        sitemap: 'https://www.pix4d.com/sitemap.xml',
        policy: [{ userAgent: '*', allow: '/' }],
      },
    },
    {
      resolve: `gatsby-plugin-google-tagmanager`,
      options: {
        id: process.env.GATSBY_GOOGLE_TAGMANAGER_ID,
      },
    },
  ],
};

if (process.env.GATSBY_ALGOLIA === 'add') {
  config.plugins.push({
    resolve: `gatsby-plugin-algolia`,
    options: {
      appId: process.env.GATSBY_ALGOLIA_APP_ID,
      apiKey: process.env.GATSBY_ALGOLIA_API_KEY_WRITE,
      queries,
      chunkSize: 10000, // default: 1000
      enablePartialUpdates: true,
      matchFields: ['updatedAt'],
    },
  });
}
module.exports = config;
