import * as React from 'react';

import './aspect-ratio-box.scss';

interface Ratios {
  desktopRatio?: Ratio;
  mobileRatio?: Ratio;
  tabletRatio?: Ratio;
}

export enum Ratio {
  Box = 'box',
  Normal = 'normal',
  Wide = 'wide',
}
export interface AspectRatioBoxProperties extends Ratios {
  children: any;
}

const getRatioClass = (target = 'normal', postfix?: string) =>
  `${target ? target + (postfix || '') : ''}`;

export const AspectRatioBox = React.forwardRef(
  ({ children, ...ratios }: AspectRatioBoxProperties, ref) => (
    <div className={`ratio-box ${generateClass(ratios)}`} ref={ref as any}>
      <div className="ratio-box-content">{children}</div>
    </div>
  ),
);

const generateClass = (ratio: Ratios) => {
  const classes: string[] = [
    getRatioClass(ratio.mobileRatio || ratio.tabletRatio || ratio.desktopRatio, '-mobile'),
    getRatioClass(ratio.tabletRatio || ratio.desktopRatio, '-tablet'),
    getRatioClass(ratio.desktopRatio),
  ];

  return classes
    .filter(x => x)
    .map(x => `ratio-box--${x}`)
    .join(' ');
};
