import * as React from 'react';
import { SecondaryMenu, Translate } from '../../components';
import { Locale, translate } from '../../translations';

import './blog-landing-filter-menu.scss';

interface BlogLandingFilterMenuProperties {
  toggleFilter: (filter: string) => void;
  categories: any;
  activeFilters: string[];
}
export const BlogLandingFilterMenu = ({
  categories,
  toggleFilter,
  activeFilters,
}: BlogLandingFilterMenuProperties) => (
  <SecondaryMenu label="Filters">
    <div className="bloglanding-filters-list">
      {categories.map((category, index) => (
        <FiltersList
          key={index}
          toggleFilter={toggleFilter}
          category={category}
          activeFilters={activeFilters}
        />
      ))}
    </div>
  </SecondaryMenu>
);

const FiltersList = ({ category, toggleFilter, activeFilters }) => {
  return (
    <div className="bloglanding-filters-section">
      <div className="bloglanding-filters-section--title">
        <Translate id={category.title} />
      </div>
      {category.labels.map((label, index) => {
        const englishLabel = translate(Locale.English, label) as string;

        return (
          <label key={index}>
            <input
              type="checkbox"
              checked={activeFilters.includes(englishLabel)}
              onChange={() => toggleFilter(englishLabel)}
            />
            <Translate id={label} />
            <span className="checkmark" />
          </label>
        );
      })}
    </div>
  );
};
