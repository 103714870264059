import { MarkdownRemark } from '../../../interfaces';
import { getMarkdownText } from '../../../utils';
import { FeatureWideItem } from './features-wide-display';

interface FeaturesWideContentfulProperties {
  title: MarkdownRemark;
  'link-more': MarkdownRemark;
  'link-more-url': MarkdownRemark;

  items: {
    figure: MarkdownRemark;
    icon: MarkdownRemark;
    desc: MarkdownRemark;
    title: MarkdownRemark;
  }[];
}

export const FeaturesWideContentfulPropertiesTransformer = ({
  items = [],
  title,
  ...rest
}: FeaturesWideContentfulProperties) => {
  const transformedTiles: FeatureWideItem[] = items.map(x => ({
    image: x.figure,
    title: x.title,
    desc: x.desc,
    icon: x.icon,
  }));

  return {
    title,
    linkMore: rest['link-more'],
    linkMoreUrl: getMarkdownText(rest['link-more-url']),
    items: transformedTiles,
  };
};
