import { Link } from 'gatsby';
import { chain } from 'ramda';
import * as React from 'react';
import { ContentfulContent, MarkdownRemarkElement, MarkdownRemarkText } from '../../interfaces';
import { getHeaders, makeTextUrlFriendly } from '../../utils';

import './table-of-contents.scss';

interface TableOfContentsProperties {
  contents: ContentfulContent[];
  title: string;
  slug: string;
}

function getLinks(headers: MarkdownRemarkElement[]): string[] {
  return headers
    .filter(x => x.tagName === 'h2')
    .map(x => (x.children[0] as MarkdownRemarkText).value);
}

export class TableOfContents extends React.Component<TableOfContentsProperties> {
  get IsActive() {
    if (typeof window !== 'undefined') {
      return window.location.pathname.slice(1) === this.props.slug;
    }

    return false;
  }

  render() {
    const headers = chain(
      getHeaders,
      this.props.contents.map(x => x.content),
    );
    const pageLinks = getLinks(headers);

    return (
      <TableOfContentsDisplay
        items={pageLinks}
        isActive={this.IsActive}
        slug={this.props.slug}
        title={this.props.title}
      />
    );
  }
}

interface PageItemProperties {
  items: string[];
  title: string;
  slug: string;
  isActive: boolean;
}

const TableOfContentsDisplay = ({ items, title, slug, isActive }: PageItemProperties) => {
  const inputID = makeTextUrlFriendly(title) + Math.random();
  return (
    <div className={`table-of-contents-item ${isActive ? 'table-of-contents-item--active' : ''}`}>
      <input type="checkbox" id={inputID} defaultChecked={isActive} />
      <label htmlFor={inputID}>
        {!isActive && (
          <Link className="table-of-contents-item--title" to={`/${slug}`}>
            {title}
          </Link>
        )}
        {isActive && <span className="table-of-contents-item--title">{title}</span>}

        <i className="icon-arrow" />
      </label>
      <ul className="items">
        {items.map((item, index) => (
          <li key={`item-${index}`}>
            <Link to={`/${slug}#${makeTextUrlFriendly(item)}`}>{item}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
