import { getHeaderHeight } from './get-header-height';
import { getSecondaryMenuHeight } from './get-secondary-menu-height';

export const scrollToElement = (element: HTMLElement, smooth?: boolean) => {
  const rect = element.getBoundingClientRect();

  window.scrollTo({
    top: rect.top + window.scrollY - getHeaderHeight() - getSecondaryMenuHeight(),
    left: 0,
    behavior: smooth ? 'smooth' : undefined,
  });
};
