export const Categories = [
  {
    title: 'page.bloglanding.filters.industries',
    labels: [
      'page.bloglanding.filters.surveyingAndMapping',
      'page.bloglanding.filters.agriculture',
      'page.bloglanding.filters.constructionAndBim',
      'page.bloglanding.filters.culturalHeritage',
      'page.bloglanding.filters.environment',
      'page.bloglanding.filters.humanitarianAid',
      'page.bloglanding.filters.inspection',
      'page.bloglanding.filters.miningAndAggregates',
      'page.bloglanding.filters.publicSafety',
      'page.bloglanding.filters.publicWorks',
      'page.bloglanding.filters.realEstate',
      'page.bloglanding.filters.utilitiesAndEnergy',
      'page.bloglanding.filters.3dModeling',
    ],
  },
  {
    title: 'page.bloglanding.filters.products',
    labels: [
      'products.mapper',
      'products.capture',
      'products.catch',
      'products.cloud',
      'products.engine',
      'products.fields',
      'products.inspect',
      'products.matic',
      'products.react',
      'products.survey',
      'products.craneCamera',
      'products.viDocRtkRover',
      'page.bloglanding.filters.trainingAndCertification',
    ],
  },
  {
    title: 'page.bloglanding.filters.categories',
    labels: [
      'page.bloglanding.filters.newsAndEvents',
      'page.bloglanding.filters.research',
      'page.bloglanding.filters.softwareUpdates',
      'page.bloglanding.filters.tipsAndTricks',
      'page.bloglanding.filters.useCases',
      'page.bloglanding.filters.webinarAndEbook',
    ],
  },
];
