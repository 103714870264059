import { getMarkdownProperty, getMarkdownText } from '../../../utils';
import { Tile } from './tile';
import { TilesContentfulProperties } from './tiles.interface';

export const TilesContentfulPropertiesTransformer = ({
  items = [],
  children = [],
  rowitems,
  align,
}: TilesContentfulProperties) => {
  const transformedTiles = items
    .map(x => ({
      icon: x.icon,
      text: x.text,
      backgroundImage: getMarkdownText(x['background-image']),
      link: getMarkdownText(x.link),
      linkTarget: getMarkdownProperty(x.link, 'target'),
    }))
    .map(Tile);

  return {
    items: children.length === 0 ? transformedTiles : children,
    centered: align === 'center',
    row: rowitems,
  };
};
