import * as React from 'react';

export class CustomComponentErrorHandler extends React.Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error, errorInfo: info });
  }

  render() {
    if (this.state.error) {
      const component = (this.props as any).children.props.children;

      let componentName;
      try {
        componentName =
          component._owner.memoizedProps.markdown.childMarkdownRemark.htmlAst.children[0].tagName;
      } catch (e) {
        componentName = component.type.displayName;
      }

      console.error(`Custom Component [${componentName}] Error`);
      console.error('Contentful interpreted properties: ', component.props);

      return <h2>{componentName} has an error! More info in console log!</h2>;
    }

    return this.props.children;
  }
}
