import * as React from 'react';
import { Translate } from '../../../layout';
import { Icon } from '../../icon';

import './non-supported-devices-banner.scss';

interface NonSupportedDevicesBannerProperties {
  isMapper?: boolean;
  show?: boolean;
}

export const NonSupportedDevicesBanner = ({
  isMapper,
  show,
}: NonSupportedDevicesBannerProperties) => (
  <div>
    <div className={`non-supported-devices ${show ? '' : 'hide'} oneline`}>
      <Icon icon="info" color="orange" />{' '}
      <span>
        <Translate id="component.nonSupportedDevice.nonSupportedDevice" />
        {isMapper && (
          <div className="tablet-hide mobile-hide">
            <a href="https://community.pix4d.com/t/beta-macos-version-of-pix4dmapper-3-0/5672">
              <Translate id="component.nonSupportedDevice.clickHere" />
            </a>{' '}
            <Translate id="component.nonSupportedDevice.moreInfo" />
          </div>
        )}
      </span>
    </div>
  </div>
);
