import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Layout, OpenGraphHelmet } from '../components';
import { ContentfulSinglePage, SiteConfig } from '../interfaces';
import {
  ContentfulMarkdownRemarkRenderer,
  fixContentfulAssetPath,
  getLocaleFromPath,
  localizeLink,
} from '../utils';

import './simple-page.scss';
import { AuthContext } from '../components/contexts';
import { BackgroundWrapper } from '../components/layout';
import { getLoginUrl, isBrowser } from '../services/auth';

interface SimplePageProperties {
  location: any;
  pageContext: {
    hideHeaderAndFooter: boolean;
    englishCanonicalUrl: string;
    gated: boolean;
  };
  data: {
    contentfulPage: ContentfulSinglePage;
    site: SiteConfig;
  };
}

const SimplePage = ({
  pageContext: { hideHeaderAndFooter, englishCanonicalUrl, gated },
  location,
  data: {
    site,
    contentfulPage: {
      socialImage,
      title,
      slug,
      noIndex,
      noFollow,
      canonicalUrl,
      metaDescription,
      contents,
      backgroundColor,
      backgroundImage,
      backgroundImageScroll,
    },
  },
}: SimplePageProperties) => {
  if (!canonicalUrl) {
    canonicalUrl =
      site.siteMetadata.siteUrl + localizeLink(getLocaleFromPath(location.pathname), `/${slug}`);
  }

  const selectedSocialImage = socialImage
    ? fixContentfulAssetPath(socialImage.file.url)
    : undefined;

  return (
    <Layout
      englishCanonicalUrl={englishCanonicalUrl}
      title={title}
      notRenderHeaderAndFooter={hideHeaderAndFooter}
      headerDarkTheme={false}
      isGated={gated}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={metaDescription} />
        {(noIndex || gated) && <meta name="robots" content="noindex" />}
        {(noFollow || gated) && <meta name="robots" content="nofollow" />}
      </Helmet>
      <OpenGraphHelmet
        description={metaDescription}
        title={title}
        socialImage={selectedSocialImage}
        url={canonicalUrl}
        type="article"
      />
      <BackgroundWrapper
        scrollable={backgroundImageScroll}
        color={backgroundColor}
        image={backgroundImage}
      >
        <div className="simple-page">
          <AuthContext.Consumer>
            {({ isAuthenticated }) => {
              const canAccessPage = gated ? isAuthenticated : true;
              if (canAccessPage) {
                return (
                  <>
                    {contents &&
                      contents.map(({ content }, index) => (
                        <ContentfulMarkdownRemarkRenderer key={index} markdown={content} />
                      ))}
                  </>
                );
              } else {
                if (isBrowser) {
                  window.location.href = getLoginUrl();
                }
                return null;
              }
            }}
          </AuthContext.Consumer>
        </div>
      </BackgroundWrapper>
    </Layout>
  );
};

export default SimplePage;

export const simplePageQuery = graphql`
  query simplePageQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, fields: { locale: { eq: $locale } }) {
      title
      metaDescription
      backgroundColor
      backgroundImageScroll
      backgroundImage {
        title
        file {
          url
        }
      }
      slug
      noIndex
      noFollow
      socialImage {
        file {
          url
        }
      }
      canonicalUrl
      contents {
        content: childContentfulContentTextTextNode {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
