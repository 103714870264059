import { evolve } from 'ramda';
import { MarkdownRemark } from '../../../interfaces';
import { getMarkdownText } from '../../../utils';

interface VerticalWorkflowItem {
  type: 'block' | 'side-block';
  title: MarkdownRemark;
  text: MarkdownRemark;
  icon: MarkdownRemark;
  dottedLine?: boolean;
}

interface VerticalWorkflowContentfulProperties {
  items: VerticalWorkflowItem[];
}

export const VerticalWorkflowContentfulPropertiesTransformer = ({
  items = [],
}: VerticalWorkflowContentfulProperties) => {
  const transformedTiles = items.map(
    evolve({
      text: getMarkdownText,
      title: getMarkdownText,
    }),
  );

  return {
    items: transformedTiles,
  };
};
