import * as React from 'react';
import { MarkdownRemarkRenderer } from '../../../utils';
import { LinkLocalizer } from '../../layout/link-localizer';
import { TileProperties } from './tiles.interface';

export const Tile = ({ icon, text, link, linkTarget, backgroundImage }: TileProperties) => {
  if (link) {
    return (
      <LinkLocalizer
        className="tile box-shadow"
        to={link}
        target={linkTarget}
        style={{ backgroundImage: backgroundImage && `url(${backgroundImage}?w=480&q=80)` }}
      >
        <div className="image">
          <MarkdownRemarkRenderer markdown={icon} />
        </div>
        <div className="text">
          <MarkdownRemarkRenderer markdown={text} />
        </div>
      </LinkLocalizer>
    );
  }

  return (
    <div
      className="tile box-shadow"
      style={{ backgroundImage: backgroundImage && `url(${backgroundImage}?w=480&q=80)` }}
    >
      <div className="image">
        <MarkdownRemarkRenderer markdown={icon} />
      </div>
      <div className="text">
        <MarkdownRemarkRenderer markdown={text} />
      </div>
    </div>
  );
};
