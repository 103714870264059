import { Animatable } from '../interfaces';

const queue: Animatable[] = [];
let isAnimationInProgress = false;
let readyForExecution = false;

export const removeFromPageAnimationSchedule = (component: Animatable) => {
  const index = queue.indexOf(component);
  if (index >= 0) {
    queue.splice(index, 1);
  }
};

const executeNextComponent = () => {
  if (queue.length > 0 && readyForExecution) {
    isAnimationInProgress = true;
    const component = queue.shift();
    component!.activate();
    setTimeout(() => {
      isAnimationInProgress = false;
      executeNextComponent();
    }, 100);
  }
};

export const startPageAnimation = () => {
  if (!readyForExecution && !isAnimationInProgress) {
    readyForExecution = true;
    executeNextComponent();
  }
};

export const scheduleForPageAnimation = (component: Animatable) => {
  queue.push(component);
  if (!isAnimationInProgress) {
    executeNextComponent();
  }
};
