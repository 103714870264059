function edgeLanguageFilter(contentfulEdge) {
  // In contentful in activeTranslations the japanese country code is ja
  const activeTranslationsLocale =
    contentfulEdge.node.fields.locale === 'jp' ? 'ja' : contentfulEdge.node.fields.locale;

  return (
    contentfulEdge.node.fields.locale === 'en-US' ||
    (contentfulEdge.node.activeTranslations !== null &&
      contentfulEdge.node.activeTranslations.indexOf(activeTranslationsLocale) !== -1)
  );
}

module.exports = { edgeLanguageFilter };
