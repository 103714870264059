import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  Block,
  Layout,
  OpenGraphHelmet,
  SecondaryMenu,
  StickyLeftSidebar,
  TableOfContents,
} from '../components';
import {
  ContentfulContent,
  ContentfulEdges,
  ContentfulSinglePage,
  SiteConfig,
} from '../interfaces';
import {
  alterHeadersWithAnchors,
  ContentfulMarkdownRemarkRenderer,
  fixContentfulAssetPath,
  localizeLink,
} from '../utils';

import './table-of-contents-page.scss';

interface TableOfContentsPageProperties {
  data: {
    contentfulPage: ContentfulSinglePage;
    site: SiteConfig;
    tableOfContentData: ContentfulEdges<{
      title: string;
      slug: string;
      contents: ContentfulContent[];
    }>;
  };
  pageContext: {
    englishCanonicalUrl: string;
    tableOfContentSlugs: string[];
    tableOfContentTitle: string;
  };
}

const TableOfContentsPage = ({
  data: { contentfulPage, site, tableOfContentData },
  pageContext,
}: TableOfContentsPageProperties) => {
  const {
    title,
    metaDescription,
    slug,
    fields: { locale },
    canonicalUrl,
    socialImage,
    contents,
    noIndex,
    noFollow,
    heroImage,
  } = contentfulPage;

  const selectedSocialImage = socialImage ? fixContentfulAssetPath(socialImage.file.url) : '';
  const canonical = canonicalUrl || site.siteMetadata.siteUrl + localizeLink(locale, `/${slug}`);

  const tableOfContentPagesNoSlugModified =
    tableOfContentData.edges.length > 0
      ? tableOfContentData.edges.map(x => x.node)
      : [{ title, contents, slug }];

  const tableOfContentPages = tableOfContentPagesNoSlugModified.map(x => {
    const localizedSlug = localizeLink(locale, x.slug);

    return { ...x, slug: localizedSlug };
  });

  const { tableOfContentTitle, englishCanonicalUrl } = pageContext;

  const contentContainerRef = React.createRef<HTMLDivElement>();

  const secondaryMenu = (
    <SecondaryMenu
      label={tableOfContentTitle || title}
      labelExtend={tableOfContentTitle ? title : undefined}
      hideOnDesktop
    >
      <div className="toc-page-secondary-menu-content">
        {tableOfContentTitle && <div className="title">{tableOfContentTitle}</div>}
        {tableOfContentPages.map((toc, index) => (
          <TableOfContents
            key={`toc-${index}`}
            contents={toc.contents}
            title={toc.title}
            slug={toc.slug}
          />
        ))}
      </div>
    </SecondaryMenu>
  );

  return (
    <Layout
      title={title}
      headerDarkTheme={false}
      secondaryMenu={secondaryMenu}
      englishCanonicalUrl={englishCanonicalUrl}
    >
      <Helmet>
        <html lang={locale} />
        <link rel="canonical" href={canonical} />
        <meta name="description" content={metaDescription} />
        {noIndex && <meta name="robots" content="noindex" />}
        {noFollow && <meta name="robots" content="nofollow" />}
      </Helmet>
      <OpenGraphHelmet
        title={title}
        description={metaDescription}
        socialImage={selectedSocialImage}
        url={canonical}
        type="article"
      />
      <div className="toc-page">
        <div>
          {heroImage && (
            <div className="toc-page__hero">
              <img alt={heroImage.title} src={fixContentfulAssetPath(heroImage.file.url)} />
            </div>
          )}
          <Block wide extraClasses={['toc-content-wrap']}>
            <StickyLeftSidebar contentRef={contentContainerRef} hideOnTablet>
              <div className="toc-page__left-panel">
                {tableOfContentTitle && <div className="title">{tableOfContentTitle}</div>}
                {tableOfContentPages.map((toc, index) => (
                  <TableOfContents
                    key={`toc-page-${index}`}
                    contents={toc.contents}
                    title={toc.title}
                    slug={toc.slug}
                  />
                ))}
              </div>
            </StickyLeftSidebar>
            <div className="toc-page__content" ref={contentContainerRef}>
              {contents &&
                contents.map(
                  ({ content }, index) =>
                    content && (
                      <ContentfulMarkdownRemarkRenderer
                        key={index}
                        markdown={alterHeadersWithAnchors(content)}
                      />
                    ),
                )}
            </div>
          </Block>
        </div>
      </div>
    </Layout>
  );
};

export default TableOfContentsPage;

export const pageQuery = graphql`
  query tableOfContentsPageQuery(
    $slug: String!
    $locale: String!
    $tableOfContentSlugs: [String]!
  ) {
    contentfulPage(slug: { eq: $slug }, fields: { locale: { eq: $locale } }) {
      title
      metaDescription
      slug
      fields {
        locale
      }
      canonicalUrl
      socialImage {
        file {
          url
        }
      }
      heroImage {
        title
        file {
          url
        }
      }
      noIndex
      noFollow
      contents {
        content: childContentfulContentTextTextNode {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
    tableOfContentData: allContentfulPage(
      filter: { slug: { in: $tableOfContentSlugs }, fields: { locale: { eq: $locale } } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          contents {
            content: childContentfulContentTextTextNode {
              childMarkdownRemark {
                htmlAst
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
