import { Session, SessionJson, UserJson, User } from './types';

export const serializeSession = (session: Session): SessionJson => {
  return {
    access_token: session.accessToken,
    token_type: session.tokenType,
  };
};

export const deserializeSession = (json: SessionJson): Session => {
  return {
    accessToken: json.access_token,
    tokenType: json.token_type,
  };
};

export const deserializeUser = (json: UserJson): User => {
  return {
    initial: json.first_name[0],
    fullName: `${json.first_name} ${json.last_name}`,
    firstName: json.first_name,
  };
};
