import * as React from 'react';
import ReactDOM from 'react-dom';
import { Animatable } from '../../interfaces';
import {
  removeFromPageAnimationSchedule,
  scheduleForPageAnimation,
} from '../../utils/page-animation-queue';

import { DeviceMode, getDeviceMode } from '../../utils';
import './animation.scss';

interface AnimationProperties {
  children: React.DOMElement<any, any> | JSX.Element;
  animation?: 'slide-up' | 'fade-in' | 'fade-out';
}

class AnimationClass extends React.PureComponent<AnimationProperties> implements Animatable {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.onScroll);
    }
  }

  childRef = React.createRef<HTMLElement>();

  get ChildClasses() {
    const animationClasses = [];
    const childClasses = (this.props.children.props.className || '').split(' ');

    return [...childClasses, ...animationClasses].join(' ');
  }

  get ChildNode() {
    return ReactDOM.findDOMNode(this.childRef.current!) as HTMLElement;
  }

  get AnimationType() {
    return this.props.animation || 'slide-up';
  }

  componentDidMount() {
    const node = this.ChildNode;
    if (!node) {
      return;
    }
    node.classList.add(...[`animate-${this.AnimationType}`]);
    this.onScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    removeFromPageAnimationSchedule(this);
  }

  onScroll = () => {
    const node = this.ChildNode;
    if (!node) {
      window.removeEventListener('scroll', this.onScroll);
      removeFromPageAnimationSchedule(this);
      return;
    }
    const rect = node.getBoundingClientRect();
    const triggerPosition = rect.top + rect.height * 0.25;
    if (triggerPosition < window.innerHeight) {
      window.removeEventListener('scroll', this.onScroll);
      scheduleForPageAnimation(this);
    }
  };

  activate = () => {
    const node = this.ChildNode;
    if (!node) {
      window.removeEventListener('scroll', this.onScroll);
      removeFromPageAnimationSchedule(this);
      return;
    }
    node.classList.add('animated');
  };

  render() {
    return React.cloneElement(this.props.children, {
      ...this.props.children.props,
      className: this.ChildClasses,
      ref: this.childRef,
    });
  }
}

export let Animation;

if (getDeviceMode && getDeviceMode() === DeviceMode.Mobile) {
  Animation = ({ children }) => children;
} else {
  Animation = AnimationClass;
}
