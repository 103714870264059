module.exports = {
  // languages enabled in the language selector
  enabledLanguages: {
    'en-US': 'language.english',
    de: 'language.german',
    es: 'language.spanish',
    fr: 'language.french',
    jp: 'language.japanese',
    ru: 'language.russian',
    pt: 'language.portuguese',
  },
  displayLanguageSelector: true,
  defaultLangKey: 'en-US',
};
