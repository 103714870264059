import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Layout, OpenGraphHelmet } from '../components';
import { ContentfulFramePage, SiteConfig } from '../interfaces';
import { fixContentfulAssetPath, localizeLink } from '../utils';
import { AuthContext } from '../components/contexts';
import { getLoginUrl, isBrowser } from '../services/auth';
import { FramePageController } from './frame-page-controller';

interface FramePageProperties {
  location: any;
  pageContext: { hideHeaderAndFooter: boolean; englishCanonicalUrl: string; gated: boolean };
  data: {
    contentfulPage: ContentfulFramePage;
    site: SiteConfig;
  };
}

const themes = {
  construction: 'construction',
  agriculture: 'agriculture',
  enterprise: 'enterprise',
};

const getPageTheme = (categories: string[] = []): string | undefined =>
  themes[categories.map(x => x.toLowerCase()).find(category => themes[category])!];

export class FramePage extends React.Component<FramePageProperties> {
  render() {
    const {
      title,
      metaDescription,
      slug,
      fields: { locale },
      canonicalUrl,
      socialImage,
      category,
      noIndex,
      noFollow,
    } = this.props.data.contentfulPage;
    const { englishCanonicalUrl, hideHeaderAndFooter, gated } = this.props.pageContext;

    const { site } = this.props.data;
    const frames = this.props.data.contentfulPage.frames || [];

    let selectedSocialImage = '';

    if (socialImage) {
      selectedSocialImage = socialImage.file.url;
    } else if (frames[0] && frames[0].backgroundImage !== null) {
      selectedSocialImage = frames[0].backgroundImage!.file.url;
    }

    selectedSocialImage = fixContentfulAssetPath(selectedSocialImage);

    const slashSlug = slug[0] === '/' ? slug : `/${slug}`;

    const canonical =
      canonicalUrl || `${site.siteMetadata.siteUrl}${localizeLink(locale, slashSlug)}`;
    const theme = category !== null ? getPageTheme(category) : undefined;

    const isHomePage = slug === 'home';

    return (
      <Layout
        title={title}
        englishCanonicalUrl={englishCanonicalUrl}
        notRenderHeaderAndFooter={hideHeaderAndFooter}
        isGated={gated}
      >
        <Helmet>
          <html lang={locale} />
          <link rel="canonical" href={canonical} />
          <meta name="description" content={metaDescription} />
          {(noIndex || gated) && <meta name="robots" content="noindex" />}
          {(noFollow || gated) && <meta name="robots" content="nofollow" />}
        </Helmet>

        <AuthContext.Consumer>
          {({ isAuthenticated }) => {
            const canAccessPage = gated ? isAuthenticated : true;
            if (canAccessPage) {
              return (
                <>
                  <OpenGraphHelmet
                    title={title}
                    description={metaDescription}
                    socialImage={selectedSocialImage}
                    url={canonical}
                    type="article"
                  />

                  <FramePageController
                    key={canonical}
                    theme={theme}
                    useDarkTheme={false}
                    frames={frames}
                    isHomePage={isHomePage}
                  />
                </>
              );
            } else {
              if (isBrowser) {
                window.location.href = getLoginUrl();
              }
              return null;
            }
          }}
        </AuthContext.Consumer>
      </Layout>
    );
  }
}

export default FramePage;

export const framePageQuery = graphql`
  query framePageQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, fields: { locale: { eq: $locale } }) {
      title
      metaDescription
      slug
      category
      fields {
        locale
      }
      canonicalUrl
      noIndex
      noFollow
      socialImage {
        file {
          url
        }
      }
      frames {
        backgroundColor
        backgroundParallax
        backgroundVideo {
          file {
            url
            contentType
          }
        }
        backgroundImage {
          title
          file {
            url
          }
        }
        contents {
          content: childContentfulContentTextTextNode {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
