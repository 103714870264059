import * as React from 'react';
import { Animation, ResponsiveTemplate } from '../../layout';
import { Slider } from '../slider';
import { ColumnFeatures, ColumnFeaturesProperties } from './column-features';

export const ColumnFeaturesResponsive = ({ items }: ColumnFeaturesProperties) => (
  <ResponsiveTemplate>
    <ResponsiveTemplate.Mobile>
      <Slider rotateSlides items={items} itemsPerSlide={1} itemTemplate={ColumnFeatures} />
    </ResponsiveTemplate.Mobile>
    <ResponsiveTemplate.Desktop>
      <ColumnFeatures items={items} />
    </ResponsiveTemplate.Desktop>
  </ResponsiveTemplate>
);

export const ColumnFeaturesAnimatedResponsive = ({ items }: ColumnFeaturesProperties) => (
  <ResponsiveTemplate>
    <ResponsiveTemplate.Mobile>
      <Slider rotateSlides items={items} itemsPerSlide={1} itemTemplate={ColumnFeatures} />
    </ResponsiveTemplate.Mobile>
    <ResponsiveTemplate.Desktop>
      <Animation>
        <ColumnFeatures items={items} />
      </Animation>
    </ResponsiveTemplate.Desktop>
  </ResponsiveTemplate>
);
