import * as React from 'react';
import { LinkLocalizer } from './link-localizer';

export interface HeaderLink {
  to: string;
  allowedLanguages?: Array<string>;
  children: React.ReactNode;
  target?: string;
  rel?: string;
}

export const HeaderLink = ({ children, ...rest }: HeaderLink): JSX.Element => {
  return (
    <LinkLocalizer
      onClick={() => {
        (document.getElementById('mobile-menu') as HTMLInputElement).checked = false;
      }}
      {...rest}
    >
      {children}
    </LinkLocalizer>
  );
};
