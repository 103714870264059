import * as React from 'react';
import { LinkLocalizer } from '../../layout/link-localizer';

import './call-to-action.scss';

interface CallToActionProperties {
  title: string;
  subtitle: string;
  primaryButton: string;
  primaryButtonLink: string;
  primaryButtonTarget?: string;
  secondaryButton?: string;
  secondaryButtonLink?: string;
  secondaryButtonTarget?: string;
}

export const CallToAction = ({
  title,
  subtitle,
  primaryButton,
  primaryButtonLink,
  primaryButtonTarget,
  secondaryButton,
  secondaryButtonLink,
  secondaryButtonTarget,
}: CallToActionProperties) => (
  <div className="call-to-action box-shadow">
    <div className="title">{title}</div>
    <div className="subtitle">{subtitle}</div>
    <div className="actions">
      <LinkLocalizer
        to={primaryButtonLink}
        className="btn btn--primary"
        target={primaryButtonTarget}
      >
        {primaryButton}
      </LinkLocalizer>
      {secondaryButton && secondaryButtonLink && (
        <LinkLocalizer
          to={secondaryButtonLink}
          className="btn btn--secondary"
          target={secondaryButtonTarget}
        >
          {secondaryButton}
        </LinkLocalizer>
      )}
    </div>
  </div>
);
