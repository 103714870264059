import * as Cookie from 'js-cookie';
import * as React from 'react';
import { CookieBar } from './cookie-bar';

interface CookieBarContainerState {
  hasCookieBar: boolean;
}

interface CookieBarContainerProperties {}

const CookieName = 'pix4d-cookie-policy';
const CookieVersion = 3;
const CookieDomain = 'pix4d.com';

export const isValidCookieVersion = (value: number | boolean | undefined): boolean => {
  return value !== undefined && value >= CookieVersion;
};

const getCookieVersion = (): number | undefined => {
  const value = Cookie.get(CookieName);
  return value ? parseInt(value, 10) : undefined;
};

export const setCookieVersion = (settings): void => {
  Cookie.set(CookieName, `${CookieVersion}`, { expires: 365, domain: CookieDomain });
  Object.entries(settings).forEach((el: any) => {
    Cookie.set(el[0], el[1], { expires: 365, domain: CookieDomain });
  });
};

export class CookieBarContainer extends React.Component<
  CookieBarContainerProperties,
  CookieBarContainerState
> {
  constructor(props) {
    super(props);
    this.state = { hasCookieBar: false };
  }

  componentDidMount = () => {
    const showCookieBar = !isValidCookieVersion(getCookieVersion());
    this.setState({ hasCookieBar: showCookieBar });
  };

  acceptCookiePolicy = settings => {
    setCookieVersion(settings);
    let slug: string;
    if (window.dataLayer) {
      Object.entries(settings).forEach(item => {
        slug = item[1] ? '_granted' : '_denied';
        window.dataLayer.push({ event: item[0] + slug });
      });
      window.dataLayer.push({ event: 'consent_settings_update' });
    }

    this.setState({
      hasCookieBar: false,
    });
  };

  render() {
    return this.state.hasCookieBar ? (
      <CookieBar acceptCookiePolicy={this.acceptCookiePolicy} />
    ) : null;
  }
}
