import * as React from 'react';

import { ZoomContext } from './zoom.context';
import './zoomable.scss';

export const Zoomable = ({ children }) => {
  const [zoomInstance, setZoomInstance] = React.useState(false);
  const { zoom, setZoom } = React.useContext(ZoomContext);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      setZoomInstance(!zoom);
    }
  };

  return (
    <div
      className={`zoomable ${zoom && zoomInstance ? 'zoomable-extended' : ''}`}
      tabIndex={0}
      onClick={() => {
        setZoom(!zoom);
        setZoomInstance(!zoom);
      }}
      onKeyDown={handleKeyDown}
    >
      <div className="zoomable--content">{children}</div>
    </div>
  );
};
