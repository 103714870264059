import { assoc, map, merge, pipe, prop, toPairs } from 'ramda';
import { MarkdownRemarkElement } from '../../../interfaces';
import { isCustomComponent } from '../is-custom-component';
import { isPropertyNode } from '../is-property-node';

const isFinalPropertyNode = (node: MarkdownRemarkElement) =>
  !isCustomComponent(node) &&
  (node.children === undefined ||
    node.children.every(x => x.type === 'text' || (x.type === 'element' && !isPropertyNode(x))));
const getPropertyNodeValue = (node: MarkdownRemarkElement): MarkdownRemarkElement => ({
  type: 'element',
  properties: node.properties,
  tagName: 'element-property-node',
  children: node.children || [],
});

const propertiesMapReducer = (acc, { property, value }) => {
  if (property in acc) {
    acc[property] = acc[property].length ? acc[property] : [acc[property]];
    acc[property].push(value);
  } else {
    acc[property] = value;
  }

  return acc;
};

const convertNodeTreeToProperties = (node: MarkdownRemarkElement) => {
  if (isFinalPropertyNode(node)) {
    return getPropertyNodeValue(node);
  }

  const children = (node.children as MarkdownRemarkElement[]).map(x => ({
    property: x.tagName,
    value: convertNodeTreeToProperties(x),
  }));

  const properties = pipe<any, any, any>(
    toPairs,
    map((pair: any[]) => ({ property: pair[0], value: pair[1] })),
  )(node.properties);

  return [...properties, ...children].reduce(propertiesMapReducer, {});
};

export const CustomElementPropertiesTransformer = (
  element: MarkdownRemarkElement,
): MarkdownRemarkElement => {
  if (isCustomComponent(element)) {
    const customElementProperties = convertNodeTreeToProperties(element);

    if (customElementProperties.items && customElementProperties.items.item) {
      customElementProperties.items =
        'length' in customElementProperties.items.item
          ? customElementProperties.items.item
          : [customElementProperties.items.item];
    }

    const noPropertiesChildren = element.children.filter(
      x => x.type === 'element' && !isPropertyNode(x),
    );
    return pipe(
      assoc('children', noPropertiesChildren),
      assoc('properties', merge(prop('properties', element), customElementProperties)),
    )(element) as any;
  }

  return element;
};
