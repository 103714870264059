import * as React from 'react';

import './block-wide.scss';

interface BlockWideProps {
  children: any;
}

interface BlockWideState {
  childrenHeight: number;
}

export class BlockWide extends React.PureComponent<BlockWideProps, BlockWideState> {
  state = {
    childrenHeight: 0,
  };

  private nodeRef: HTMLDivElement | undefined | null;

  updateDimensions = () => this.setState(() => ({ childrenHeight: this.nodeRef!.clientHeight }));

  componentDidMount() {
    this.updateDimensions();
    this.WatchingItems.map(x => x.addEventListener('load', this.onLoad));
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    this.WatchingItems.map(x => x.removeEventListener('load', this.onLoad));
    window.removeEventListener('resize', this.updateDimensions);
  }

  onLoad = () => {
    this.updateDimensions();
  };

  get WatchingItems(): HTMLElement[] {
    return Array.prototype.slice.call(this.nodeRef!.querySelectorAll('img, iframe'));
  }

  render() {
    return (
      <>
        <div ref={elem => (this.nodeRef = elem)} className="wide-content">
          {this.props.children}
        </div>
        <div style={{ height: this.state.childrenHeight }} />
      </>
    );
  }
}
