import { TRANSLATION_KEYS } from './translation_keys';

export const DEFAULT_TRANSLATIONS: Record<TRANSLATION_KEYS, string> = {
  'header.login': 'Login',
  'header.contact': 'Contact',
  'header.tryForFree': 'Try for free',
  'header.blog': 'Blog',
  'header.cd': 'Cyber Monday',
  'header.pricing': 'Pricing',
  'header.supportAndTraining': 'Services & Resources',
  'header.supportAndTraining.technicalSupport': 'Technical support',
  'header.supportAndTraining.support-offer': 'Support offer',
  'header.supportAndTraining.documentation': 'Documentation',
  'header.supportAndTraining.training': 'Training',
  'header.supportAndTraining.certification': 'Certification',
  'header.supportAndTraining.community': 'Community',
  'header.supportAndTraining.onlineCourse': 'Online Courses',
  'header.supportAndTraining.workshop': 'Workshops',
  'header.supportAndTraining.consultation': 'Personal Consultation',
  'header.supportAndTraining.videotutorials': 'Video tutorials',
  'header.industries': 'Industries',
  'header.industries.surveyAndMapping': 'Surveying & mapping',
  'header.industries.surveying': 'Digital surveying',
  'header.industries.construction': 'Construction',
  'header.industries.agriculture': 'Agriculture',
  'header.industries.miningAndAggregates': 'Mining & aggregates',
  'header.industries.publicSafety': 'Public safety',
  'header.industries.education': 'Education',
  'header.industries.oem': 'OEM & integrators',
  'header.industries.inspection': 'Inspection',
  'header.industries.inspectionandTelecom': 'Inspection and Telecom',
  'header.industries.cloud': 'Cloud solutions',
  'header.industries.telecom': 'Telecom',
  'header.industries.roofing': 'Roofing',
  'header.products': 'Products',
  'header.products.software': 'Mapping and 3D modeling',
  'header.products.hardware': 'Data Capture Hardware',
  'header.products.capture': 'Data Capture Applications',
  'header.user.logout': 'Log out',
  'header.user.accessCloud': 'Access PIX4Dcloud',
  'header.user.accessInspect': 'Access PIX4Dinspect',
  'header.user.accountSettings': 'Account settings',
  'header.network.conference': 'User Conference',
  'products.mapper': 'PIX4Dmapper',
  'products.bim': 'PIX4Dbim',
  'products.fields': 'PIX4Dfields',
  'products.react': 'PIX4Dreact',
  'products.survey': 'PIX4Dsurvey',
  'products.model': 'PIX4Dmodel',
  'products.matic': 'PIX4Dmatic',
  'products.capture': 'PIX4Dcapture',
  'products.catch': 'PIX4Dcatch',
  'products.cloud': 'PIX4Dcloud',
  'products.engine': 'PIX4Dengine',
  'products.inspect': 'PIX4Dinspect',
  'products.scan': 'PIX4Dscan',
  'products.craneCamera': 'CraneCamera',
  'products.parrotSequoia': 'Parrot Sequoia+',
  'products.viDocRtkRover': 'viDoc RTK rover',
  'language.english': 'English',
  'language.french': 'French',
  'language.german': 'German',
  'language.japanese': 'Japanese',
  'language.portuguese': 'Portuguese',
  'language.russian': 'Russian',
  'language.spanish': 'Spanish',
  'footer.company': 'Company',
  'footer.company.about': 'About',
  'footer.company.locations': 'Locations',
  'footer.company.careers': 'Careers',
  'footer.company.useCases': 'Use cases',
  'footer.products': 'Products',
  'footer.industries': 'Industries',
  'footer.resources': 'Resources',
  'footer.resources.guide': 'Product guide',
  'footer.resources.download': 'Download software',
  'footer.resources.webinar': 'Webinars & ebooks',
  'footer.resources.documentation': 'Documentation',
  'footer.resources.workshops': 'Workshops',
  'footer.resources.training': 'Training',
  'footer.resources.blog': 'Blog',
  'footer.media': 'Media',
  'footer.media.newsroom': 'Media',
  'footer.network': 'Network',
  'footer.network.becomePartner': 'Become a partner',
  'footer.network.locatePartner': 'Locate a partner',
  'footer.network.meetUs': 'Meet us at an event',
  'footer.network.conference': 'Pix4D User Conference',
  'footer.joinCommunity': 'Join our community',
  'footer.subscribeNewsletter': 'Subscribe to our newsletter',
  'footer.termsAndPolicy': 'Terms & privacy',
  'footer.rights': '© 2022 Pix4D SA, all rights reserved',
  'page.404.header': 'Uh oh, ...',
  'page.404.subtitle': "seems like this page doesn't exist.",
  'page.404.error': '404 Page Not Found',
  'page.404.errorDescription':
    "We're sorry, the page you are looking for was moved, removed, renamed or might have never existed.",
  'page.404.getBackOnTrack': 'To get back on track, click on one of the following links',
  'page.404.linkHome': 'Home',
  'page.404.linkTraining': 'Training',
  'page.404.linkSupport': 'Support',
  'page.blog.relatedArticles': 'Related articles',
  'page.blog.downloadPdf': 'Download PDF',
  'page.bloglanding.title': 'Pix4D blog: Stories and use cases about drone mapping',
  'page.bloglanding.description':
    'Discover use cases, tips and tricks and unique stories about drone mapping that any photogrammetrist or drone enthusiast would not want to miss.',
  'page.bloglanding.filters.industries': 'Industries',
  'page.bloglanding.filters.products': 'Products',
  'page.bloglanding.filters.categories': 'Categories',
  'page.bloglanding.filters.useCases': 'Use cases',
  'page.bloglanding.filters.articles': 'Articles',
  'page.bloglanding.filters.softwareUpdates': 'Software updates',
  'page.bloglanding.filters.tipsAndTricks': 'Tips & tricks',
  'page.bloglanding.filters.newsAndEvents': 'News & events',
  'page.bloglanding.filters.agriculture': 'Agriculture',
  'page.bloglanding.filters.culturalHeritage': 'Cultural heritage',
  'page.bloglanding.filters.energy': 'Energy',
  'page.bloglanding.filters.insurance': 'Insurance',
  'page.bloglanding.filters.miningAndAggregates': 'Mining & Earthworks',
  'page.bloglanding.filters.publicSafety': 'Public safety',
  'page.bloglanding.filters.publicWorks': 'Public works',
  'page.bloglanding.filters.realEstate': 'Real estate',
  'page.bloglanding.filters.research': 'Research',
  'page.bloglanding.filters.utilitiesAndEnergy': 'Utilities & Energy',
  'page.bloglanding.filters.trainingAndCertification': 'Training & certification',
  'page.bloglanding.filters.pix4dcloudAdvanced': 'PIX4Dcloud Advanced',
  'page.bloglanding.filters.3dModeling': '3D modeling',
  'page.bloglanding.filters.aerialMapping': 'Aerial mapping',
  'page.bloglanding.filters.aerialPhotogrammetry': 'Aerial photogrammetry',
  'page.bloglanding.filters.earthworks': 'Earthworks',
  'page.bloglanding.filters.digitalScoutingCrops': 'Digital scouting/crops',
  'page.bloglanding.filters.environment': 'Environment',
  'page.bloglanding.filters.forensics': 'Forensics',
  'page.bloglanding.filters.indoorMapping': 'Indoor mapping',
  'page.bloglanding.filters.inspection': 'Inspection & Telecom',
  'page.bloglanding.filters.surveying': 'Surveying',
  'page.bloglanding.filters.webinarAndEbook': 'Webinar & Ebook',
  'page.bloglanding.filters.scanningAndMapping': 'Scanning & mapping',
  'page.bloglanding.filters.humanitarianAid': 'Humanitarian aid',
  'page.bloglanding.filters.surveyingAndMapping': 'Surveying & mapping',
  'page.bloglanding.filters.constructionAndBim': 'Construction/BIM',
  'page.bloglanding.filters.terestrialscanning': 'Terrestrial scanning',
  'page.bloglanding.noResult': 'No results were found for',
  'page.bloglanding.noResultClearFilters': 'clear all filters',
  'page.bloglanding.noResultHelp': 'Try broadening your search to see more results or',
  'page.search-page.header': 'pix4d.com search',
  'page.search-page.indexType.blog': 'Blog',
  'page.search-page.indexType.support': 'Support',
  'page.search-page.indexType.page': 'Page',
  'page.search-page.results': 'results found.',
  'page.search-page.noResultsFound': 'Sorry, no results were found ....',
  'pageFragment.recentPosts.title': 'Pix4D Recent Blogposts',
  'component.readMore': 'Read more',
  'component.countdown.days': 'days',
  'component.countdown.hours': 'hours',
  'component.countdown.minutes': 'minutes',
  'component.countdown.seconds': 'seconds',
  'component.downloadButton.download': 'Download',
  'component.nonSupportedDevice.nonSupportedDevice':
    'This software is not supported on your system.',
  'component.nonSupportedDevice.moreInfo': 'for more information.',
  'component.nonSupportedDevice.clickHere': 'Click here',
  'component.otherOperatingSystems.windows': 'Windows',
  'component.otherOperatingSystems.mac': 'MacOS',
  'component.otherOperatingSystems.linux': 'Linux',
  'component.otherOperatingSystems.operatingSystems': 'Operating systems',
  'component.cookieBar.agreement':
    'We - and our partners - use cookies to deliver our services. By using our website, you agree to the use of cookies as described in our',
  'component.cookieBar.cookiePolicyLink': 'Privacy Notice',
  'component.cookieBar.necessaryCookies': 'Necessary cookies',
  'component.cookieBar.necessaryCookiesText':
    'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.  These cookies do not store any personally identifiable information.',
  'component.cookieBar.webCookies': 'Web analytics cookies',
  'component.cookieBar.webCookiesText':
    'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.',
  'component.cookieBar.targetingCookies': 'Targeting cookies',
  'component.cookieBar.targetingCookiesText':
    'These cookies may be set through our site by our advertising partner (Google). They may be used by Google to build a profile of your interests and show you relevant adverts on other sites.  They do not directly store personal information but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.',
  'component.cookieBar.settingsBtn': 'Settings',
  'component.cookieBar.saveSettingsBtn': 'Save Settings',
  'component.cookieBar.acceptAllBtn': 'Accept All',
  'component.cookieBar.rejectAllBtn': 'Reject All',
  'component.price.buyNow': 'Buy now',
  'component.price.otcPeriod': 'one-time',
  'component.price.otcYearPeriod': 'Year',
  'component.price.project': 'Project',
  'component.price.monthlyPeriod': 'month',
  'component.price.monthlyCaption': 'billed monthly',
  'component.price.yearlyPeriod': 'Month',
  'component.price.yearlyCaption': 'billed yearly',
  'component.price.yearlyCaptionDiscount': '16% off - ',
  'component.priceToggle.annual': 'Yearly',
  'component.priceToggle.monthly': 'Monthly',
  'component.userWelcome.personalWelcome': 'Welcome back,',
  'component.userWelcome.mainWelcome':
    'Manage your account settings and get to PIX4Dcloud from anywhere.',
  'component.softwareVersionBanner.versionMessage':
    'Your Support & Upgrade subscription has expired. You can only use up to version {version} of the software.',
  'component.softwareVersionBanner.checkMessage': 'Please check',
  'component.softwareVersionBanner.licenceMessage': 'the license page',
  'component.softwareVersionBanner.detailsMessage':
    'for more details and extend your subscription.',
  'component.softwareVersionBanner.link': 'Download valid version',
  'component.workable.createdAt': 'Created at',
  'component.workable.department': 'Department',
  'component.workable.location': 'Location',
  'component.workable.loading': 'Loading data',
};
