export const calculateSrcset = (src: string, width: number): string | undefined => {
  if (src === undefined || src === null) {
    return undefined;
  }
  if (src.indexOf('.svg') !== -1) {
    return undefined;
  }
  if (src.indexOf('.gif') !== -1) {
    return undefined;
  }
  if (src.toLowerCase().indexOf('logo') !== -1) {
    return undefined;
  }
  if (src.toLowerCase().indexOf('button') !== -1) {
    return undefined;
  }
  if (src.indexOf('?') !== -1) {
    return undefined;
  }

  const url = src.trim();

  return `
    ${url}?w=${width}&q=80 1x,
    ${url}?w=${width * 1.5}&q=80 1.5x,
    ${url}?w=${width * 2}&q=80 2x
  `;
};
