interface DoubleLinkProperties {
  children: Array<JSX.Element | undefined> | JSX.Element;
  downloadlink: string;
  to?: string;
}

export const DoubleLinkPropertiesTransformer = ({
  to,
  children,
  ...rest
}: DoubleLinkProperties) => {
  return {
    children,
    downloadLink: rest['downloadlink'],
    to,
  };
};
