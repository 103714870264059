import { MarkdownRemark } from '../../../interfaces';
import { getMarkdownText } from '../../../utils';
import { GridItem } from './grid';

interface GridContentfulProperties {
  rowitems: number;
  items: {
    icon: MarkdownRemark;
    ['icon-text']: MarkdownRemark;
    text: MarkdownRemark;
    link: MarkdownRemark;
  }[];
}

export const GridContentfulPropertiesTransformer = ({
  items = [],
  rowitems = 4,
}: GridContentfulProperties) => {
  const transformedGriditems: GridItem[] = items.map(x => ({
    icon: x.icon,
    text: x.text,
    iconText: x['icon-text'],
    link: getMarkdownText(x.link),
  }));

  return {
    items: transformedGriditems,
    row: rowitems,
  };
};
