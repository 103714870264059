import { MarkdownRemarkElement } from '../../../interfaces';
import { TransformerConfig } from '../apply-transformers';

export const LazyLoadImageTransformer = (
  element: MarkdownRemarkElement,
  config: TransformerConfig,
): MarkdownRemarkElement => {
  if (element.tagName === 'img' && config.lazyLoadImages) {
    element.properties.loading = 'lazy';
    return element;
  }

  return element;
};
