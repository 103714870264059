/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import throttle from 'lodash.throttle';
import * as React from 'react';
import { translate } from '../../translations';
import { localizeLink } from '../../utils';
import { LocaleContext } from '../contexts';
import { LocationalDisplay } from '../dynamic-components';
import { HeaderLink } from './header-link';
import './header.scss';
import { LanguageSelector } from './language-selector';
import { LoginButton } from './login-button';
import { SearchBar } from './search-bar';
import { Translate } from './translate';
import { User } from './user';

const COMPACT_HEADER_THRESHOLD = 48;
const languages = require('../../data/languages');

interface HeaderProperties {
  darkTheme?: boolean;
  secondaryMenu?: JSX.Element;
  zoomInProgress?: boolean;
  englishCanonicalUrl: string;
  isGated?: boolean;
}

interface HeaderState {
  compactHeader: boolean;
  darkTheme: boolean;
  searchBarActive: boolean;
}

export class Header extends React.Component<HeaderProperties, HeaderState> {
  constructor(props) {
    super(props);

    this.state = {
      compactHeader: false,
      darkTheme: true,
      searchBarActive: false,
    };

    this.onScroll = throttle(this.onScroll.bind(this), 100);
  }

  handleSearchBarActiveChange = (value: boolean): void => {
    this.setState({ searchBarActive: value });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const compactHeader = window.scrollY > COMPACT_HEADER_THRESHOLD;
    const darkTheme = !compactHeader;
    this.setState({ compactHeader, darkTheme });
  }
  // LOGO
  render() {
    const whiteLogo = require('../../assets/Pix4D_logo_white_new.svg');
    const colorLogo = require('../../assets/Pix4D_logo_new.svg');

    return (
      <HeaderTemplate
        compactHeader={this.state.compactHeader}
        darkTheme={this.darkTheme}
        englishCanonicalUrl={this.props.englishCanonicalUrl}
        inactive={false}
        logo={this.darkTheme ? whiteLogo : colorLogo}
        secondaryMenu={this.props.secondaryMenu}
        zoomInProgress={this.props.zoomInProgress}
        handleSearchBarActiveChange={this.handleSearchBarActiveChange}
        searchBarActive={this.state.searchBarActive}
        isGated={this.props.isGated}
      />
    );
  }

  get darkTheme() {
    return this.props.darkTheme !== undefined ? this.props.darkTheme : this.state.darkTheme;
  }
}

const HeaderTemplate = ({
  logo,
  darkTheme,
  inactive,
  compactHeader,
  secondaryMenu,
  zoomInProgress,
  englishCanonicalUrl,
  handleSearchBarActiveChange,
  searchBarActive,
  isGated,
}) => (
  <header
    className={`
      site-header
      ${darkTheme ? 'theme-dark' : 'theme-white'}
      ${inactive ? 'header--inactive' : ''}
      ${zoomInProgress ? 'header--zoom-in-progress' : ''}
    `}
  >
    <div className="site-header-content-wrapper">
      <div className={`site-header-content ${compactHeader ? 'compact' : ''}`}>
        <input type="checkbox" id="mobile-menu" className="mobile-menu" />
        <LocaleContext.Consumer>
          {locale => (
            <Link className="logo-link" to={localizeLink(locale, '')}>
              <img src={logo} alt="Pix4D" className="logo" />
            </Link>
          )}
        </LocaleContext.Consumer>

        <div className="navigation-container">
          <div className="navigation-container-header mobile-only desktop-hide">
            <span className="try-now">
              <HeaderLink to="/software-guide">
                <button className="btn btn--primary btn--small btn--capitalize">
                  <Translate id="header.tryForFree" />
                </button>
              </HeaderLink>
              <LoginButton enableLogoutBtn={true} isGated={isGated} />
            </span>
            <div className="mobile-menu-controller">
              <label htmlFor="mobile-menu">
                <i className="icon-hamburger" />
                <i className="icon-close" />
              </label>
            </div>
          </div>
          <div className="header-search-bar desktop-hide">
            <SearchBar changeOpenState={handleSearchBarActiveChange} active={true} />
          </div>
          <nav className="primary-navigation">
            <li className="user desktop-hide">
              <User isGated={isGated} />
            </li>
            <li>
              <input type="checkbox" className="mobile-submenu" id="mobile-submenu-products" />
              <div className="product-section">
                <LocaleContext.Consumer>
                  {locale => (
                    <HeaderLink
                      to={localizeLink(
                        locale,
                        '/#' + translate(locale, 'header.products').toLowerCase(),
                      )}
                    >
                      <Translate id="header.products" />
                    </HeaderLink>
                  )}
                </LocaleContext.Consumer>
                <label htmlFor="mobile-submenu-products">
                  <i className="icon-arrow desktop-hide" />
                </label>
              </div>
              <div className="popup-navigation">
                <div>
                  <div className="popup-navigation__title">
                    <Translate id="header.products.software" />
                  </div>
                  <ul>
                    <li>
                      <HeaderLink to="/product/pix4dmapper-photogrammetry-software">
                        <Translate id="products.mapper" />
                      </HeaderLink>
                    </li>
                    <li>
                      <HeaderLink to="/product/pix4dengine">
                        <Translate id="products.engine" />
                      </HeaderLink>
                    </li>
                    <li>
                      <HeaderLink to="/product/pix4dfields">
                        <Translate id="products.fields" />
                      </HeaderLink>
                    </li>
                    <li>
                      <HeaderLink to="/product/pix4dmatic-large-scale-photogrammetry-software">
                        <Translate id="products.matic" />
                      </HeaderLink>
                    </li>
                    <li>
                      <HeaderLink to="/product/pix4dreact">
                        <Translate id="products.react" />
                      </HeaderLink>
                    </li>
                  </ul>

                  <div className="title-space">
                    <div className="popup-navigation__title">
                      <Translate id="header.industries.cloud" />
                    </div>
                    <ul>
                      <li>
                        <HeaderLink to="/product/pix4dcloud">
                          <Translate id="products.cloud" />
                        </HeaderLink>
                      </li>
                    </ul>
                  </div>

                  <div className="title-space">
                    <div className="popup-navigation__title">
                      <Translate id="header.industries.surveying" />
                    </div>
                    <ul>
                      <li>
                        <HeaderLink to="/product/pix4dsurvey">
                          <Translate id="products.survey" />
                        </HeaderLink>
                      </li>
                    </ul>
                  </div>
                  <div className="title-space">
                    <div className="popup-navigation__title">
                      <Translate id="header.industries.inspectionandTelecom" />
                    </div>
                    <ul>
                      <li>
                        <HeaderLink to="/product/pix4dinspect-inspection-asset-management-software">
                          <Translate id="products.inspect" />
                        </HeaderLink>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mobile-title">
                  <div className="popup-navigation__title">
                    <Translate id="header.products.capture" />
                  </div>
                  <ul>
                    <li>
                      <HeaderLink to="/product/pix4dcapture">
                        <Translate id="products.capture" />
                      </HeaderLink>
                    </li>
                    <li>
                      <HeaderLink to="/product/pix4dcatch">
                        <Translate id="products.catch" />
                      </HeaderLink>
                    </li>
                    <li>
                      <HeaderLink to="/product/pix4dscan-drone-flight-inspection-software">
                        <Translate id="products.scan" />
                      </HeaderLink>
                    </li>
                  </ul>
                  <div>
                    <div className="popup-navigation__title-header">
                      <Translate id="header.products.hardware" />
                    </div>
                    <ul>
                      <li>
                        <HeaderLink to="/product/crane-camera">
                          <Translate id="products.craneCamera" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/product/sequoia">
                          <Translate id="products.parrotSequoia" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/product/vidoc-rtk-rover">
                          <Translate id="products.viDocRtkRover" />{' '}
                          <span className="new-feat">NEW</span>
                        </HeaderLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <input type="checkbox" className="mobile-submenu" id="mobile-submenu-industries" />
              <a href="#">
                <label htmlFor="mobile-submenu-industries">
                  <Translate id="header.industries" />
                  <i className="icon-arrow desktop-hide" />
                </label>
              </a>
              <div className="popup-navigation">
                <div>
                  <div className="popup-navigation__title">
                    <Translate id="header.industries" />
                  </div>
                  <div className="popup-navigation__two-column">
                    <ul>
                      <li>
                        <HeaderLink to="/industry/surveying-mapping">
                          <Translate id="header.industries.surveyAndMapping" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/industry/agriculture">
                          <Translate id="header.industries.agriculture" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/industry/construction">
                          <Translate id="header.industries.construction" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/education">
                          <Translate id="header.industries.education" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/industry/inspection">
                          <Translate id="header.industries.inspection" />
                        </HeaderLink>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <HeaderLink to="/industry/telecommunication">
                          <Translate id="header.industries.telecom" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/industry/mining">
                          <Translate id="header.industries.miningAndAggregates" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/industry/oem-integrators">
                          <Translate id="header.industries.oem" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/industry/public-safety">
                          <Translate id="header.industries.publicSafety" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink to="/industry/roofing">
                          <Translate id="header.industries.roofing" />
                        </HeaderLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <LocationalDisplay allowedCountries="CN">
                <a href="https://pix4d.com.cn/">
                  <Translate id="header.pricing" />
                </a>
                <HeaderLink to="/pricing">
                  <Translate id="header.pricing" />
                </HeaderLink>
              </LocationalDisplay>
            </li>
            <li>
              <input type="checkbox" className="mobile-submenu" id="mobile-submenu-services" />
              <a href="#">
                <label htmlFor="mobile-submenu-services">
                  <Translate id="header.supportAndTraining" />
                  <i className="icon-arrow desktop-hide" />
                </label>
              </a>
              <div className="popup-navigation">
                <div>
                  <div className="popup-navigation__title">
                    <Translate id="header.supportAndTraining.technicalSupport" />
                  </div>
                  <ul>
                    <li>
                      <HeaderLink to="/support">
                        <Translate id="header.supportAndTraining.support-offer" />
                      </HeaderLink>
                    </li>
                    <li>
                      <a href="https://support.pix4d.com" target="_blank" rel="noopener noreferrer">
                        <Translate id="header.supportAndTraining.documentation" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://community.pix4d.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Translate id="header.supportAndTraining.community" />
                      </a>
                    </li>
                  </ul>
                  <div className="title-space">
                    <div className="popup-navigation__title">
                      <Translate id="header.supportAndTraining.training" />
                    </div>
                    <ul>
                      <li>
                        <a
                          href="https://training.pix4d.com/pages/workshops "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="header.supportAndTraining.workshop" />
                        </a>
                      </li>
                      <li>
                        <HeaderLink
                          to="https://training.pix4d.com/pages/online-courses"
                          allowedLanguages={['en-US', 'de', 'fr', 'pt', 'ru']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="header.supportAndTraining.onlineCourse" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink
                          to="/services/training-certification/online-course"
                          allowedLanguages={['jp', 'es']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="header.supportAndTraining.onlineCourse" />
                        </HeaderLink>
                      </li>
                      <li>
                        <a
                          href="https://training.pix4d.com/courses/personal-consultation"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="header.supportAndTraining.consultation" />
                        </a>
                      </li>
                      <li>
                        <HeaderLink
                          to="/services/training-certification/pix4d-user-certification"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="header.supportAndTraining.certification" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink
                          to="/webinars-and-ebooks"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="footer.resources.webinar" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink
                          to="https://support.pix4d.com/hc/en-us/articles/360032118251-Video-tutorials"
                          allowedLanguages={['en-US', 'de', 'es', 'fr', 'pt', 'es', 'ru']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="header.supportAndTraining.videotutorials" />
                        </HeaderLink>
                      </li>
                      <li>
                        <HeaderLink
                          to="https://support.pix4d.com/hc/ja/articles/360032118251"
                          allowedLanguages={['jp']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="header.supportAndTraining.videotutorials" />
                        </HeaderLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <HeaderLink to="/blog">
                <Translate id="header.blog" />
              </HeaderLink>
            </li>
            <li>
              <HeaderLink to="/user-conference" allowedLanguages={['en-US', 'de']} target="_blank">
                <Translate id="header.network.conference" />
              </HeaderLink>
              <HeaderLink to="/user-conference-tokyo" allowedLanguages={['jp']} target="_blank">
                <Translate id="header.network.conference" />
              </HeaderLink>
            </li>
          </nav>

          <nav className="primary-navigation desktop-hide">
            <li>
              <HeaderLink to="/careers">
                <Translate id="footer.company.careers" />
              </HeaderLink>
            </li>
            <li>
              <HeaderLink to="/contact-us">
                <Translate id="header.contact" />
              </HeaderLink>
            </li>
            {languages.displayLanguageSelector && (
              <li className="language-selector">
                <LanguageSelector
                  englishCanonicalUrl={englishCanonicalUrl}
                  languages={languages.enabledLanguages}
                />
              </li>
            )}
          </nav>

          <div className="navigation-left-part tablet-hide mobile-hide">
            <nav className="sub-navigation">
              <li className="user">
                <User isGated={isGated} />
              </li>
              {languages.displayLanguageSelector && (
                <li className="language-selector">
                  <LanguageSelector
                    languages={languages.enabledLanguages}
                    englishCanonicalUrl={englishCanonicalUrl}
                  />
                </li>
              )}
            </nav>
            <nav className="sub-navigation">
              <li>
                <SearchBar changeOpenState={handleSearchBarActiveChange} active={false} />
              </li>
              {!searchBarActive && (
                <>
                  <li>
                    <HeaderLink to="/careers">
                      <Translate id="footer.company.careers" />
                    </HeaderLink>
                  </li>
                  <li>
                    <HeaderLink to="/contact-us">
                      <Translate id="header.contact" />
                    </HeaderLink>
                  </li>
                  <li>
                    <HeaderLink to="/software-guide">
                      <button className="btn btn--primary btn--small btn--capitalize">
                        <Translate id="header.tryForFree" />
                      </button>
                    </HeaderLink>
                  </li>
                </>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
    {secondaryMenu}
  </header>
);
