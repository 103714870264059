import * as React from 'react';

import './block.scss';

interface BlockProperies {
  children: JSX.Element | JSX.Element[];
  backgroundColor?: boolean;
  small?: boolean;
  wide?: boolean;
  extraClasses?: string[];
}

export const Block = ({
  children,
  backgroundColor,
  small,
  wide,
  extraClasses = [],
}: BlockProperies) => {
  const classes = [
    'block',
    backgroundColor ? 'block--with-background' : null,
    small ? 'block--small' : null,
    wide ? 'block--wide' : null,
  ];

  return (
    <div className={classes.filter(x => x).join(' ')}>
      <div className={`block__content ${extraClasses.join(' ')}`}>{children}</div>
    </div>
  );
};
