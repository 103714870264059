import { assoc, complement } from 'ramda';
import { MarkdownRemarkElement } from '../../../interfaces';
import { isEmptyNewLineNode } from '../is-empty-newline-node';

export const EmptyNewlinesTransformer = (element: MarkdownRemarkElement): MarkdownRemarkElement => {
  if (element.children === undefined) {
    return element;
  }

  const newChildren = element.children.filter(complement(isEmptyNewLineNode));
  return assoc('children', newChildren, element);
};
