import { ComponentMap, registerComponents } from './components-map';
import * as dynamicComponents from './dynamic-components';
import { BlockWide } from './layout/block-wide';
import { LinkLocalizer } from './layout/link-localizer';

export * from './dynamic-components';
export * from './layout';

const components: ComponentMap = {
  'c-linklocalizer': {
    component: LinkLocalizer,
    noComponentWrap: true,
  },
  'c-images': {
    component: dynamicComponents.MultiColumn,
    contentfulPropertyTransformer: dynamicComponents.ImagesContentfulPropertiesTransformer,
  },
  'c-multicolumn': {
    component: dynamicComponents.MultiColumn,
    contentfulPropertyTransformer: dynamicComponents.MultiColumnContentfulPropertiesTransformer,
  },
  'c-blockwide': { component: BlockWide },
  'c-cta': {
    component: dynamicComponents.CallToAction,
    contentfulPropertyTransformer: dynamicComponents.CallToActionContentfulPropertiesTransformer,
  },
  'c-countdown': { component: dynamicComponents.Countdown },
  'c-workflow': {
    component: dynamicComponents.Workflow,
    contentfulPropertyTransformer: dynamicComponents.WorkflowContentfulPropertiesTransformer,
  },
  'c-columnfeatures': {
    animatedComponent: dynamicComponents.ColumnFeaturesAnimatedResponsive,
    component: dynamicComponents.ColumnFeaturesResponsive,
    contentfulPropertyTransformer: dynamicComponents.ColumnFeaturesContentfulPropertiesTransformer,
  },
  'c-comparisonslider': {
    component: dynamicComponents.ComparisonSlider,
    contentfulPropertyTransformer: dynamicComponents.ComparisonSliderPropertiesTransformer,
  },
  'c-tiles': {
    animatedComponent: dynamicComponents.TilesAnimated,
    component: dynamicComponents.Tiles,
    contentfulPropertyTransformer: dynamicComponents.TilesContentfulPropertiesTransformer,
  },
  'c-imagetiles': {
    animatedComponent: dynamicComponents.ImageTilesAnimated,
    component: dynamicComponents.ImageTiles,
    contentfulPropertyTransformer: dynamicComponents.ImageTilesContentfulPropertiesTransformer,
  },
  'c-featureswide': {
    component: dynamicComponents.FeaturesWide,
    contentfulPropertyTransformer: dynamicComponents.FeaturesWideContentfulPropertiesTransformer,
  },
  'c-slider': {
    animatedComponent: dynamicComponents.SliderAnimated,
    component: dynamicComponents.Slider,
    contentfulPropertyTransformer: dynamicComponents.SliderContentfulPropertyTransformer,
  },
  'c-icongrid': {
    animatedComponent: dynamicComponents.GridAnimated,
    component: dynamicComponents.Grid,
    contentfulPropertyTransformer: dynamicComponents.GridContentfulPropertiesTransformer,
  },
  'c-verticalworkflow': {
    component: dynamicComponents.VerticalWorkflow,
    contentfulPropertyTransformer:
      dynamicComponents.VerticalWorkflowContentfulPropertiesTransformer,
  },
  'c-iframe': { component: dynamicComponents.Iframe },
  'c-partnerlogos': {
    animatedComponent: dynamicComponents.PartnerLogosAnimated,
    component: dynamicComponents.PartnerLogos,
  },
  'c-downloadblock': {
    contentfulPropertyTransformer: dynamicComponents.DownloadBlockContentfulPropertiesTransformer,
    component: dynamicComponents.DownloadBlock,
    noComponentWrap: true,
  },
  'c-icon': {
    component: dynamicComponents.Icon,
    noComponentWrap: true,
  },
  'c-zoomable': {
    component: dynamicComponents.Zoomable,
  },
  'c-buynow': {
    component: dynamicComponents.BuyNow,
    contentfulPropertyTransformer: dynamicComponents.BuyNowContentfulPropertiesTransformer,
  },
  'c-price': {
    component: dynamicComponents.Price,
    contentfulPropertyTransformer: dynamicComponents.PriceContentfulPropertiesTransformer,
  },
  'c-pricetoggle': {
    component: dynamicComponents.PriceToggle,
  },
  'c-userwelcome': {
    component: dynamicComponents.UserWelcome,
  },
  'c-softwareversionbanner': {
    component: dynamicComponents.SoftwareVersionBanner,
    contentfulPropertyTransformer: dynamicComponents.SoftwareVersionBannerPropertiesTransformer,
  },
  'c-tabs': {
    component: dynamicComponents.Tabs,
    contentfulPropertyTransformer: dynamicComponents.TabsContentfulPropertiesTransformer,
  },
  'c-productcard': {
    component: dynamicComponents.ProductCard,
  },
  'c-doublelink': {
    component: dynamicComponents.DoubleLink,
    contentfulPropertyTransformer: dynamicComponents.DoubleLinkPropertiesTransformer,
  },
  'c-locationaldisplay': {
    component: dynamicComponents.LocationalDisplay,
    contentfulPropertyTransformer:
      dynamicComponents.LocationalDisplayContentfulPropertiesTransformer,
  },
  'c-workable': {
    component: dynamicComponents.Workable,
  },
};

registerComponents(components);
