import { identity, pipe } from 'ramda';
import omit from 'ramda/es/omit';
import * as React from 'react';
import { CustomComponentErrorHandler, CustomComponentWrap } from '../../components';
import { CustomComponentMap } from '../../components/components-map';
import { MarkdownRemarkElement } from '../../interfaces';
import { alterClassNameProperty } from './alter-classname-property';
import { getCustomComponentByName } from './get-component-by-name';
import { MarkdownRemarkConfig } from './render-markdown-remark';

const createCustomComponentFromElement = (
  component: CustomComponentMap<any, any>,
  config: MarkdownRemarkConfig,
  renderRemarkChild: any,
) => (element: MarkdownRemarkElement) => {
  const children = element.children.map(
    renderRemarkChild(omit(['animation', 'customComponentAnimation'], config)),
  );

  const elementProperties = pipe(
    component.contentfulPropertyTransformer || identity,
    alterClassNameProperty,
  )({ ...element.properties, children });

  const componentFunction =
    config.customComponentAnimation && component.animatedComponent
      ? component.animatedComponent
      : component.component;

  const customComponent = React.createElement(componentFunction, elementProperties, children);

  if (component.noComponentWrap) {
    return customComponent;
  }

  return React.createElement(
    CustomComponentWrap as any,
    { className: [], ...element.properties },
    customComponent,
  );
};

const wrapWithErrorHandler = (element: React.ReactElement) => {
  return React.createElement(CustomComponentErrorHandler, {}, element);
};

export const renderCustomComponent = (
  element: MarkdownRemarkElement,
  config: MarkdownRemarkConfig,
  renderRemarkChild: any,
) => {
  const component = getCustomComponentByName(element.tagName);

  if (!component) {
    return null;
  }

  return pipe(
    createCustomComponentFromElement(component, config, renderRemarkChild),
    wrapWithErrorHandler,
  )(element);
};
