import * as React from 'react';
import { Link } from 'gatsby';
import { Highlight, connectStats } from 'react-instantsearch-dom';
import { Hit } from 'react-instantsearch-core';
import { Translate } from '../components';
import { TRANSLATION_KEYS } from '../translations';

import './search-algolia-components.scss';
import './search-page.scss';

const Anchor = ({ hit }) => {
  if (hit.type === 'support') {
    return (
      <a target="_blank" href={`${hit.slug}`}>
        <Highlight hit={hit} attribute="title" tagName="mark" />
      </a>
    );
  } else {
    return (
      <Link
        to={`${hit.fields.locale === 'en-US' ? '' : '/' + hit.fields.locale}${
          hit.type === 'blog' ? '/blog/' : '/'
        }${hit.slug}`}
      >
        <Highlight hit={hit} attribute="title" tagName="mark" />
      </Link>
    );
  }
};

export const HitPreview = ({ hit }: { hit: Hit }) => (
  <div className="found-items">
    <p>
      <span className="index-type">
        <Translate id={`page.search-page.indexType.${hit.type}` as TRANSLATION_KEYS} />
      </span>
      <span className="separator">|</span>
      <Anchor hit={hit} />
    </p>
    <hr />
  </div>
);

const Stats = ({ nbHits }: { nbHits: number }) => {
  const hasResults = nbHits !== 0;
  return (
    <div className="search-state">
      {hasResults && (
        <div className="result-count">
          {nbHits + ' '} <Translate id="page.search-page.results" />
        </div>
      )}
      {!hasResults && (
        <div className="not-found-search-result">
          <p className="result-0">
            0 <Translate id="page.search-page.results" />
          </p>
          <div className="background-image">
            <img
              src={require('../../static/assets/search_result_not_found.png')}
              alt="Not found any search result"
            />
          </div>
          <p>
            <Translate id="page.search-page.noResultsFound" />
          </p>
        </div>
      )}
    </div>
  );
};

export const CustomStats = connectStats(Stats);
