import * as React from 'react';
import { OS, OS_ICONS } from '../../../../interfaces';
import { IconDisplay } from '../icon-display/icon-display';
import './download-button.scss';

interface DownloadButtonProperties {
  isMapper?: boolean;
  isOSSupported?: boolean;
  redirectLink?: string;
  downloadLink: string;
  os: OS;
}

export const DownloadButton = ({
  isMapper,
  isOSSupported,
  downloadLink,
  os,
  redirectLink,
}: DownloadButtonProperties) => {
  const icon = OS_ICONS[os];
  const className =
    isMapper && !isOSSupported
      ? 'btn btn--primary btn--disabled disabled oneline'
      : 'btn btn--primary oneline';

  return (
    <div className="download-button">
      <IconDisplay
        className={className}
        downloadLink={downloadLink}
        redirectLink={redirectLink}
        icon={icon}
        title="component.downloadButton.download"
        color="white"
      />
    </div>
  );
};
