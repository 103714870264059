import throttle from 'lodash.throttle';
import * as React from 'react';
import './secondary-menu.scss';

const COMPACT_HEADER_THRESHOLD = 48;
interface SecondaryMenuProperties {
  label: string;
  labelExtend?: string;
  hideOnDesktop?: boolean;
}

interface SecondaryMenuState {
  compactHeader: boolean;
}

export class SecondaryMenu extends React.Component<SecondaryMenuProperties, SecondaryMenuState> {
  state = {
    compactHeader: false,
  };

  secondaryMenuWrapRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  get IsInCompactHeaderTreahshold() {
    return window.scrollY > COMPACT_HEADER_THRESHOLD;
  }

  get SecondaryMenuHeight() {
    if (!this.secondaryMenuWrapRef.current) {
      return NaN;
    }

    return this.secondaryMenuWrapRef.current.getBoundingClientRect().height;
  }

  onScroll = throttle(() => {
    this.setState(state => {
      if (state.compactHeader !== this.IsInCompactHeaderTreahshold) {
        return { compactHeader: this.IsInCompactHeaderTreahshold };
      }

      return null;
    });
  }, 100);

  render() {
    return (
      <SecondaryMenuDisplay
        compactHeader={this.state.compactHeader}
        children={this.props.children}
        label={this.props.label}
        labelExtend={this.props.labelExtend}
        hideOnDesktop={this.props.hideOnDesktop}
        secondaryMenuWrapRef={this.secondaryMenuWrapRef}
        // @ts-ignore FIXME: contentTopPadding dosen't exist
        contentTopPadding={this.state.contentTopPadding}
      />
    );
  }
}

const SecondaryMenuDisplay = ({
  compactHeader,
  children,
  label,
  hideOnDesktop,
  labelExtend,
  secondaryMenuWrapRef,
  contentTopPadding,
}) => (
  <div
    className={`secondary-menu ${compactHeader ? 'secondary-menu--compact' : ''} ${
      hideOnDesktop ? 'secondary-menu--hide-on-desktop' : ''
    }`}
  >
    <input type="checkbox" id="secondarymenu-input" className="secondary-menu-content-input" />
    <div className="secondary-menu-label-wrap" ref={secondaryMenuWrapRef}>
      <div className="secondary-menu-label">
        <label htmlFor="secondarymenu-input">
          <i className="icon-close secondary-menu-content-closer" />
          <i className="secondary-menu-content-opener">
            <img src={require('../../assets/secondary-menu.svg')} alt="Hamburger menu icon" />
          </i>
          <b>{label}</b> {labelExtend && `| ${labelExtend}`}
        </label>
      </div>
    </div>
    <div className="secondary-menu-content" style={{ paddingTop: contentTopPadding }}>
      {children}
    </div>
  </div>
);
