import * as React from 'react';
import { ContentfulBlogPost } from '../../../interfaces';
import { fixContentfulAssetPath, localizeLink } from '../../../utils';
import { ResponsiveTemplate } from '../../layout';
import { ArticleCard } from '../article-card';
import { MultiColumn } from '../multi-column';
import { Slider } from '../slider';

import './related-articles.scss';

interface RelatedArticlesProperties {
  blogposts: ContentfulBlogPost[];
}

const ArticleCards = ({ items }: { items: ContentfulBlogPost[] }) =>
  items.map((blogpost, index) => (
    <ArticleCard
      key={`blogpost-${index}`}
      excerpt={blogpost.excerpt}
      imageAlt={blogpost.heroImage && blogpost.heroImage.title}
      image={blogpost.heroImage && fixContentfulAssetPath(blogpost.heroImage.file.url)}
      title={blogpost.title}
      link={localizeLink(blogpost.fields.locale, `/blog/${blogpost.slug}`)}
    />
  ));

export const RelatedArticles = ({ blogposts }: RelatedArticlesProperties) => (
  <div className="component-relatedarticles">
    <ResponsiveTemplate>
      <ResponsiveTemplate.Desktop>
        <MultiColumn gap="15px">{ArticleCards({ items: blogposts })}</MultiColumn>
      </ResponsiveTemplate.Desktop>
      <ResponsiveTemplate.Mobile>
        <Slider itemsPerSlide={1} items={blogposts} itemTemplate={ArticleCards} rotateSlides />
      </ResponsiveTemplate.Mobile>
    </ResponsiveTemplate>
  </div>
);
