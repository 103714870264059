import { graphql } from 'gatsby';
import * as React from 'react';
import { RelatedArticles } from '../../components';
import { ContentfulBlogPost } from '../../interfaces';
import { PageFragmentWrap } from './page-fragment-wrap';

interface RecentBlogpostsProperties {
  location: any;
  data: {
    blogposts: {
      edges: {
        node: ContentfulBlogPost;
      }[];
    };
    site;
  };
}

const RecentBlogposts = ({ data: { blogposts } }: RecentBlogpostsProperties) => (
  <PageFragmentWrap>
    <RelatedArticles blogposts={blogposts.edges.map(x => x.node)} />
  </PageFragmentWrap>
);

export default RecentBlogposts;

export const recentBlogpostQuery = graphql`
  query recentBlogPostQuery {
    blogposts: allContentfulBlogPost(
      filter: { slug: { ne: null }, fields: { locale: { eq: "en-US" } } }
      sort: { fields: time, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...blogpostBaseFields
        }
      }
    }
  }
`;
