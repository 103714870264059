import * as React from 'react';
import './workable.scss';
import { Translate } from '../..';
import { useStaticQuery, graphql } from 'gatsby';

export const Workable = () => {
  const data = useStaticQuery(graphql`
    query Query {
      allWorkableJobs {
        nodes {
          application_url
          department
          created_at
          country
          city
          id
          title
        }
      }
    }
  `);

  return (
    <div className={'workable-container'}>
      <ul>
        {data.allWorkableJobs.nodes.map(job => {
          return (
            <li>
              <a className="job-title" href={job.application_url}>
                {job.title}
              </a>
              <div className="job-info job-department">
                <Translate id="component.workable.department" /> : {job.department}
              </div>
              <div className="job-info job-location">
                <Translate id="component.workable.location" /> : {job.city}, {job.country}
              </div>
              <div className="job-info job-date">
                <Translate id="component.workable.createdAt" /> :{' '}
                {new Date(job.created_at).toLocaleDateString()}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
