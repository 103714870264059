import { ContentfulMarkdownRemark, MarkdownRemarkElement, MarkdownRemarkText } from '../interfaces';
import { getHeaders } from './get-headers';
import { makeTextUrlFriendly } from './make-text-url-friendly';

const getHeaderContent = (header: MarkdownRemarkElement): string =>
  (header.children[0] as MarkdownRemarkText).value;
const createAnchor = (text: string): MarkdownRemarkElement => ({
  type: 'element',
  tagName: 'a',
  properties: { id: makeTextUrlFriendly(text) },
  children: [],
});

export const alterHeadersWithAnchors = (content: ContentfulMarkdownRemark) => {
  const contentCopy = JSON.parse(JSON.stringify(content));

  const headers = getHeaders(contentCopy);
  headers.forEach(header => {
    const anchor = createAnchor(getHeaderContent(header)) as any;
    header.children.unshift(anchor);
  });

  return contentCopy;
};
