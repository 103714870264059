import { Locale } from '../translations';

export const formatDate = (date: Date, locale: Locale) => {
  if (typeof Intl !== 'undefined') {
    return new Intl.DateTimeFormat(locale === 'jp' ? 'ja' : locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date);
  }

  // Legacy way, for browser that don't support Intl.DateTimeFormat
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]} ${year}`;
};
