import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { MarkdownRemarkRenderer, scrollToElement } from '../../../utils';
import { ResponsiveTemplate } from '../../layout';
import { SliderDisplay } from '../slider/slider-display';

import './workflow-display.scss';

export interface WorkflowItem {
  icon: MarkdownRemark;
  title: MarkdownRemark;
  image: MarkdownRemark;
  text: MarkdownRemark;
}

export interface WorkflowDisplayProperties {
  items: WorkflowItem[];
  activeIndex: number;
  onSelect: (number: number) => void;
}

export const WorkflowDisplay = ({
  activeIndex = 0,
  items,
  onSelect,
}: WorkflowDisplayProperties) => {
  const slides = items.map((item, index) => (
    <WorkflowItem key={`workflow-item-${index}`} isActive={index === activeIndex} item={item} />
  ));

  return (
    <div className="component-workflow">
      <header className="component-workflow-header">
        <div className="container container--medium">
          <ul className="workflow-control">
            {items.map((item, index) => (
              <>
                <li
                  key={index}
                  className={`workflow-frame ${index === activeIndex ? 'active' : ''}`}
                  onClick={e => {
                    onSelect(index);
                    scrollToElement(
                      (e.target as HTMLElement).closest('.frame')! as HTMLElement,
                      true,
                    );
                  }}
                >
                  <MarkdownRemarkRenderer markdown={item.icon} />
                  <div className="workflow-title">
                    <MarkdownRemarkRenderer markdown={item.title} />
                  </div>
                </li>
                <li className="right-arrow">
                  <i className="icon-arrow" />
                </li>
              </>
            ))}
          </ul>
        </div>
      </header>
      <ResponsiveTemplate>
        <ResponsiveTemplate.Desktop>
          <SliderDisplay
            pagerElements={0}
            currentIndex={activeIndex}
            isActive
            setIndex={onSelect}
            slides={slides}
          />
        </ResponsiveTemplate.Desktop>
        <ResponsiveTemplate.Tablet>{slides}</ResponsiveTemplate.Tablet>
      </ResponsiveTemplate>
    </div>
  );
};

const WorkflowItem = ({ item, isActive }) => (
  <article className={`component-workflow-main ${isActive ? 'active' : ''}`}>
    <div className="workflow-content">
      <div className="workflow-content-image">
        <MarkdownRemarkRenderer markdown={item.image} />
      </div>
      <div className="workflow-content-description">
        <div className="workflow-content-title">
          <MarkdownRemarkRenderer markdown={item.title} />
        </div>
        <div className="workflow-content-text">
          <MarkdownRemarkRenderer markdown={item.text} />
        </div>
      </div>
    </div>
  </article>
);
