import * as React from 'react';
import { calculateSrcset } from '../../../utils';
import { Translate } from '../../layout';
import { LinkLocalizer } from '../../layout/link-localizer';
import { AspectRatioBox, Ratio } from '../aspect-ratio-box';

import './article-card.scss';

interface ArticleCardProperties {
  image: string;
  imageAlt: string;
  title: string;
  subtitle?: string;
  excerpt?: string;
  link: string;
}

export const ArticleCard = React.forwardRef(
  ({ image, link, title, subtitle, excerpt, imageAlt }: ArticleCardProperties, ref) => (
    <div className="article-card" ref={ref as any}>
      <AspectRatioBox desktopRatio={Ratio.Wide}>
        <Image image={image} link={link} alt={imageAlt} />
      </AspectRatioBox>
      <Content link={link} title={title} subtitle={subtitle} excerpt={excerpt} />
    </div>
  ),
);

const Image = ({ image, link, alt }) => (
  <div className="article-card__image">
    <LinkLocalizer to={link}>
      <img alt={alt} src={image} srcSet={calculateSrcset(image, 400)} loading="lazy" />
    </LinkLocalizer>
  </div>
);

const Content = ({ link, title, subtitle, excerpt }) => (
  <div className="article-card__content">
    <div className="article-card__title">{title}</div>
    {subtitle && <div className="article-card__subtitle">{subtitle}</div>}
    {excerpt && <div className="article-card__desc">{excerpt}</div>}
    <div className="article-card__readmore">
      <LinkLocalizer to={link}>
        <Translate id="component.readMore" />
      </LinkLocalizer>
    </div>
  </div>
);
