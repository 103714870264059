import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { MarkdownRemarkRenderer } from '../../../utils';
import { Animation, ResponsiveTemplate } from '../../layout';
import { LinkLocalizer } from '../../layout/link-localizer';
import { AspectRatioBox } from '../aspect-ratio-box';
import { ImageDescOverlay } from '../image-desc-overlay';

import './features-wide-display.scss';

export interface FeatureWideItem {
  image: MarkdownRemark;
  desc: MarkdownRemark;
  icon: MarkdownRemark;
  title: MarkdownRemark;
}

export interface FeaturesWideDisplayProperties {
  title: MarkdownRemark;
  linkMore: MarkdownRemark;
  linkMoreUrl?: string;
  items: FeatureWideItem[];
  activeIndex: number;
  previousIndex?: number;
  onSelect: (selectedItem: number) => void;
}

export const FeaturesWideDisplay = ({
  title,
  items,
  activeIndex,
  linkMore,
  linkMoreUrl,
  onSelect,
}: FeaturesWideDisplayProperties) => (
  <div className="component-featureswide">
    <div className="left-side">
      <div className="title">
        <MarkdownRemarkRenderer markdown={title} />
      </div>
      <Animation animation="fade-in" key={Math.random()}>
        <AspectRatioBox>
          <ImageDescOverlay
            image={items[activeIndex].image}
            title={items[activeIndex].title}
            subtitle={items[activeIndex].desc}
            darkTheme
          />
        </AspectRatioBox>
      </Animation>
    </div>
    <ResponsiveTemplate>
      <ResponsiveTemplate.Tablet>
        <ul className="tablet-controllers">
          {items.map((item, index) => (
            <li
              key={`item-${index}`}
              className={activeIndex === index ? 'active' : ''}
              onClick={() => onSelect(index)}
            >
              <MarkdownRemarkRenderer markdown={item.icon} />
            </li>
          ))}
        </ul>
      </ResponsiveTemplate.Tablet>
      <ResponsiveTemplate.Desktop>
        <div className="right-side tablet-hide mobile-hide">
          <div className="title">
            <MarkdownRemarkRenderer markdown={title} />
          </div>
          <ul>
            {items.map((item, index) => (
              <li
                key={index}
                className={activeIndex === index ? 'active' : ''}
                onMouseOver={() => onSelect(index)}
              >
                <div className="feature-icon">
                  <MarkdownRemarkRenderer markdown={item.icon} />
                </div>
                <div className="feature-title">
                  <MarkdownRemarkRenderer markdown={item.title} />
                </div>
              </li>
            ))}
          </ul>
          <div className="link-more">
            <LinkLocalizer to={linkMoreUrl}>
              <MarkdownRemarkRenderer markdown={linkMore} />
            </LinkLocalizer>
          </div>
        </div>
      </ResponsiveTemplate.Desktop>
    </ResponsiveTemplate>
  </div>
);
