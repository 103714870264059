export interface CustomComponentMap<ContentfulProperties, ComponentProperties> {
  noComponentWrap?: boolean;
  component: React.ComponentType<ComponentProperties>;
  animatedComponent?: React.ComponentType<ComponentProperties>;
  contentfulPropertyTransformer?: (props: ContentfulProperties) => ComponentProperties;
}

export type ComponentMap = Record<string, CustomComponentMap<any, any>>;

export const componentMap: ComponentMap = {};

export const registerComponents = map => {
  Object.assign(componentMap, map);
};
