import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { FeaturesWideDisplay, FeatureWideItem } from './features-wide-display';

interface FeaturesWideProperties {
  title: MarkdownRemark;
  linkMore: MarkdownRemark;
  linkMoreUrl?: string;
  items: FeatureWideItem[];
}

interface FeaturesWideState {
  activeIndex: number;
}

export class FeaturesWide extends React.PureComponent<FeaturesWideProperties, FeaturesWideState> {
  state = {
    activeIndex: 0,
  };

  onSelect = (newIndex: number) => {
    if (newIndex !== this.state.activeIndex) {
      this.setState(() => ({ activeIndex: newIndex }));
    }
  };

  render() {
    return (
      <FeaturesWideDisplay
        activeIndex={this.state.activeIndex}
        onSelect={this.onSelect}
        items={this.props.items}
        title={this.props.title}
        linkMore={this.props.linkMore}
        linkMoreUrl={this.props.linkMoreUrl}
      />
    );
  }
}
