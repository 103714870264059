import { assoc } from 'ramda';
import { MarkdownRemarkElement } from '../../../interfaces';

const isWideElement = (element: MarkdownRemarkElement) => {
  return element.properties && 'wide' in element.properties;
};

const wrapWithBlockWide = (...element: MarkdownRemarkElement[]): MarkdownRemarkElement => {
  return {
    type: 'element',
    tagName: 'c-block-wide',
    properties: {},
    children: element,
  };
};

export const BlockWideTransformer = (root: MarkdownRemarkElement): MarkdownRemarkElement => {
  const newChildren = root.children.map(x => {
    if (x.type === 'element' && isWideElement(x)) {
      return wrapWithBlockWide(x);
    }

    return x;
  });

  return assoc('children', newChildren, root);
};
