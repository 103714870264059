interface MultiColumnContentfulProperties {
  gap: string;
  imagecrop: boolean;
  children: any[];
  'space-distribution': string;
  'break-on-tablet': boolean;
}

export const MultiColumnContentfulPropertiesTransformer = ({
  gap,
  imagecrop,
  children,
  ...rest
}: MultiColumnContentfulProperties) => {
  return {
    breakOnTablet: rest['break-on-tablet'],
    gap,
    imagecrop,
    spaceDistribution: rest['space-distribution'],
    children,
  };
};
