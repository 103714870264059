require('dotenv').config({
  path: `.env.development`,
});

const sitemapSerializer = require('./pages/utils/sitemap-serializer').sitemapSerializer;

const siteGlobals = {
  contentful: {
    spaceId: process.env.GATSBY_CONTENTFUL_APP_ID,
    accessToken: process.env.GATSBY_CONTENTFUL_API_KEY,
    host: `preview.contentful.com`,
  },
};

module.exports = {
  siteMetadata: {
    title: 'Pix4D',
    contentful: siteGlobals.contentful,
    siteUrl: 'https://www.pix4d.com',
    twitterHandle: '@pix4d',
  },
  plugins: [
    'gatsby-plugin-use-query-params',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-transformer-remark',
    'pix4d-japanese-locale-transformer',
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        serialize: sitemapSerializer,
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allSitePage {
              edges {
                node {
                  path
                  context {
                    locale
                    englishCanonicalUrl
                    noIndex
                  }
                }
              }
            }
          }`,
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://dev.www.pix4d.com/',
        sitemap: 'https://dev.www.pix4d.com/sitemap.xml',
        policy: [{ userAgent: '*', disallow: ['/'] }],
      },
    },
    {
      resolve: `gatsby-source-contentful`,
      options: siteGlobals.contentful,
    },
  ],
};
