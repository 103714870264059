import { graphql } from 'gatsby';
import throttle from 'lodash.throttle';
import * as Qs from 'query-string';
import { append, map, remove } from 'ramda';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { edgeLanguageFilter } from '../../../pages/utils/edge-language-filter';
import { Animation, ArticleCard, Layout, Slider, Translate } from '../../components';
import { FilterMapping, UrlFriendlyFilters } from '../../data/blog-filters-mapping';
import { Categories } from '../../data/blog-landing-filter-menu';
import { ContentfulBlogPost, ContentfulEdges, SiteConfig } from '../../interfaces';
import {
  fixContentfulAssetPath,
  localizeLink,
  makeTextUrlFriendly,
  startPageAnimation,
} from '../../utils';
import { Locale, translate } from '../../translations';
import { BlogLandingFilterMenu } from './blog-landing-filter-menu';
import { CarouselBlogPostTemplate } from './carousel-blogpost-template';

import './blog-landing-page.scss';

interface BlogLandingPageProperties {
  location: any;
  data: {
    allContentfulBlogPost: ContentfulEdges<ContentfulBlogPost>;
    site: SiteConfig;
  };
  pageContext: {
    locale: Locale;
  };
}

interface BlogLandingPageState {
  filters: string[];
  pagination: number;
  compactHeader: boolean;
}

const mapUrlFriendly = (label: string): string => makeTextUrlFriendly(label.toLowerCase());

class BlogLandingPage extends React.Component<BlogLandingPageProperties, BlogLandingPageState> {
  state: BlogLandingPageState = {
    filters: [],
    pagination: 0,
    compactHeader: false,
  };

  preventNextHistoryBack = false;

  private loadMoreEndReference: HTMLElement = null as any;

  get AllBlogPosts() {
    return this.props.data.allContentfulBlogPost.edges.filter(edgeLanguageFilter).map(x => x.node);
  }

  get CarouselBlogPosts() {
    return this.AllBlogPosts.slice(0, 3);
  }

  get AllFilteredBlogPosts() {
    const filters = map(x => FilterMapping[x], this.state.filters);
    return this.AllBlogPosts.filter(
      post =>
        post.labels &&
        filters.every(orFilters => orFilters.some(orFilter => post.labels.includes(orFilter))),
    );
  }

  get ActiveBlogPosts() {
    return this.AllFilteredBlogPosts.slice(0, 12 + 6 * this.state.pagination);
  }

  get HasMorePosts() {
    return this.AllFilteredBlogPosts.length > this.ActiveBlogPosts.length;
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onHistoryBack);
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidMount() {
    startPageAnimation();
    window.addEventListener('popstate', this.onHistoryBack);
    window.addEventListener('scroll', this.onScroll);
    const filtersFromQuery = this.getFiltersFromQueryString();

    if (filtersFromQuery.length) {
      this.setState(() => ({ filters: filtersFromQuery }));
    }
  }

  onHistoryBack = () => {
    if (this.preventNextHistoryBack) {
      this.preventNextHistoryBack = false;
      return;
    }
    this.setState(() => ({ filters: this.getFiltersFromQueryString() }));
  };

  onScroll = throttle(() => {
    if (this.loadMoreEndReference.getBoundingClientRect().bottom < 850) {
      this.loadMorePosts();
    }
  }, 400);

  getFiltersFromQueryString = () => {
    const filters = Qs.parse(this.props.location.search, { arrayFormat: 'index' })
      .categories as string[];

    return (filters || []).map(filter => UrlFriendlyFilters[filter]);
  };

  updateRoute = () => {
    const search = Qs.stringify(
      { categories: this.state.filters.map(mapUrlFriendly) },
      { arrayFormat: 'index' },
    );
    this.preventNextHistoryBack = true;
    window.history.pushState(
      null,
      this.props.data.site.siteMetadata.title,
      this.props.location.pathname + (search ? `?${search}` : ''),
    );
  };

  clearFilters = () => {
    this.setState(() => ({ filters: [], pagination: 0 }), this.updateRoute);
  };

  toggleFilter = input => {
    let { filters } = this.state;
    const labelIndex = filters.findIndex(x => x === input);

    if (labelIndex !== -1) {
      filters = remove(labelIndex, 1, filters);
    } else {
      filters = append(input, filters);
    }

    this.setState(() => ({ filters, pagination: 0 }), this.updateRoute);
  };

  loadMorePosts = () => {
    if (this.HasMorePosts) {
      this.setState(state => ({ pagination: state.pagination + 1 }));
    }
  };

  render() {
    const secondaryMenu = (
      <BlogLandingFilterMenu
        toggleFilter={this.toggleFilter}
        categories={Categories}
        activeFilters={this.state.filters}
      />
    );
    const locale = this.props.pageContext.locale;

    const canonical = `${this.props.data.site.siteMetadata.siteUrl}${localizeLink(
      locale,
      '/blog',
    )}`;

    const title = translate(locale, 'page.bloglanding.title');
    const description = translate(locale, 'page.bloglanding.description');

    return (
      <Layout
        headerDarkTheme={false}
        title={title}
        secondaryMenu={secondaryMenu}
        englishCanonicalUrl="/blog"
      >
        <Helmet>
          <link rel="canonical" href={canonical} />
          <meta name="description" content={description} />
        </Helmet>
        <div className="blog-landing">
          <div className="blog-landing-page" ref={ref => (this.loadMoreEndReference = ref!)}>
            <div className="tablet-hide mobile-hide blog-landing__carousel">
              {this.CarouselBlogPosts.length > 0 && (
                <Slider
                  items={this.CarouselBlogPosts}
                  itemsPerSlide={1}
                  rotateSlides={true}
                  itemTemplate={CarouselBlogPostTemplate}
                />
              )}
            </div>
            <div className="blog-landing-page--content">
              <div className="blog-landing-page--blog-listing">
                {this.ActiveBlogPosts.map((blogPost, index) => {
                  return (
                    <Animation key={`blogpost-${index}`}>
                      <ArticleCard
                        key={localizeLink(blogPost.fields.locale, `/blog/${blogPost.slug}`)}
                        image={
                          blogPost.heroImage && fixContentfulAssetPath(blogPost.heroImage.file.url)
                        }
                        imageAlt={blogPost.heroImage && blogPost.heroImage.title}
                        title={blogPost.title}
                        excerpt={blogPost.excerpt}
                        link={localizeLink(blogPost.fields.locale, `/blog/${blogPost.slug}`)}
                      />
                    </Animation>
                  );
                })}

                {this.ActiveBlogPosts.length <= 0 && (
                  <EmptyResults
                    filters={this.state.filters}
                    toggleFilter={this.toggleFilter}
                    clearFilters={this.clearFilters}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
const EmptyResults = ({ filters, toggleFilter, clearFilters }) => (
  <div className="blog-landing-page--empty">
    <h2>
      <Translate id="page.bloglanding.noResult" />
    </h2>
    {filters.map(filter => (
      <a href="#" key={filter} onClick={() => toggleFilter(filter)}>
        <button className="btn btn--secondary">×{filter}</button>
      </a>
    ))}
    <p>
      <Translate id="page.bloglanding.noResultHelp" />{' '}
      <a href="#" onClick={e => clearFilters(e)} className="color-primary">
        <Translate id="page.bloglanding.noResultClearFilters" />
      </a>
    </p>
  </div>
);
/* eslint-enable jsx-a11y/anchor-is-valid */

export default BlogLandingPage;

export const pageQuery = graphql`
  query blogLandingQuery($locale: String!) {
    allContentfulBlogPost(
      filter: { slug: { ne: null }, fields: { locale: { eq: $locale } }, time: { ne: null } }
      sort: { fields: [time], order: DESC }
    ) {
      edges {
        node {
          id
          title
          fields {
            locale
          }
          activeTranslations
          labels
          time
          slug
          excerpt
          heroImage: image {
            title
            file {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
