import { PriceProperties } from './types';
import { PricePropertiesDefaults } from '.';

export const PriceContentfulPropertiesTransformer = (props: PriceProperties) => {
  return {
    ...props,
    showcaption: props.showcaption
      ? JSON.parse(props.showcaption as any)
      : PricePropertiesDefaults.showcaption,
    otc: props.otc ? JSON.parse(props.otc as any) : PricePropertiesDefaults.otc,
    otcYear: props.otcyear ? JSON.parse(props.otcyear as any) : PricePropertiesDefaults.otcyear,
  };
};
