import * as React from 'react';
import { OS } from '../../../interfaces';
import { getCurrentOS } from '../../../utils/get-current-os';
import { DownloadButton } from './download-button/download-button';
import { NonSupportedDevicesBanner } from './non-supported-devices-banner/non-supported-devices-banner';
import { OtherOperatingSystemDropdown } from './other-operating-systems/other-operating-systems';

import './download-block.scss';

const DEFAULT_OS = OS.Windows;
const downloadLinksMap = (product: string, version: string) => ({
  [OS.Windows]: `${process.env.GATSBY_CLOUD_API_URL}download_release/${product}/${version}/windows`,
  [OS.Mac]: `${process.env.GATSBY_CLOUD_API_URL}download_release/${product}/${version}/mac`,
  [OS.Linux]: `${process.env.GATSBY_CLOUD_API_URL}download_release/${product}/${version}/linux`,
});

interface DownloadBlockProperties {
  availableOS: OS[];
  product: string;
  productVersion?: string;
  redirectLink?: string;
}

interface DownloadBlockState {
  detectedOS: OS | null;
  mounted: boolean;
}

export class DownloadBlock extends React.Component<DownloadBlockProperties> {
  state: DownloadBlockState = {
    detectedOS: null,
    mounted: false,
  };

  componentDidMount() {
    this.setState({ detectedOS: getCurrentOS(), mounted: true });
  }

  render() {
    const { product, availableOS, productVersion, redirectLink } = this.props;
    const { detectedOS, mounted } = this.state;
    const version = productVersion ? productVersion : 'latest';
    const downloadLinks = downloadLinksMap(product, version);
    const otherOperatingSystemDropdownProperties = {
      windowsLink: availableOS.indexOf(OS.Windows) >= 0 ? downloadLinks[OS.Windows] : undefined,
      macLink: availableOS.indexOf(OS.Mac) >= 0 ? downloadLinks[OS.Mac] : undefined,
      linuxLink: availableOS.indexOf(OS.Linux) >= 0 ? downloadLinks[OS.Linux] : undefined,
      redirectLink: redirectLink,
    };

    const isOSSupported = Boolean(detectedOS && availableOS.indexOf(detectedOS) >= 0);
    const isMapper = product === 'pix4d_mapper';
    const downloadButtonOS = !isOSSupported && !isMapper ? DEFAULT_OS : detectedOS!;
    var downloadLink = downloadLinks[downloadButtonOS];

    //Specific Pix4DMapper download URL
    if (isMapper) {
      downloadLink = productVersion
        ? `${process.env.GATSBY_CLOUD_API_URL}download/${product}/${version}/${downloadButtonOS}`
        : 'https://cloud.pix4d.com/download/mapperlatest';
    }

    return (
      <>
        <DownloadButton
          downloadLink={downloadLink}
          os={downloadButtonOS}
          isMapper={isMapper}
          isOSSupported={isOSSupported}
          redirectLink={redirectLink}
        />
        <div>
          <NonSupportedDevicesBanner show={mounted && !isOSSupported} isMapper={isMapper} />
          {availableOS.length > 1 && (
            <OtherOperatingSystemDropdown {...otherOperatingSystemDropdownProperties} />
          )}
        </div>
      </>
    );
  }
}
