import { Locale } from '../translations';

export function localizeLink(locale: Locale, slug?: string) {
  if (!slug || slug === 'home' || slug === '/home') {
    slug = '/';
  }

  if (locale !== Locale.English) {
    return `/${locale}${slug}`;
  }

  return slug;
}
