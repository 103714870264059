import * as React from 'react';
import { AuthService, isBrowser } from '../services/auth';

const auth = new AuthService();
const CLOUD_URL = process.env.GATSBY_CLOUD_API_URL;
const CLIENT_ID = process.env.GATSBY_OAUTH_CLIENT_ID;

const LogoutPage = () => {
  auth.clear();

  if (isBrowser) {
    window.location.href = `${CLOUD_URL}logout?redirect_uri=${window.location.origin}&client_id=${CLIENT_ID}`;
  }
  return <></>;
};

export default LogoutPage;
