export enum Currency {
  USD = 'usd',
  CHF = 'chf',
  EUR = 'eur',
  JPY = 'jpy',
}

export interface Localization {
  currency: Currency;
  country: string;
}
