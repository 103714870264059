import * as React from 'react';

import './comparison-slider.scss';

function SliderHandle({ backgroundColor, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <circle cx="16" cy="16" r="16" fill={backgroundColor}></circle>
      <g transform="translate(6,10.5)">
        <path d="M14.275 9.86774L17.9575 5.57144L14.275 1.27515" stroke={color} strokeWidth="2" />
        <path d="M5.68254 1.27521L2 5.5715L5.68254 9.8678" stroke={color} strokeWidth="2" />
      </g>
    </svg>
  );
}

interface ComparisonSliderProperties {
  imageLeft: string;
  imageRight: string;
  altLeft?: string;
  altRight?: string;
  sliderColor?: string;
  handleBackgroundColor?: string;
  handleColor?: string;
  leftLabelText?: string | null;
  rightLabelText?: string | null;
  sliderWidth?: string;
}

export function ComparisonSlider({
  imageLeft,
  imageRight,
  altLeft = 'altLeft',
  altRight = 'altRight',
  sliderColor = '#ffffff',
  handleBackgroundColor = '#ffffff',
  handleColor = '#34657F',
  leftLabelText,
  rightLabelText,
  sliderWidth = '6',
}: ComparisonSliderProperties): JSX.Element {
  const [fromLeft, setFromLeft] = React.useState<number | null>(null);
  const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false);
  const [sliderInitialPosition] = React.useState<number>(0.5);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const [firstImageLoaded, setFirstImageLoaded] = React.useState<boolean>(false);
  const [secondImageLoaded, setSecondImageLoaded] = React.useState<boolean>(false);
  const [imageSize, setImageSize] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const handleMouseDown = (
    mouseEvent: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ): void => {
    mouseEvent.stopPropagation();
    mouseEvent.preventDefault();
    setIsMouseDown(true);
  };

  const moveHandler = (clickPosition: number): void => {
    const sliderWidthNumber = Number(sliderWidth);
    if (containerRef && containerRef.current && isMouseDown) {
      const { left, width } = containerRef.current.getBoundingClientRect();

      if (clickPosition - left < 0) {
        setFromLeft(0 - sliderWidthNumber / 2);
      } else if (clickPosition > left + width) {
        setFromLeft(width - sliderWidthNumber / 2);
      } else {
        setFromLeft(clickPosition - left);
      }
    }
  };

  const setInitialState = (): void => {
    if (containerRef && containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      setFromLeft(width * sliderInitialPosition);
    }
    if (imageRef && imageRef.current) {
      const { width, height } = imageRef.current.getBoundingClientRect();
      setImageSize({
        width,
        height,
      });
    }
  };

  React.useEffect(() => {
    setInitialState();
  }, [sliderInitialPosition, firstImageLoaded, secondImageLoaded]);

  React.useEffect(() => {
    function handleMouseUp(): void {
      setIsMouseDown(false);
    }

    function handleMouseMove(mouseEvent: MouseEvent): void {
      moveHandler(mouseEvent.pageX);
    }

    function handleTouchMove(touchEvent: TouchEvent): void {
      moveHandler(touchEvent.touches[0].pageX);
    }

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove);

    return (): void => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isMouseDown, sliderWidth]);


  React.useEffect(() => {
    function handleContainerSize(): void {
      setInitialState();
    }

    window.addEventListener('resize', handleContainerSize);

    return (): void => {
      window.removeEventListener('resize', handleContainerSize);
    };
  });

  return (
    <div
      className="slider-wrapper"
      ref={containerRef}
      style={{
        cursor: isMouseDown ? 'ew-resize' : 'default',
      }}
    >
      {fromLeft !== null && (
        <>
          <div className="slider-container">
            <img
              alt={altRight}
              src={imageRight}
              onLoad={(): void => {
                setFirstImageLoaded(true);
              }}
              ref={imageRef}
            />

            {rightLabelText && <div className="label-text label-right">{rightLabelText}</div>}
          </div>

          <div
            className="slider-container slider-container-left"
            style={{ width: fromLeft <= 0 ? 0 : fromLeft }}
          >
            <img
              alt={altLeft}
              src={imageLeft}
              style={
                firstImageLoaded && secondImageLoaded
                  ? { width: imageSize.width, height: imageSize.height }
                  : {}

              }
              onLoad={(): void => {
                setSecondImageLoaded(true);
              }}
            />

            {leftLabelText && <div className="label-text label-left">{leftLabelText}</div>}
          </div>

          <div
            className="slider-stick"
            style={{
              left: fromLeft,
              backgroundColor: sliderColor,
              width: Number(sliderWidth),
              height: firstImageLoaded && secondImageLoaded ? imageSize.height : '100%',
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
          >
            <div className="slider-handle">
              <SliderHandle backgroundColor={handleBackgroundColor} color={handleColor} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
