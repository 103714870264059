import * as React from 'react';
import { Translate } from '../../layout/translate';

export interface BuyNowProperties {
  product: string;
}

export const BuyNowContentfulPropertiesTransformer = (props: BuyNowProperties) => {
  return {
    ...props,
  };
};

export const BuyNow = ({ product }: BuyNowProperties) => {
  return (
    <form
      method="POST"
      data-test={`buyNow-${product}`}
      action={`${process.env.GATSBY_CLOUD_API_URL}store/buy/`}
    >
      <input type="hidden" name="product_ref" value={product} />
      <button className="btn btn--primary" type="submit">
        <Translate id="component.price.buyNow" />
      </button>
    </form>
  );
};
