import { MarkdownRemark } from '../interfaces';
import { MarkdownRemarkConfig, renderMarkdownRemark } from './render-markdown-remark';
import { TransformerConfig } from './render-markdown-remark/apply-transformers';

export interface MarkdownRemarkRendererProperties extends TransformerConfig, MarkdownRemarkConfig {
  markdown: MarkdownRemark;
}

export const MarkdownRemarkRenderer = ({
  markdown,
  ...config
}: MarkdownRemarkRendererProperties): JSX.Element => {
  return markdown ? renderMarkdownRemark(markdown, config) : (null as any);
};
