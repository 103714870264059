/* eslint-disable jsx-a11y/anchor-is-valid */
import { SvgIcon } from '@pix4d/pix4d-design-system-lib';
import * as React from 'react';
import { OS, OS_ICONS } from '../../../../interfaces';
import { Translate } from '../../../layout';
import { Icon } from '../../icon';
import { IconDisplay } from '../icon-display/icon-display';
import './other-operating-systems.scss';

const BLUE = '#1e88e5';

interface OtherOperatingSystem {
  windowsLink?: string;
  macLink?: string;
  linuxLink?: string;
  redirectLink?: string;
}

export const OtherOperatingSystemDropdown = ({
  windowsLink,
  macLink,
  linuxLink,
  redirectLink,
}: OtherOperatingSystem) => {
  const [openState, setOpenState] = React.useState(false);

  const windowsIcon = OS_ICONS[OS.Windows];
  const macIcon = OS_ICONS[OS.Mac];
  const linuxIcon = OS_ICONS[OS.Linux];

  const arrowUpIcon: SvgIcon = 'arrow_up';
  const arrowDownIcon: SvgIcon = 'arrow_down';

  return (
    <div>
      <div className={`download-other-systems ${openState ? 'download-other-systems--open' : ''}`}>
        <div className={`download-other-systems__content ${openState ? 'box-shadow' : ''}`}>
          <a onClick={() => setOpenState(!openState)} className="title-action oneline">
            <Translate id="component.otherOperatingSystems.operatingSystems" />{' '}
            <Icon icon={openState ? arrowUpIcon : arrowDownIcon} color={BLUE} />
          </a>

          <div className="download-other-systems__options">
            {windowsLink && windowsIcon && (
              <IconDisplay
                downloadLink={windowsLink}
                redirectLink={redirectLink}
                icon={windowsIcon}
                title="component.otherOperatingSystems.windows"
                color={BLUE}
              />
            )}
            {macLink && macIcon && (
              <IconDisplay
                downloadLink={macLink}
                redirectLink={redirectLink}
                icon={macIcon}
                title="component.otherOperatingSystems.mac"
                color={BLUE}
              />
            )}
            {linuxLink && linuxIcon && (
              <IconDisplay
                downloadLink={linuxLink}
                redirectLink={redirectLink}
                icon={linuxIcon}
                title="component.otherOperatingSystems.linux"
                color={BLUE}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
