import * as React from 'react';
import { LocalizationContext } from '../../contexts';
import { Localization } from '../../contexts/types';

interface LocationalDisplayProperties {
  allowedCountries: string;
  children: JSX.Element[];
}

export const LocationalDisplay = ({ allowedCountries, children }: LocationalDisplayProperties) => {
  const allowedCountriesArray = allowedCountries.split(',');

  return (
    <LocalizationContext.Consumer>
      {(localization: Localization | null) => (
        <React.Fragment>
          {localization?.country && allowedCountriesArray.includes(localization?.country)
            ? children[0]
            : children[1]}
        </React.Fragment>
      )}
    </LocalizationContext.Consumer>
  );
};
