import { MarkdownRemarkElement } from '../../interfaces';
import { isCustomComponent } from './is-custom-component';

const allowedHTMLElements = [
  'a',
  'p',
  'div',
  'span',
  'img',
  'video',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'br',
  'strong',
  'b',
  'em',
  'i',
  'ul',
  'ol',
];
export const isPropertyNode = (element: MarkdownRemarkElement) =>
  allowedHTMLElements.indexOf(element.tagName) === -1 && !isCustomComponent(element);
