import { AuthService } from './auth';
import { License, LicenseJson, Session } from './types';

const CLOUD_API = process.env.GATSBY_CLOUD_API_URL;
const LICENCSE_MANAGER = `${CLOUD_API}rest/license_manager/v4/`;
const RELEASE_URL = `${LICENCSE_MANAGER}release/latest_release/`;

export class LicenseService {
  auth = new AuthService();
  session: Session | undefined = this.auth.get();

  getLatestReleaseForUser(product: string) {
    const productURL = `${RELEASE_URL}${product}/`;

    return this.fetchLicense(productURL)
      .then(license => {
        if (license) {
          return license;
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  private fetchLicense = (productURL: string): Promise<License | null | void> => {
    if (!this.session) {
      return Promise.resolve(null);
    }
    const token = this.session.accessToken;
    return fetch(productURL, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(licenseJson => this.deserializeLicense(licenseJson))
      .catch(err => {
        console.error(err);
      });
  };

  private deserializeLicense = (json: LicenseJson): License => {
    return {
      canDownloadLatest:
        typeof json.can_download_latest === 'undefined' ? true : json.can_download_latest,
      windows: json.old_available ? json.old_available.windows : '',
      linux: json.old_available ? json.old_available.windows : '',
      mac: json.old_available ? json.old_available.mac : '',
    };
  };
}
