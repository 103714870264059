import * as React from 'react';
import { AuthService, User } from '../../services';

const auth = new AuthService();

interface AuthContextData {
  isAuthenticated: boolean;
  user: User | null;
}
export const AuthContext = React.createContext<AuthContextData>({
  isAuthenticated: false,
  user: null,
});

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User | null>(null);

  auth.init();

  React.useState(() => {
    setIsAuthenticated(auth.isAuthenticated);
    setUser(auth.user);
  });

  return { isAuthenticated, user };
};
