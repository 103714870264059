import * as React from 'react';
import {
  InstantSearch,
  Hits,
  SearchBox,
  PoweredBy,
  Configure,
  Pagination,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { Block, Layout, Translate } from '../components';
import { HitPreview, CustomStats } from './search-algolia-components';
import './search-page.scss';
import { LocaleContext } from '../components/contexts';

const ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY_READ;
if (!ALGOLIA_APP_ID || !ALGOLIA_API_KEY) {
  throw new Error('Credential not provided');
}
const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

const SearchPage = () => {
  const [searchValue, setSearchValue] = React.useState(``);
  const [hitDisplay] = React.useState('block');

  React.useEffect(() => {
    const res = window.location.search
      ? decodeURIComponent(window.location.search).split('=')[1].replace(/-/g, ' ')
      : '';
    res.length !== 0 ? setSearchValue(res) : setSearchValue('');
  }, []);

  return (
    <Layout headerDarkTheme={false} englishCanonicalUrl={'/search'}>
      <div className="search-page">
        <Block>
          <div className="search-content">
            <h1>
              <Translate id="page.search-page.header" />
            </h1>
            <br />
            <LocaleContext.Consumer>
              {locale => (
                <InstantSearch indexName={locale} searchClient={searchClient}>
                  <SearchBox
                    className="ais-SearchBox"
                    autoFocus={true}
                    defaultRefinement={searchValue}
                    searchAsYouType={false}
                    showLoadingIndicator={false}
                    translations={{
                      placeholder: 'Search ...',
                      submitTitle: 'Submit your search query.',
                      resetTitle: 'Clear your search query.',
                    }}
                    focusShortcuts={['s', 8, 32, 13, 16]}
                  ></SearchBox>
                  <PoweredBy
                    translations={{
                      searchBy: 'Search by',
                    }}
                  />
                  <Configure hitsPerPage={10} />
                  <div
                    className="search-results"
                    style={{
                      display: hitDisplay,
                    }}
                  >
                    <CustomStats /> <br />
                    <Hits hitComponent={HitPreview} />
                  </div>
                  <Pagination
                    showFirst={true}
                    showLast={true}
                    showPrevious={true}
                    showNext={true}
                    padding={2}
                    totalPages={5}
                    translations={{
                      previous: '‹',
                      next: '›',
                      first: '«',
                      last: '»',
                      page(currentRefinement) {
                        return currentRefinement;
                      },
                    }}
                  />
                </InstantSearch>
              )}
            </LocaleContext.Consumer>
          </div>
        </Block>
      </div>
    </Layout>
  );
};

export default SearchPage;
