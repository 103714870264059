import * as React from 'react';
import { ResponsiveTemplate } from '../components';
import { ContentfulAsset, ContentfulFrame } from '../interfaces';
import { ContentfulMarkdownRemarkRenderer, fixContentfulAssetPath } from '../utils';

import { startPageAnimation } from '../utils/page-animation-queue';
import './frame-page-display.scss';

export interface FramePageDisplayProperties {
  isHomePage: boolean;
  frames: ContentfulFrame[];
  theme?: string;
  isDarkTheme: boolean;
}

const addHash = (color: string) => (color[0] === '#' ? color : `#${color}`);
const getStyle = (frame: ContentfulFrame) =>
  frame.backgroundColor ? { backgroundColor: addHash(frame.backgroundColor) } : undefined;

export const FramePageDisplay = ({ isHomePage, frames }: FramePageDisplayProperties) => (
  <div className={`frames ${isHomePage ? 'homepage' : ''}`}>
    {frames &&
      frames.map((frame, index) => (
        <FrameDisplay
          showScrollIndicator={isHomePage && index === 0}
          key={index}
          useDarkTheme={index === 0}
          isLastFrame={index === frames.length - 1}
          frame={frame}
          index={index}
        />
      ))}
  </div>
);

interface FrameProperties {
  frame: ContentfulFrame;
  index: number;
  isLastFrame: boolean;
  useDarkTheme: boolean;
  showScrollIndicator: boolean;
}

class FrameDisplay extends React.PureComponent<FrameProperties> {
  componentDidMount() {
    setTimeout(startPageAnimation, 500);
  }

  render() {
    const { frame, index, isLastFrame, useDarkTheme, showScrollIndicator } = this.props;

    return (
      <div
        className={`frame ${isLastFrame ? 'frame--last' : ''} ${useDarkTheme ? 'theme-dark' : ''} ${
          index === 0 ? 'frame--first frame--active' : ''
        }${frame.backgroundParallax ? 'background-parallax' : ''}`}
        style={{
          backgroundImage:
            frame.backgroundParallax && frame.backgroundImage
              ? 'url(' + frame.backgroundImage.file.url + ')'
              : undefined,
        }}
      >
        <div className="frame-background" style={getStyle(frame)}>
          <FrameBackgroundContent
            video={frame.backgroundVideo}
            image={frame.backgroundImage}
            staticBackground={!frame.backgroundParallax}
          />
        </div>
        <div className="frame-content">
          {frame.contents &&
            frame.contents.map(({ content }, i) => (
              <ContentfulMarkdownRemarkRenderer
                key={i}
                markdown={content}
                animation={index === 0}
                customComponentAnimation={true}
              />
            ))}
          {showScrollIndicator && (
            <div className="frame-homepage-scroll-indicator">
              <img alt="scroll indicator" src={require('../assets/home_scroll_indicator.svg')} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const BackgroundImage = ({ imageUrl, alt }: { alt: string; imageUrl: string }) => (
  <img
    onLoad={() => setTimeout(() => startPageAnimation(), 300)}
    alt={alt}
    className="frame-image"
    src={`${imageUrl}`}
    srcSet={`
        ${imageUrl}?w=480&fm=jpg&q=80 480w,
        ${imageUrl}?w=850&fm=jpg&q=80 850w,
        ${imageUrl}?w=1400&fm=jpg&q=80 1400w,
        ${imageUrl} 2560w
      `}
  />
);

const FrameBackgroundContent = ({
  video,
  image,
  staticBackground,
}: {
  video?: ContentfulAsset;
  image?: ContentfulAsset;
  staticBackground?: boolean;
}) => (
  <ResponsiveTemplate>
    <ResponsiveTemplate.Mobile>
      {staticBackground && image && image.file && (
        <BackgroundImage alt={image.title} imageUrl={fixContentfulAssetPath(image.file.url)} />
      )}
    </ResponsiveTemplate.Mobile>
    <ResponsiveTemplate.Desktop>
      {staticBackground && image && image.file && (
        <BackgroundImage alt={image.title} imageUrl={fixContentfulAssetPath(image.file.url)} />
      )}
      {video && (
        <video
          className="frame-video"
          playsInline={true}
          controls={false}
          autoPlay={true}
          loop={true}
          muted={true}
        >
          <source src={fixContentfulAssetPath(video.file.url)} type={video.file.contentType} />
        </video>
      )}
    </ResponsiveTemplate.Desktop>
  </ResponsiveTemplate>
);
