import { DEFAULT_TRANSLATIONS } from './translation_default';
import { TRANSLATION_KEYS } from './translation_keys';

let DE_TRANSLATIONS = {};
let EN_TRANSLATIONS = {};
let ES_TRANSLATIONS = {};
let FR_TRANSLATIONS = {};
let JP_TRANSLATIONS = {};
let PT_TRANSLATIONS = {};
let RU_TRANSLATIONS = {};

try {
  DE_TRANSLATIONS = require('../../translations_data/de.json');
  EN_TRANSLATIONS = require('../../translations_data/en.json');
  ES_TRANSLATIONS = require('../../translations_data/es.json');
  FR_TRANSLATIONS = require('../../translations_data/fr.json');
  JP_TRANSLATIONS = require('../../translations_data/ja.json');
  RU_TRANSLATIONS = require('../../translations_data/ru.json');
  PT_TRANSLATIONS = require('../../translations_data/pt.json');
} catch {}

export enum Locale {
  English = 'en-US',
  French = 'fr',
  German = 'de',
  Japanese = 'jp',
  Portuguese = 'pt',
  Russian = 'ru',
  Spanish = 'es',
}

function getTranslation(locale: Locale, key: TRANSLATION_KEYS) {
  if (locale === Locale.French && FR_TRANSLATIONS[key]) {
    return FR_TRANSLATIONS[key];
  }

  if (locale === Locale.German && DE_TRANSLATIONS[key]) {
    return DE_TRANSLATIONS[key];
  }

  if (locale === Locale.Japanese && JP_TRANSLATIONS[key]) {
    return JP_TRANSLATIONS[key];
  }

  if (locale === Locale.Portuguese && PT_TRANSLATIONS[key]) {
    return PT_TRANSLATIONS[key];
  }

  if (locale === Locale.Russian && RU_TRANSLATIONS[key]) {
    return RU_TRANSLATIONS[key];
  }

  if (locale === Locale.Spanish && ES_TRANSLATIONS[key]) {
    return ES_TRANSLATIONS[key];
  }

  if (EN_TRANSLATIONS[key]) {
    return EN_TRANSLATIONS[key];
  }

  // Local file fallback when files not loaded from lokalise
  if (DEFAULT_TRANSLATIONS[key]) {
    return DEFAULT_TRANSLATIONS[key];
  }

  return key;
}

export function translate(locale: Locale, key: TRANSLATION_KEYS, placeholders?: object) {
  var translatedKey: string = getTranslation(locale, key);

  if (translatedKey !== key) {
    if (placeholders) {
      for (const key in placeholders) {
        translatedKey = translatedKey.replace(`{${key}}`, placeholders[key]);
      }
    }
    return translatedKey;
  }

  console.error(`Translation not found for key "${key}" in "${locale}"`);
  return key;
}
