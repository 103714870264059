import * as React from 'react';
import { LinkLocalizer } from './link-localizer';
import { Translate } from './translate';

import './footer.scss';

export const Footer = () => (
  <footer className="footer theme-dark">
    <div className="footer__content">
      <div className="link-block-list mobile-full-width">
        <div className="link-block">
          <input
            type="checkbox"
            id="link-block-navigation-company"
            name="footer-mobile-navigation"
          />
          <label htmlFor="link-block-navigation-company" className="link-block__title">
            <Translate id="footer.company" />
            <i className="icon-arrow desktop-hide tablet-hide" />
          </label>
          <ul className="link-block__content">
            <li>
              <LinkLocalizer to="/about-us">
                <Translate id="footer.company.about" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/about-us#locations">
                <Translate id="footer.company.locations" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/careers">
                <Translate id="footer.company.careers" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/newsroom-and-media-resources">
                <Translate id="footer.media.newsroom" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/blog?categories[0]=use-cases">
                <Translate id="footer.company.useCases" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/legal">
                <Translate id="footer.termsAndPolicy" />
              </LinkLocalizer>
            </li>
          </ul>
        </div>

        <div className="link-block">
          <input
            type="checkbox"
            id="link-block-navigation-products"
            name="footer-mobile-navigation"
          />
          <label htmlFor="link-block-navigation-products" className="link-block__title">
            <Translate id="footer.industries" />
            <i className="icon-arrow desktop-hide tablet-hide" />
          </label>
          <ul className="link-block__content">
            <li>
              <LinkLocalizer to="/industry/surveying-mapping">
                <Translate id="header.industries.surveyAndMapping" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/industry/agriculture">
                <Translate id="header.industries.agriculture" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/industry/construction">
                <Translate id="header.industries.construction" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/education">
                <Translate id="header.industries.education" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/industry/inspection">
                <Translate id="header.industries.inspection" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/industry/mining">
                <Translate id="header.industries.miningAndAggregates" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/industry/oem-integrators">
                <Translate id="header.industries.oem" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/industry/public-safety">
                <Translate id="header.industries.publicSafety" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/industry/telecommunication">
                <Translate id="header.industries.telecom" />
              </LinkLocalizer>
            </li>
          </ul>
        </div>

        <div className="link-block">
          <input
            type="checkbox"
            id="link-block-navigation-resources"
            name="footer-mobile-navigation"
          />
          <label htmlFor="link-block-navigation-resources" className="link-block__title">
            <Translate id="footer.resources" />
            <i className="icon-arrow desktop-hide tablet-hide" />
          </label>
          <ul className="link-block__content">
            <li>
              <LinkLocalizer to="/software-solution-for-your-needs">
                <Translate id="footer.resources.guide" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/download-software">
                <Translate id="footer.resources.download" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/webinars-and-ebooks">
                <Translate id="footer.resources.webinar" />
              </LinkLocalizer>
            </li>
            <li>
              <a href="https://support.pix4d.com" target="_blank" rel="noopener noreferrer">
                <Translate id="footer.resources.documentation" />
              </a>
            </li>
            <li>
              <a
                href="https://training.pix4d.com/pages/workshops"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Translate id="footer.resources.workshops" />
              </a>
            </li>
            <li>
              <LinkLocalizer to="/blog">
                <Translate id="footer.resources.blog" />
              </LinkLocalizer>
            </li>
          </ul>
        </div>

        <div className="link-block">
          <input
            type="checkbox"
            id="link-block-navigation-partners"
            name="footer-mobile-navigation"
          />
          <label htmlFor="link-block-navigation-partners" className="link-block__title">
            <Translate id="footer.network" />
            <i className="icon-arrow desktop-hide tablet-hide" />
          </label>
          <ul className="link-block__content">
            <li>
              <LinkLocalizer to="/become-pix4d-partner">
                <Translate id="footer.network.becomePartner" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/partners#locate-partner">
                <Translate id="footer.network.locatePartner" />
              </LinkLocalizer>
            </li>
            <li>
              <LinkLocalizer to="/user-conference">
                <Translate id="footer.network.conference" />
              </LinkLocalizer>
            </li>
          </ul>
        </div>
      </div>

      <div className="company">
        <div className="socialmedia">
          <a href="https://www.facebook.com/Pix4D/" target="_blank" rel="noopener noreferrer">
            <i className="icon-facebook" />
          </a>
          <LinkLocalizer
            allowedLanguages={['jp']}
            to="https://twitter.com/pix4d_japan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-twitter" />
          </LinkLocalizer>
          <LinkLocalizer
            allowedLanguages={['en-US', 'de', 'fr', 'es', 'pt', 'ru']}
            to="https://twitter.com/pix4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-twitter" />
          </LinkLocalizer>
          <a
            href="https://www.linkedin.com/company/pix4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-linkedin" />
          </a>
          <a
            href="https://www.instagram.com/pix4d_official"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-instagram" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCXHBqjCbv1wj_-itfvpVNIA?sub_confirmation=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-youtube" />
          </a>
          <LinkLocalizer
            allowedLanguages={['jp']}
            to="https://lin.ee/mPmgYVJ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-line-chat" />
          </LinkLocalizer>
        </div>
        <div className="join-community">
          <a href="https://community.pix4d.com/" target="_blank" rel="noopener noreferrer">
            <Translate id="footer.joinCommunity" />
          </a>
          <br />
          <LinkLocalizer to="/subscribe-to-our-newsletters">
            <Translate id="footer.subscribeNewsletter" />
          </LinkLocalizer>
        </div>
        <div className="terms-and-service">
          <Translate id="footer.rights" />
        </div>
      </div>
    </div>
  </footer>
);
