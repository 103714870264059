import { MarkdownRemarkElement, MarkdownRemarkText } from '../../../interfaces';

export const ScriptLDJSONTransformer = (element: MarkdownRemarkElement): MarkdownRemarkElement => {
  if (
    element.tagName === 'script' &&
    element.properties &&
    element.properties.type === 'application/ld+json'
  ) {
    const text = element.children.length > 0 && (element.children[0] as MarkdownRemarkText).value;
    element.properties.dangerouslySetInnerHTML = { __html: text };
    element.children = [];
  }

  return element;
};
