import * as React from 'react';
import { LocalizationContext } from '../../contexts';
import { Localization } from '../../contexts/types';
import { Translate } from '../../layout';
import './price.scss';
import { PriceProperties } from './types';

export const PricePropertiesDefaults: PriceProperties = {
  chfsymbol: 'CHF',
  usdsymbol: 'USD',
  eursymbol: '€',
  jpysymbol: '￥',
  otc: false,
  otcyear: false,
  project: false,
  showcaption: true,
};
function formatPrice(price: string): JSX.Element {
  if (!price) {
    return <></>;
  }
  const [pre, post] = price.split('.');
  return (
    <>
      {pre}
      {post ? <sup className="sap">{post}</sup> : ''}
    </>
  );
}

function calculateYearlyTotalPrice(price: string): JSX.Element {
  if (!price) {
    return <></>;
  }
  const floatPrice = parseFloat(price) * 12;
  const pre = floatPrice.toString().split('.')[0];
  return <>{pre}</>;
}

export const Price = (props: PriceProperties) => {
  const data: PriceProperties = { ...PricePropertiesDefaults, ...props };

  const otcPeriod =
    data.otc && data.otcyear ? 'component.price.otcYearPeriod' : 'component.price.otcPeriod';

  if (data.otc) {
    return (
      <LocalizationContext.Consumer>
        {(localization: Localization | null) => (
          <span className={`price price--block`} key={`price-otc`}>
            <sup className="sap">{data[`${localization?.currency}symbol`]}</sup>
            {formatPrice(data[`${localization?.currency}otc`])}
            <span className="period">
              {' '}
              / <Translate id={otcPeriod} />
            </span>
          </span>
        )}
      </LocalizationContext.Consumer>
    );
  }

  if (data.project) {
    return (
      <LocalizationContext.Consumer>
        {(localization: Localization | null) => (
          <span className={`price price--block`} key={`price-project`}>
            <sup className="sap">{data[`${localization?.currency}symbol`]}</sup>
            {formatPrice(data[`${localization?.currency}project`])}
            <span className="period">
              {' '}
              / <Translate id={'component.price.project'} />
            </span>
          </span>
        )}
      </LocalizationContext.Consumer>
    );
  }

  return (
    <LocalizationContext.Consumer>
      {localization => (
        <div>
          <span className={`price price--monthly`} key={`price-monthly`}>
            <sup className="sap">{data[`${localization?.currency}symbol`]}</sup>
            {formatPrice(data[`${localization?.currency}monthly`])}
            <span className="period">
              {' '}
              / <Translate id="component.price.monthlyPeriod" />
            </span>
            {data.showcaption && (
              <>
                <br />
                <span className="caption">
                  <Translate id="component.price.monthlyCaption" />
                </span>
              </>
            )}
          </span>
          <span className={`price price--yearly`} key={`price-yearly`}>
            <sup className="sap">{data[`${localization?.currency}symbol`]}</sup>
            {formatPrice(data[`${localization?.currency}yearly`])}
            <span className="period">
              / <Translate id="component.price.yearlyPeriod" />
            </span>
            {data.showcaption && (
              <>
                <br />
                <span className="caption">
                  <span className="caption__discount">
                    <Translate id="component.price.yearlyCaptionDiscount" />
                  </span>
                  <Translate id="component.price.yearlyCaption" /> (
                  {data[`${localization?.currency}symbol`]}{' '}
                  {calculateYearlyTotalPrice(data[`${localization?.currency}yearly`])})
                </span>
              </>
            )}
          </span>
        </div>
      )}
    </LocalizationContext.Consumer>
  );
};
