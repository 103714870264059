export const batcher = (cb: Function, timeout: number) => {
  let counter = 0;

  return () => {
    if (counter++ !== 0) {
      return;
    }

    setTimeout(() => {
      counter = 0;
      cb();
    }, timeout);
  };
};
