import * as React from 'react';
import * as Qs from 'query-string';
import { Translate } from '../../layout/translate';

import './price-toggle.scss';

enum Period {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export const PriceToggle = () => {
  const handleChange = event => {
    // Set the toggle status on the layout, to show the correct prices
    document
      .querySelectorAll('.layout')
      .forEach(item => item.setAttribute('data-period-toggle', event.target.checked));

    // Set the toggle on or off
    document.querySelectorAll('.checkbox').forEach(item => {
      event.target.checked
        ? item.setAttribute('checked', event.target.checked)
        : item.removeAttribute('checked');
    });

    // Changes the period query param in the links hrefs
    document.querySelectorAll('.btn.btn--supl').forEach(button => {
      const href = button.getAttribute('href');
      if (href) {
        let parsed = Qs.parseUrl(href);
        parsed.query.period = event.target.checked ? Period.Monthly : Period.Yearly;
        const newUrl = `${parsed.url}?${Qs.stringify(parsed.query)}`;
        button.setAttribute('href', newUrl);
      }
    });
  };

  return (
    <span>
      <Translate id="component.priceToggle.annual" />
      <input type="checkbox" id="toggle" className="checkbox" onChange={handleChange} />
      <label data-test="priceToggle" htmlFor="toggle" className="switch"></label>
      <Translate id="component.priceToggle.monthly" />
    </span>
  );
};
