import * as React from 'react';
import { Locale } from '../../translations';

interface SiteLocale {
  [Locale.English]: string;
  [Locale.French]?: string;
  [Locale.German]?: string;
  [Locale.Japanese]?: string;
  [Locale.Portuguese]?: string;
  [Locale.Russian]?: string;
  [Locale.Spanish]?: string;
}

type Page = Record<string, SiteLocale>;

export const PagesContext = React.createContext<Page>({});
