import { ContentfulMarkdownRemark, MarkdownRemark, MarkdownRemarkElement } from '../interfaces';

function getHeaderNodes(content: MarkdownRemark): MarkdownRemarkElement[] {
  return content.children.filter(
    (x => x.type === 'element' && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(x.tagName)) as any,
  );
}

function getMarkdownRoot(contentfulMarkdown: ContentfulMarkdownRemark) {
  return contentfulMarkdown.childMarkdownRemark.htmlAst;
}

export const getHeaders = (content: ContentfulMarkdownRemark) => {
  const root = getMarkdownRoot(content);
  return getHeaderNodes(root);
};
