import * as React from 'react';
import { Helmet } from 'react-helmet';
import 'typeface-roboto';
import { Locale } from '../../translations';
import { compose, getLocaleFromPath, getLocalizedPages } from '../../utils';
import { AuthContext, LocaleContext, LocalizationContext, PagesContext } from '../contexts';
import { useAuth } from '../contexts/auth';
import { useLocalization } from '../contexts/localization';
import { ZoomContext } from '../dynamic-components/zoomable/zoom.context';
import { CookieBarContainer } from './cookie-bar-container';
import { Footer } from './footer';
import { Header } from './header';
import './layout.scss';

interface LayoutProperties {
  children: React.ReactNode;
  headerDarkTheme?: boolean;
  title?: string;
  notRenderHeaderAndFooter?: boolean;
  hideFooter?: boolean;
  secondaryMenu?: JSX.Element;
  englishCanonicalUrl: string;
  isGated?: boolean;
}

export const Layout = ({
  notRenderHeaderAndFooter,
  hideFooter,
  children,
  headerDarkTheme,
  title,
  secondaryMenu,
  englishCanonicalUrl,
  isGated,
}: LayoutProperties) => {
  const favicon = require('../../assets/favicon_new.png');

  const [zoomState, setZoomState] = React.useState(false);

  const activeLocale =
    typeof window === 'object' ? getLocaleFromPath(window.location.pathname) : Locale.English;
  const [localeState] = React.useState(activeLocale);
  const pages = getLocalizedPages();

  const { localization } = useLocalization();
  const { isAuthenticated, user } = useAuth();

  return compose(
    [
      [PagesContext, pages],
      [LocaleContext, localeState],
      [ZoomContext, { zoom: zoomState, setZoom: setZoomState }],
      [LocalizationContext, localization],
      [AuthContext, { isAuthenticated, user }],
    ],
    <div className={`layout ${notRenderHeaderAndFooter ? 'layout--no-header' : ''}`}>
      <Helmet link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}>
        <html lang={activeLocale} />

        <title>{title ? `${title} | Pix4D` : 'Pix4D'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="google-site-verification"
          content="kWB1x5lpC7ZFScLiHDBoVkZhqDjNmu6kwEHxpVwWOiE"
        />
        <meta name="yandex-verification" content="53ebf56424372620" />
        <link rel="dns-prefetch" href="https://images.ctfassets.net" />
        <link
          rel="shortcut icon"
          href="//images.ctfassets.net/go54bjdzbrgi/2DkaHD7yFJhUsKxIYRmPf2/1ededd61e4cbc1ceb52f3b879fbed46b/favicon_new.png"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {!notRenderHeaderAndFooter && (
        <ZoomContext.Consumer>
          {({ zoom }) => (
            <Header
              englishCanonicalUrl={englishCanonicalUrl}
              darkTheme={headerDarkTheme}
              secondaryMenu={secondaryMenu}
              zoomInProgress={zoom}
              isGated={isGated}
            />
          )}
        </ZoomContext.Consumer>
      )}
      {children}
      {!notRenderHeaderAndFooter && !hideFooter && <Footer />}
      <CookieBarContainer />
    </div>,
  );
};
