import * as React from 'react';
import { Layout, Block, Translate, LinkLocalizer } from '../components';

import './404.scss';

const NotFoundPage = () => (
  <Layout headerDarkTheme={false} englishCanonicalUrl="/">
    <div>
      <Block>
        <div className="page-not-found">
          <div>
            <h1>
              <Translate id="page.404.header" />
            </h1>
            <div className="subtitle light">
              <Translate id="page.404.subtitle" />
            </div>
            <div className="caption">
              <Translate id="page.404.error" />
            </div>
          </div>
          <div>
            <p>
              <Translate id="page.404.errorDescription" />
            </p>

            <div className="back-on-track">
              <Translate id="page.404.getBackOnTrack" />
              <ul>
                <li>
                  <LinkLocalizer to="/">
                    <Translate id="page.404.linkHome" />
                  </LinkLocalizer>
                </li>
                <li>
                  <LinkLocalizer
                    allowedLanguages={['en-US']}
                    to="/services/training-certification"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate id="page.404.linkTraining" />
                  </LinkLocalizer>
                </li>
                <li>
                  <LinkLocalizer to="/support">
                    <Translate id="page.404.linkSupport" />
                  </LinkLocalizer>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Block>
    </div>
  </Layout>
);

export default NotFoundPage;
