import { assocPath } from 'ramda';
import { MarkdownRemarkElement } from '../../../interfaces';
import { fixContentfulAssetPath } from '../../fix-contentful-asset-path';

export const ImageAssetPathTransformer = (
  element: MarkdownRemarkElement,
): MarkdownRemarkElement => {
  if (element.tagName !== 'img' || !element.properties.src) {
    return element;
  }

  return assocPath(['properties', 'src'], fixContentfulAssetPath(element.properties.src), element);
};
