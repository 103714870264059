import * as React from 'react';
import { Direction, Gesture } from '../../hoc';

import './slider-display.scss';

interface SliderDisplayProperties {
  slides: JSX.Element[];
  pagerElements: number;
  isActive: boolean;
  setIndex: (index: number) => void;
  currentIndex: number;
}

const transformPagerElements = (currentIndex: number, setIndex) => {
  return (element: any, index: number) => (
    <span
      key={index}
      onClick={() => setIndex(index)}
      onTouchEnd={() => setIndex(index)}
      className={[
        currentIndex === index ? 'active' : '',
        ' ',
        element === undefined ? 'dot' : '',
      ].join('')}
    >
      {element}
    </span>
  );
};

export class SliderDisplay extends React.PureComponent<SliderDisplayProperties> {
  onSwipe = (d: Direction) => {
    if (d === Direction.Left) {
      this.props.setIndex(this.props.currentIndex + 1);
    } else if (d === Direction.Right) {
      this.props.setIndex(this.props.currentIndex - 1);
    }
  };

  render() {
    const { slides, pagerElements, isActive, setIndex, currentIndex } = this.props;

    return (
      <Gesture
        className={`component-slider slider ${isActive ? 'slider--active' : ''}`}
        swipeTreashold={25}
        onSwipe={this.onSwipe}
      >
        <div className="slider-content">
          <div className="left-arrow" onClick={() => setIndex(currentIndex - 1)}>
            <i className="icon-arrow" />
          </div>

          <div className="slides" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
            {slides.map((content, index) => (
              <div
                key={`slide-${index}`}
                className={`slide ${index === currentIndex ? 'slide--active' : ''}`}
              >
                {content}
              </div>
            ))}
          </div>

          <div className="right-arrow" onClick={() => setIndex(currentIndex + 1)}>
            <i className="icon-arrow" />
          </div>
        </div>
        <div className="slider-pager">
          {new Array(pagerElements)
            .fill(undefined)
            .map(transformPagerElements(currentIndex, setIndex))}
        </div>
      </Gesture>
    );
  }
}
