import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as SiteConfig from '../../../gatsby-config';

interface OpenGraphHelmet {
  title: string;
  description: string;
  socialImage: string;
  type: string;
  url: string;
}

export const OpenGraphHelmet = ({ title, description, socialImage, type, url }) => (
  <Helmet>
    <meta name="og:type" property="og:type" content={type} />
    <meta name="og:title" property="og:title" content={title} />
    <meta name="og:description" property="og:description" content={description} />
    <meta name="og:site_name" property="og:site_name" content={SiteConfig.siteMetadata.title} />
    <meta name="og:image" property="og:image" content={socialImage} />
    <meta name="og:url" property="og:url" content={url} />

    <meta name="twitter:image" content={socialImage} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:site" content={SiteConfig.siteMetadata.twitterHandle} />
    <meta name="twitter:creator" content={SiteConfig.siteMetadata.twitterHandle} />
  </Helmet>
);
