interface TabsContentfulProperties {
  children: string;
  tabs: string;
}

export const TabsContentfulPropertiesTransformer = ({
  children,
  tabs,
  ...rest
}: TabsContentfulProperties) => {
  return {
    children,
    tabs,
  };
};
