import { MarkdownRemarkElement } from '../../../interfaces';
import { TransformerConfig } from '../apply-transformers';

const wrapImage = (image: MarkdownRemarkElement): MarkdownRemarkElement => {
  return {
    type: 'element',
    tagName: 'c-zoomable',
    properties: {},
    children: [image],
  };
};

export const ZoomableImageTransformer = (
  element: MarkdownRemarkElement,
  config: TransformerConfig,
): MarkdownRemarkElement => {
  if (element.tagName === 'img' && config.zoomableImages) {
    return wrapImage(element);
  }

  return element;
};
