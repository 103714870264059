const { edgeLanguageFilter } = require('./../../pages/utils/edge-language-filter');

let queryArray = [];

const flatten = (type, showOrder, arr) =>
  arr.map(({ node: { frontmatter, ...rest } }) => ({
    ...frontmatter,
    ...rest,
    type,
    showOrder,
  }));

const settings = {
  attributesToSnippet: [`excerpt:20`],
  searchableAttributes: [
    'unordered(title)',
    'labels,slug',
    'unordered(metaDescription)',
    'unordered(content.content)',
  ],
  ranking: [
    'desc(productPage)',
    'asc(showOrder)',
    'typo',
    'geo',
    'words',
    'filters',
    'proximity',
    'attribute',
    'exact',
    'custom',
  ],
};

const queries = createQueries();

//In order to create an unique index for each language available we have to pass the language locale in the query
function createQueries() {
  const localeArray = ['"en-US"', '"jp"', '"es"', '"de"', '"ru"', '"fr", "pt"'];

  localeArray.forEach(populateQueries);

  return queryArray;
}

function populateQueries(item) {
  let queryPageOne = `
  {
    allContentfulPage(filter: {fields: { locale: { eq: `;
  let queryPageTwo = ` }},pageType: { in: ["simple-page", "simple-page-without-header-and-footer", "frame-page"] }, slug: { ne: null regex: "/^(?!.*\\\\brecording\\\\b)(?!.*\\\\baufzeichnung\\\\b)(?!.*\\\\bgrabacion\\\\b).*$/i"}}) {
      edges {
        node {
          objectID: contentful_id
          slug
          fields {
            locale
          }
          activeTranslations
          updatedAt
          title
          metaDescription
          socialImage {
            file {
              url
            }
          }
          productPage
        }
      }
    }
  `;

  let pagesQuery = queryPageOne.concat(item, queryPageTwo);

  let queryBlogOne = `
    allContentfulBlogPost(filter: {fields: { locale: { eq: `;

  let queryBlogTwo = ` }},slug: { ne: null regex: "/^(?!.*\\\\brecording\\\\b)(?!.*\\\\baufzeichnung\\\\b)(?!.*\\\\bgrabacion\\\\b).*$/i"}}, sort: { fields: time, order: DESC }) {
      edges {
        node {
          objectID: contentful_id
          title
          fields {
            locale
          }
          activeTranslations
          updatedAt
          heroImage: image {
            title
            file {
              url
            }
          }
          content{
            content
          }
          excerpt
          slug
          labels
          metaDescription
        }
      }
    }
  }
  `;

  let blogpostsQuery = queryBlogOne.concat(item, queryBlogTwo);

  let finalQuery = pagesQuery.concat(blogpostsQuery);

  queryArray.push({
    query: finalQuery,
    transformer: ({ data }) =>
      flatten('blog', 2, data.allContentfulBlogPost.edges.filter(edgeLanguageFilter)).concat(
        flatten('page', 0, data.allContentfulPage.edges.filter(edgeLanguageFilter)),
      ),
    indexName: item.replace(/['"]+/g, ''),
    settings,
    matchFields: ['updatedAt'],
  });
}

module.exports = queries;
