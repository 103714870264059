import { assoc, chain, flatten, splitWhen } from 'ramda';
import { MarkdownRemarkChild, MarkdownRemarkElement } from '../../../interfaces';
import { isCustomComponent } from '../is-custom-component';

const BlockElements = ['div', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'br'];

const isBlockElement = (element: MarkdownRemarkChild) =>
  element.type === 'element' &&
  (isCustomComponent(element) || BlockElements.indexOf(element.tagName) > -1);

const explodeBlockElementsFromP = (pElement: MarkdownRemarkElement): MarkdownRemarkChild[] => {
  const [newPElementChildren, ...extractedElements] = splitWhen(isBlockElement, pElement.children);
  const flattenOtherElements = flatten<MarkdownRemarkChild[][]>(extractedElements);

  if (newPElementChildren.length > 0) {
    const newPElement = assoc('children', newPElementChildren, pElement);
    return [newPElement, ...flattenOtherElements];
  }
  return flattenOtherElements;
};

const transformPNodes = (element: MarkdownRemarkChild): MarkdownRemarkChild[] => {
  if (element.type === 'element' && element.tagName === 'p') {
    return explodeBlockElementsFromP(element);
  }

  return [element];
};

export const StandaloneParagraphsTransformer = (
  element: MarkdownRemarkElement,
): MarkdownRemarkElement => {
  const newChildren = chain(transformPNodes, element.children);
  return assoc('children', newChildren, element);
};
