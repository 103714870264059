import { assoc, dissocPath, takeWhile } from 'ramda';
import { MarkdownRemarkChild, MarkdownRemarkElement } from '../../../interfaces';
import { TransformerConfig } from '../apply-transformers';

const CAPTIONABLE_ELEMENTS = ['iframe', 'img', 'c-images', 'c-zoomable'];
const CAPTION_CONTENT_COMPATIBLE_ELEMENTS = ['a', 'b', 'i', 'strong', 'em'];

const wrapCaption = (elements: MarkdownRemarkChild[]): MarkdownRemarkElement => {
  return {
    type: 'element',
    tagName: 'div',
    properties: {
      className: ['caption'],
    },
    children: elements,
  };
};

const mergeElementWithCaption = (
  element: MarkdownRemarkElement,
  captionElements: MarkdownRemarkChild[],
): MarkdownRemarkElement => {
  const properties = element.properties.wide !== undefined ? { wide: '' } : {};

  return {
    type: 'element',
    tagName: 'div',
    properties,
    children: [dissocPath(['properties', 'wide'], element), wrapCaption(captionElements)],
  };
};

const isCaptionableElement = (element: MarkdownRemarkChild) =>
  element.type === 'element' && CAPTIONABLE_ELEMENTS.indexOf(element.tagName) !== -1;
const isCaptionContentCompatibleElement = (element: MarkdownRemarkChild) =>
  element &&
  (element.type === 'text' ||
    (element.type === 'element' &&
      CAPTION_CONTENT_COMPATIBLE_ELEMENTS.indexOf(element.tagName) !== -1));

export const CaptionableElementsTransformer = (
  root: MarkdownRemarkElement,
  config: TransformerConfig,
): MarkdownRemarkElement => {
  if (!config.imageCaption) {
    return root;
  }

  const newChildren: MarkdownRemarkChild[] = [];

  for (let index = 0; index < root.children.length; index++) {
    let element = root.children[index];

    if (isCaptionableElement(element)) {
      const captionElements = takeWhile(
        isCaptionContentCompatibleElement,
        root.children.slice(index + 1),
      );
      if (captionElements.length > 0) {
        element = mergeElementWithCaption(element as MarkdownRemarkElement, captionElements);
        index += captionElements.length;
      }
    }

    newChildren.push(element);
  }

  return assoc('children', newChildren, root);
};
