import * as React from 'react';
import { Link } from 'gatsby';
import { AuthContext } from '../contexts';
import { Translate } from './translate';
import { LoginButton } from './login-button';

import './user.scss';

const CLOUD_URL = process.env.GATSBY_CLOUD_API_URL;
const INSPECT_URL = process.env.GATSBY_INSPECT_API_URL;

export const User = ({ isGated }: { isGated?: boolean }) => {
  const { isAuthenticated, user } = React.useContext(AuthContext);

  if (isAuthenticated && user) {
    return (
      <>
        <input type="checkbox" className="mobile-submenu" id="mobile-submenu-user" />
        <a href="#/menu">
          <label htmlFor="mobile-submenu-user">
            <div className="user__avatar">{user.initial}</div>

            <span className="desktop-hide user__name">{user.fullName}</span>
            <i className="icon-arrow desktop-hide" />
          </label>
        </a>
        <ul className="popup-navigation user__menu">
          <li className="user__menu__name user__name mobile-hide">{user.fullName}</li>
          <li>
            <a href={`${CLOUD_URL}account`} target="_blank" rel="noopener noreferrer">
              <Translate id="header.user.accountSettings" />
            </a>
          </li>
          <li>
            <a href={`${CLOUD_URL}`} target="_blank" rel="noopener noreferrer">
              <Translate id="header.user.accessCloud" />
            </a>
          </li>
          <li>
            <a href={`${INSPECT_URL}`} target="_blank" rel="noopener noreferrer">
              <Translate id="header.user.accessInspect" />
            </a>
          </li>
          <li className="mobile-hide">
            <LoginButton isGated={isGated} enableLogoutBtn={true} />
          </li>
        </ul>
      </>
    );
  }
  return (
    <span className="mobile-hide tablet-hide">
      <LoginButton />
    </span>
  );
};
