import * as React from 'react';
import { LinkLocalizer } from './link-localizer';
import { Translate } from './translate';

import './cookie-bar.scss';

export const CookieBar = props => {
  const cookieTypes = [
    {
      id: 'necessary',
      title: 'component.cookieBar.necessaryCookies',
      text: 'component.cookieBar.necessaryCookiesText',
    },
    {
      id: 'analytic_storage',
      title: 'component.cookieBar.webCookies',
      text: 'component.cookieBar.webCookiesText',
    },
    {
      id: 'ad_storage',
      title: 'component.cookieBar.targetingCookies',
      text: 'component.cookieBar.targetingCookiesText',
    },
  ];

  const cookieMsgs = {
    necessary: 'component.cookieBar.necessaryCookiesText',
    analytic_storage: 'component.cookieBar.webCookiesText',
    ad_storage: 'component.cookieBar.targetingCookiesText',
  };

  const [activeType, setActiveType] = React.useState(cookieTypes[0].id);
  const [showSettings, setShowSettings] = React.useState(false);
  const [settings, setSettings] = React.useState({ analytic_storage: true, ad_storage: true });

  const acceptAll = () => {
    saveSettings({ analytic_storage: true, ad_storage: true });
  };

  const rejectAll = () => {
    saveSettings({ analytic_storage: false, ad_storage: false });
  };
  const saveSettings = config => {
    props.acceptCookiePolicy(config);
  };

  const renderCheckbox = type => {
    if (type === 'analytic_storage') {
      return (
        <div>
          <label className={`cookie-switch ${settings['analytic_storage'] ? 'checked' : ''}`}>
            <input
              type="checkbox"
              checked={settings['analytic_storage']}
              onChange={() => {
                setSettings({ ...settings, analytic_storage: !settings['analytic_storage'] });
              }}
            />
            <div></div>
          </label>
        </div>
      );
    } else if (type === 'ad_storage') {
      return (
        <div>
          <label className={`cookie-switch ${settings['ad_storage'] ? 'checked' : ''}`}>
            <input
              type="checkbox"
              checked={settings['ad_storage']}
              onChange={() => {
                setSettings({ ...settings, ad_storage: !settings['ad_storage'] });
              }}
            />
            <div></div>
          </label>
        </div>
      );
    }
    return;
  };

  return (
    <div className="cookie-bar">
      <div className="cookie-bar--wrapper">
        <div className="cookie-bar--message">
          <div className="cookie-bar--text">
            <Translate id="component.cookieBar.agreement" />{' '}
            <LinkLocalizer to="https://assets.ctfassets.net/go54bjdzbrgi/KjgABDnk5UXyQhnsJwDoz/b23fc61940675f92f13546aa4c34aa7a/Pix4D_-_Privacy_Policy.pdf">
              <Translate id="component.cookieBar.cookiePolicyLink" />
            </LinkLocalizer>
            .
          </div>
          <div className="cookie-bar--buttons">
            <button
              className="cookie-settings-btn"
              onClick={() => {
                setShowSettings(!showSettings);
              }}
            >
              <Translate id="component.cookieBar.settingsBtn" />
            </button>
            <button className="btn btn--primary" onClick={rejectAll}>
              <Translate id="component.cookieBar.rejectAllBtn" />
            </button>
            <button className="btn btn--primary" onClick={acceptAll}>
              <Translate id="component.cookieBar.acceptAllBtn" />
            </button>
          </div>
        </div>

        {showSettings && (
          <div>
            <div className="cookie-bar--settings">
              <div className="cookie-bar--column">
                {cookieTypes.map(el => {
                  return (
                    <div
                      id={el.id}
                      className={
                        'cookie-bar--cell cookie-type' + (el.id === activeType ? '--active' : '')
                      }
                      onClick={() => {
                        setActiveType(el.id);
                      }}
                    >
                      <Translate id={el.title as any} />
                    </div>
                  );
                })}
              </div>
              <div className="cookie-bar--column cookie-type-text">
                <div className="cookie-bar--text">
                  {<Translate id={cookieMsgs[activeType] as any} />}
                </div>
                {renderCheckbox(activeType)}
              </div>
            </div>
            <button
              className="btn btn--primary cookie-save-settings-btn"
              onClick={() => {
                saveSettings(settings);
              }}
            >
              <Translate id="component.cookieBar.saveSettingsBtn" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
