import * as React from 'react';
import { AuthService, getLoginUrl, getLogoutUrl, isBrowser } from '../../services/auth';
import { AuthContext } from '../contexts';
import { Translate } from './translate';

const CLOUD_URL = process.env.GATSBY_CLOUD_API_URL;

export const LoginButton = ({
  enableLogoutBtn,
  isGated,
}: {
  enableLogoutBtn?: boolean;
  isGated?: boolean;
}) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    const enableWelcomePage = true;
    window.location.replace(getLoginUrl(enableWelcomePage));
  };

  const handleLogout = (e: any) => {
    e.preventDefault();
    if (isBrowser) {
      const auth = new AuthService();
      auth.clear();
      window.location.replace(getLogoutUrl(isGated));
    } else {
      window.location.replace(`/logout`);
    }
  };

  const { isAuthenticated } = React.useContext(AuthContext);

  if (enableLogoutBtn && isAuthenticated) {
    return (
      <a href={`/logout`} onClick={handleLogout}>
        <Translate id="header.user.logout" />
      </a>
    );
  }

  return (
    <a href="#/login" onClick={handleClick}>
      <Translate id="header.login" />
    </a>
  );
};
