const sitemapSerializer = ({ site, allSitePage }) => {
  const localizationMap = allSitePage.edges.reduce((map, edge) => {
    const context = edge.node.context;
    map[context.englishCanonicalUrl] = map[context.englishCanonicalUrl] || {};
    map[context.englishCanonicalUrl][context.locale] = edge.node.path;
    return map;
  }, {});

  return allSitePage.edges.filter(isIndexable).map(serializePage(site, localizationMap));
};

const isIndexable = edge =>
  edge.node.context === undefined ||
  edge.node.context.noIndex !== true ||
  edge.node.context.gated !== true;
const serializePage = (site, localizationMap) => edge => {
  const siteLocaleMap = localizationMap[edge.node.context.englishCanonicalUrl];
  const otherLanguages = Object.keys(siteLocaleMap).filter(x => x !== edge.node.context.locale);
  const links = otherLanguages.map(locale => ({
    lang: locale === 'jp' ? 'ja' : locale,
    url: site.siteMetadata.siteUrl + siteLocaleMap[locale],
  }));

  return {
    url: site.siteMetadata.siteUrl + edge.node.path,
    changefreq: `daily`,
    priority: 0.7,
    links,
  };
};

module.exports = {
  sitemapSerializer,
};
