import { SvgIcon } from '@pix4d/pix4d-design-system-lib';
import * as React from 'react';

interface IconProperties {
  icon: SvgIcon;
  color?: string;
}

export function decodeSvg(svg: string) {
  if (typeof window !== 'undefined') {
    return atob(svg);
  }
  return Buffer.from(svg, 'base64').toString('binary');
}

export const Icon = ({ icon, color }: IconProperties) => {
  const iconData = require(`@pix4d/pix4d-design-system-lib/icons-optimized/${icon}.svg`);
  const svg = decodeSvg(iconData.replace(/data:image\/svg\+xml;base64,/, ''));
  return (
    <i
      className="icon"
      style={{ fill: color || 'currentColor' }}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};
