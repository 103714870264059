import * as React from 'react';
import { WorkflowDisplay, WorkflowItem } from './workflow-display';

interface WorkflowProperties {
  items: WorkflowItem[];
}

interface WorkflowState {
  activeIndex: number;
}

export class Workflow extends React.PureComponent<WorkflowProperties, WorkflowState> {
  state = {
    activeIndex: 0,
  };

  onSelect = (newIndex: number) => {
    // Keep the index in valid range
    const activeIndex = (newIndex + this.props.items.length) % this.props.items.length;
    this.setState(() => ({ activeIndex }));
  };

  render() {
    return (
      <WorkflowDisplay
        activeIndex={this.state.activeIndex}
        onSelect={this.onSelect}
        items={this.props.items}
      />
    );
  }
}
