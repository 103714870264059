interface CallToActionProperties {
  title: string;
  subtitle: string;
  'primary-button': string;
  'primary-button-link': string;
  'primary-button-target'?: string;
  'secondary-button'?: string;
  'secondary-button-link'?: string;
  'secondary-button-target'?: string;
}

export const CallToActionContentfulPropertiesTransformer = ({
  title,
  subtitle,
  ...rest
}: CallToActionProperties) => {
  return {
    title,
    subtitle,
    primaryButton: rest['primary-button'],
    primaryButtonLink: rest['primary-button-link'],
    primaryButtonTarget: rest['primary-button-target'],
    secondaryButton: rest['secondary-button'],
    secondaryButtonLink: rest['secondary-button-link'],
    secondaryButtonTarget: rest['secondary-button-target'],
  };
};
