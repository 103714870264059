import * as React from 'react';
import './multi-column.scss';

interface MultiColumnProperties {
  children: JSX.Element[];
  gap?: string;
  spaceDistribution?: string;
  imagecrop?: string | boolean;
  breakOnTablet?: boolean;
}

const isImageChild = (element: JSX.Element) => {
  if (element.type === 'img' || element.type === 'video') {
    return true;
  }
  if (
    element.props.children &&
    (element.props.children.type === 'img' || element.props.children.type === 'video')
  ) {
    return true;
  }

  return false;
};

const calculateMultiColumnDisplayProperties = ({
  children,
  spaceDistribution = '',
  gap = '',
  breakOnTablet,
}: MultiColumnProperties) => {
  const ratios = spaceDistribution ? spaceDistribution.split(',') : [];
  const definedChildren = children.filter(x => x);
  const styles: object[] = definedChildren.map((_, index) => {
    return {
      flex: ratios[index],
      marginRight: index !== definedChildren.length - 1 ? gap : undefined,
    };
  });

  const doesBreakOnTablet =
    breakOnTablet !== undefined ||
    (definedChildren.length === 2 &&
      (isImageChild(definedChildren[0]) || isImageChild(definedChildren[1])));

  return {
    ratios,
    definedChildren,
    styles,
    doesBreakOnTablet,
  };
};

const MultiColumnDisplay = ({ breakOnTablet, imagecrop, children, styles }) => (
  <div
    className={`component-multicolumn ${
      imagecrop !== undefined ? 'component-multicolumn--image' : ''
    } ${breakOnTablet ? 'component-multicolumn--tablebreak' : ''}`}
    style={{ height: typeof imagecrop === 'string' ? imagecrop : 'auto' }}
  >
    {children.map((child, index) => (
      <div
        className={`column ${isImageChild(child) ? 'column--first' : ''}`}
        style={styles[index]}
        key={index}
      >
        {child}
      </div>
    ))}
  </div>
);

export const MultiColumn = ({ imagecrop, ...props }: MultiColumnProperties) => {
  const { doesBreakOnTablet, definedChildren, styles } = calculateMultiColumnDisplayProperties(
    props,
  );
  return (
    <MultiColumnDisplay
      breakOnTablet={doesBreakOnTablet}
      imagecrop={imagecrop}
      children={definedChildren}
      styles={styles}
    />
  );
};
