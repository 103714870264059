import { MarkdownRemark } from '../../../interfaces';
import { WorkflowDisplayProperties, WorkflowItem } from './workflow-display';

interface WorkflowContentfulProperties {
  items: {
    icon: MarkdownRemark;
    title: MarkdownRemark;
    figure: MarkdownRemark;
    text: MarkdownRemark;
  }[];
}

export const WorkflowContentfulPropertiesTransformer = ({
  items = [],
  ...rest
}: WorkflowContentfulProperties & WorkflowDisplayProperties) => {
  const transformedItems: WorkflowItem[] = items.map(x => ({
    icon: x.icon,
    title: x.title,
    image: x.figure,
    text: x.text,
  }));

  return {
    items: transformedItems,
    ...rest,
  };
};
