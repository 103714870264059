import * as React from 'react';
import { Animation } from '../../layout';
import { TilesProperties } from './tiles.interface';
import './tiles.scss';

export const Tiles = ({ items, row = 4, centered }: TilesProperties) => (
  <div className={`component-tiles ${centered === true ? 'component-tiles--center' : ''}`}>
    {items &&
      items.map((item, index) => (
        <div className={`tile-wrap tile-wrap--${row}-rows`} key={index}>
          {item}
        </div>
      ))}
  </div>
);

export const TilesAnimated = ({ items, row = 4, centered }: TilesProperties) => (
  <Tiles
    items={items.map((tile, index) => (
      <Animation key={`tile-${index}`}>{tile}</Animation>
    ))}
    row={row}
    centered={centered}
  />
);
