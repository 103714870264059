import { MarkdownRemark, MarkdownRemarkElement } from '../interfaces';

export const getMarkdownProperty = (
  element: MarkdownRemark | MarkdownRemarkElement,
  property: string,
): string | undefined => {
  if (!element || !('properties' in element)) {
    return undefined;
  }

  return element.properties[property];
};
