import { Link } from '@reach/router';
import { filter, head, keys, pipe } from 'ramda';
import * as React from 'react';
import { FilterMapping } from '../../data/blog-filters-mapping';
import { makeTextUrlFriendly } from '../../utils';

interface BlogLandingLinkProperties {
  labels: string | string[];
  children: JSX.Element | string;
}

const getCategory = (label: string): string[][] =>
  filter(arr => arr.indexOf(label) !== -1, FilterMapping);
const findCategory = (label: string): any => pipe(getCategory, keys, head)(label);

const convertLabelToCategory = (label: string) => makeTextUrlFriendly(label.toLowerCase());
const convertLabelsToLinkparams = (label: string | string[]) => {
  const query = []
    .concat(label as any)
    .map(findCategory)
    .filter(x => x)
    .map(convertLabelToCategory)
    .map(x => `categories[]=${x}`)
    .join('&');

  return query ? `?${query}` : '';
};

export const BlogLandingLink = ({ labels, children }: BlogLandingLinkProperties) => (
  <Link to={`/blog${convertLabelsToLinkparams(labels)}`}>{children}</Link>
);
