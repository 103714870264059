import { assoc } from 'ramda';
import { MarkdownRemark, MarkdownRemarkChild, MarkdownRemarkElement } from '../../../interfaces';
import { calculateSrcset } from '../../calculate-srcset';

const isImageNode = (node: MarkdownRemarkChild) =>
  node.type === 'element' && node.tagName === 'img';

const elementImageTiles = (element: MarkdownRemarkElement) => {
  return new Array(element.properties.items.length || element.children.length).fill(4);
};

const elementFeaturesWide = (element: MarkdownRemarkElement): number[] => {
  return new Array(element.properties.items.length || element.children.length).fill(7);
};

const elementWorkflow = (element: MarkdownRemarkElement): number[] => {
  return new Array(element.properties.items.length || element.children.length).fill(4);
};

const getWidth = (columns: number) => columns * 100;

const addImageSrcSet = (element: MarkdownRemarkElement, columns: number): MarkdownRemarkElement => {
  const columnWidth = getWidth(columns);

  element.properties.srcSet = calculateSrcset(element.properties.src, columnWidth);
  return element;
};

const calculateRelatedColumns = (parentColumns: number, elementColumns: number, minColumns = 0) => {
  const relatedColumnsToParent = Math.ceil(parentColumns * (elementColumns / 12));
  return Math.max(minColumns, relatedColumnsToParent);
};

const getCustomElementWrapper = (element: MarkdownRemarkElement): number => {
  if (element.properties.medium !== undefined) {
    return 10;
  }
  if (element.properties.small !== undefined) {
    return 6;
  }

  return 12;
};

const elementMultiColumn = (element: MarkdownRemarkElement): number[] => {
  let distributions: number[];
  if (
    element.children.length === 2 &&
    (isImageNode(element.children[0]) || isImageNode(element.children[1]))
  ) {
    return new Array(element.children.length).fill(9);
  }
  if (element.properties['space-distribution']) {
    distributions = element.properties['space-distribution']
      .split(',')
      .map(x => parseInt(x, 10)) as number[];
  } else {
    const oneChildWidth = 12 / element.children.length;
    distributions = new Array(element.children.length).fill(oneChildWidth);
  }

  const sum = distributions.reduce((x, y) => x + y, 0);
  return distributions.map(d => Math.ceil((d / sum) * 12));
};

const getElementColumnWidth = (element: MarkdownRemarkElement, parentColumn: number): number[] => {
  if (element.tagName.startsWith('c-')) {
    parentColumn = calculateRelatedColumns(parentColumn, getCustomElementWrapper(element));
  }

  if (element.tagName === 'c-multicolumn') {
    return elementMultiColumn(element).map(x => calculateRelatedColumns(parentColumn, x, 4));
  }

  if (element.tagName === 'c-imagetiles') {
    return elementImageTiles(element).map(x => calculateRelatedColumns(parentColumn, x, 4));
  }

  if (element.tagName === 'c-featureswide') {
    return elementFeaturesWide(element).map(x => calculateRelatedColumns(parentColumn, x));
  }
  if (element.tagName === 'c-workflow') {
    return elementWorkflow(element).map(x => calculateRelatedColumns(parentColumn, x));
  }

  return new Array(element.children.length).fill(parentColumn);
};

const transformChildren = (
  element: MarkdownRemarkChild,
  elementColumn: number,
): MarkdownRemarkChild => {
  if (element.type === 'text' || element.type === 'comment') {
    return element;
  }

  if (element.tagName === 'img') {
    return addImageSrcSet(element, elementColumn);
  }

  const childrenColumns = getElementColumnWidth(element, elementColumn);

  return assoc(
    'children',
    element.children &&
      element.children.map((child, index) => transformChildren(child, childrenColumns[index])),
    element,
  );
};

const elementTransformer = (element: MarkdownRemark, rootColumn = 12) => {
  return assoc(
    'children',
    element.children && element.children.map(child => transformChildren(child, rootColumn)),
    element,
  );
};

export const ImageMediaSourceTransformer = (root: MarkdownRemark): MarkdownRemark => {
  if (root.type !== 'root') {
    return root;
  }

  return elementTransformer(root);
};
