import { chain, fromPairs, keys, map, pipe } from 'ramda';
import { makeTextUrlFriendly } from '../utils';

const includeFilter = (filter: string) => `#${filter}#`;
const isIncludeFilter = (filter: string) => filter[0] === '#' && filter[filter.length - 1] === '#';
const cleanFilterWrap = (filter: string) => filter.slice(1, -1);

const FilterMappingRaw: { [key: string]: string[] } = {
  'Use cases': ['Use cases'],
  Articles: ['Articles'],
  'Software updates': ['Software updates'],
  'Tips & tricks': ['Tips & tricks'],
  'News & events': ['News & events'],
  Agriculture: ['Agriculture', 'Crop management', 'Digital scouting', 'Parrot Sequoia'],
  'Cultural heritage': ['Cultural heritage'],
  Energy: ['Energy'],
  'Humanitarian aid': ['Insurance', 'Forensics & scene reconstruction'],
  'Mining & Earthworks': ['Mining & aggregates', 'Earthworks'],
  'Public safety': [
    'Public safety',
    'Event & emergency management',
    'Search & rescue',
    'Forensics & scene reconstruction',
  ],
  'Public works': ['Public works'],
  'Real estate': ['Real estate'],
  Partners: ['Partners'],
  Research: ['Research'],
  'Utilities & Energy': ['Utilities', 'Energy'],
  PIX4Dmapper: ['Pix4Dmapper'],
  PIX4Dcloud: ['Pix4Dcloud', 'Pix4Dbim', 'Pix4Dcloud Advanced'],
  PIX4Dcapture: ['Pix4Dcapture'],
  PIX4Dcatch: ['Pix4Dcatch'],
  PIX4Dreact: ['Pix4Dreact'],
  PIX4Dsurvey: ['Pix4Dsurvey'],
  PIX4Dfields: ['Pix4Dfields'],
  PIX4Dinspect: ['Pix4Dinspect'],
  PIX4Dscan: ['Pix4Dscan'],
  PIX4Dmatic: ['Pix4Dmatic'],
  PIX4Dengine: ['Pix4Dengine'],
  'Parrot Sequoia': ['Parrot sequoia'],
  CraneCamera: ['Crane Camera', 'CraneCamera'],
  'Training & certification': ['Training & certification'],
  'viDoc RTK rover': ['viDoc RTK rover'],
  'Terrestrial scanning': ['Terrestrial scanning'],
  '3D modeling': ['3D modeling'],
  'Aerial mapping': [includeFilter('Surveying & mapping')],
  'Aerial photogrammetry': [includeFilter('Surveying & mapping')],
  Earthworks: ['Earthworks'],
  'Digital scouting/crops': [includeFilter('Agriculture')],
  Environment: ['Environmental monitoring', 'Environment'],
  Forensics: [includeFilter('Public safety')],
  'Indoor mapping': ['Indoor mapping'],
  'Inspection & Telecom': ['Infrastructure & asset management', 'Inspection & Telecom'],
  'Scanning & mapping': ['Scanning & mapping'],
  Surveying: [includeFilter('Surveying & mapping')],
  'Webinar & Ebook': ['Webinar', 'Ebook'],
  'Surveying & mapping': [
    'Surveying & mapping',
    'Surveying',
    'GIS',
    'Thermal mapping',
    'Aerial photogrammetry',
    'Aerial mapping',
  ],
  'Construction/BIM': [
    'Construction',
    'Project planning and design',
    'Infrastructure & asset management',
    'Earthworks',
    'BIM',
    'As-built monitoring',
  ],
};

const resolveLabelGroup = chain((label: string) => {
  if (isIncludeFilter(label)) {
    return FilterMappingRaw[cleanFilterWrap(label)];
  }

  return [label];
});

const resolveNestedFilters = map(resolveLabelGroup);

// @ts-ignore
export const FilterMapping = resolveNestedFilters(FilterMappingRaw);
export const UrlFriendlyFilters = pipe(
  keys,
  map<string, [string, string]>(x => [makeTextUrlFriendly(x.toLowerCase()), x]),
  x => fromPairs(x),
)(FilterMapping);
