interface ComparisonSliderProperties {
  'left-image': string;
  'right-image': string;
  'alt-left'?: string;
  'alt-right'?: string;
  'slider-color'?: string;
  'handle-background-color'?: string;
  'handle-color'?: string;
  'left-label-text'?: string;
  'right-label-text'?: string;
  'slider-width'?: string;
}

export const ComparisonSliderPropertiesTransformer = ({ ...rest }: ComparisonSliderProperties) => {
  return {
    imageLeft: rest['left-image'],
    imageRight: rest['right-image'],
    altLeft: rest['alt-left'],
    altRight: rest['alt-right'],
    sliderColor: rest['slider-color'],
    handleBackgroundColor: rest['handle-background-color'],
    handleColor: rest['handle-color'],
    leftLabelText: rest['left-label-text'],
    rightLabelText: rest['right-label-text'],
    sliderWidth: rest['slider-width'],
  };
};
