import { eqProps } from 'ramda';
import * as React from 'react';
import { LinkLocalizer } from '../../layout';

interface DoubleLinkProperties {
  children: Array<JSX.Element | undefined> | JSX.Element;
  downloadLink: string;
  to?: string;
  className?: string;
}

export const DoubleLink = ({ ...props }: DoubleLinkProperties) => {
  return (
    <LinkLocalizer
      onClick={() => {
        window.location.href = props.downloadLink;
      }}
      {...props}
    >
      {props.children}
    </LinkLocalizer>
  );
};
