import * as React from 'react';
import { Locale, translate, TRANSLATION_KEYS } from '../../translations';
import { LocaleContext } from '../contexts';

interface TranslateProperties {
  id: TRANSLATION_KEYS;
  locale?: Locale;
  placeholders?: object;
}

export const Translate = ({ id, locale, placeholders }: TranslateProperties): JSX.Element => {
  if (locale) {
    return translate(locale, id, placeholders) as any;
  }

  return (
    <LocaleContext.Consumer>
      {contextLocale => translate(contextLocale, id, placeholders)}
    </LocaleContext.Consumer>
  );
};
