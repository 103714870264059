import { ContentfulMarkdownRemark } from '../interfaces';
import {
  MarkdownRemarkRenderer,
  MarkdownRemarkRendererProperties,
} from './markdown-remark-renderer';

interface ContentfulMarkdownRemarkRendererProperties
  extends Omit<MarkdownRemarkRendererProperties, 'markdown'> {
  markdown: ContentfulMarkdownRemark;
}

export const ContentfulMarkdownRemarkRenderer = ({
  markdown,
  ...config
}: ContentfulMarkdownRemarkRendererProperties) => {
  if (markdown === null) {
    return null;
  }

  const content = markdown.childMarkdownRemark.htmlAst;
  return MarkdownRemarkRenderer({ markdown: content, ...config });
};
