interface SoftwareVersionBannerProperties {
  'software-code': string;
}

export const SoftwareVersionBannerPropertiesTransformer = ({
  ...rest
}: SoftwareVersionBannerProperties) => {
  return {
    softwareCode: rest['software-code'],
  };
};
