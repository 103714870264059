import { __, assoc, curry, map, pipe as pipeAny } from 'ramda';
import { MarkdownRemark, MarkdownRemarkChild, MarkdownRemarkElement } from '../../interfaces';
import * as transformers from './transformers';

export interface TransformerConfig {
  zoomableImages?: boolean;
  imageCaption?: boolean;
  lazyLoadImages?: boolean;
}

const pipe = pipeAny as any;

type ElementTransformer = (
  element: MarkdownRemarkElement,
  config: TransformerConfig,
) => MarkdownRemarkElement;
type RootTransformer = (element: MarkdownRemark, config: TransformerConfig) => MarkdownRemark;
type Transformer = RootTransformer | ElementTransformer;

type ConfiguredElementTransformer = (element: MarkdownRemarkElement) => MarkdownRemarkElement;

const TRANSFORMERS: Transformer[] = [
  transformers.StylePropertyTransformer,
  transformers.ScriptLDJSONTransformer,
  transformers.ImageAssetPathTransformer,
  transformers.ImageMediaSourceTransformer,
  transformers.IframeSizeTransformer,
  transformers.EmptyNewlinesTransformer,
  transformers.PatchSelfClosingPropertiesTransformer,
  transformers.BlockquoteTransformer,
  transformers.LazyLoadImageTransformer,
  transformers.ZoomableImageTransformer,
  transformers.CaptionableElementsTransformer,
  transformers.CustomElementPropertiesTransformer,
  transformers.StandaloneParagraphsTransformer,
  transformers.BlockWideTransformer,
  transformers.LinkLocalizerTransformer,
];

const getConfiguredTransformers = (config?: TransformerConfig) => {
  return map(pipe(curry, transformer => transformer(__, config || {})))(
    TRANSFORMERS,
  ) as ConfiguredElementTransformer[];
};

export const applyTransformersOnNode = (
  node: MarkdownRemarkChild | MarkdownRemark,
  transformersToApply: ConfiguredElementTransformer[],
): MarkdownRemarkChild | MarkdownRemark => {
  if (node.type === 'text' || node.type === 'comment') {
    return node;
  }

  const transformedChildrens = node.children.map(child =>
    applyTransformersOnNode(child, transformersToApply),
  );
  const newRoot = assoc('children', transformedChildrens, node);
  return pipe(...transformersToApply)(newRoot);
};

export const applyTransformers = (
  node: MarkdownRemarkChild | MarkdownRemark,
  config?: TransformerConfig,
): MarkdownRemarkChild | MarkdownRemark => {
  const transformersToApply = getConfiguredTransformers(config);
  return applyTransformersOnNode(node, transformersToApply);
};
