import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import * as React from 'react';
import { Locale } from '../../translations';
import { localizeLink } from '../../utils';
import { LocaleContext } from '../contexts';
import { PagesContext } from '../contexts';
import { getRedirectionLink } from '../../../gatsby-browser';

export interface LinkLocalizerProperties extends Omit<GatsbyLinkProps<any>, 'to'> {
  lang?: Locale;
  to?: string;
  defaultTo?: string;
  allowedLanguages?: Array<string>;
}

export const LinkLocalizer = ({
  to,
  defaultTo,
  allowedLanguages,
  lang,
  ...rest
}: LinkLocalizerProperties) => (
  <PagesContext.Consumer>
    {pages => {
      return (
        <LocaleContext.Consumer>
          {localeContext => {
            const show: boolean = allowedLanguages
              ? allowedLanguages.includes(localeContext)
              : true;
            const finalTo = to === null || to === undefined ? '/home' : to.trim();
            const { base, params } = urlParts(finalTo);
            const locale = lang || localeContext;

            if (pages[base]) {
              if (defaultTo) {
                const link = pages[base][locale] ? pages[base][locale] + params : defaultTo;
                const localizedLink = localizeLink(locale, link);
                return <>{show && <LinkLocalizerDisplay to={localizedLink} {...rest} />}</>;
              }
              const linkLocale = pages[base][locale] ? locale : Locale.English;
              const localizedLink = localizeLink(linkLocale, pages[base][linkLocale] + params);
              return <>{show && <LinkLocalizerDisplay to={localizedLink} {...rest} />}</>;
            }
            return <>{show && <LinkLocalizerDisplay to={finalTo} {...rest} />}</>;
          }}
        </LocaleContext.Consumer>
      );
    }}
  </PagesContext.Consumer>
);

const LinkLocalizerDisplay = ({ children, to = '/', ...rest }: LinkLocalizerProperties) => {
  to = getRedirectionLink(to) ? getRedirectionLink(to) : to;
  const internal = to[0] === '/';

  if (internal) {
    return (
      <GatsbyLink to={to} {...rest} ref={rest.ref as any}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} {...rest}>
      {children}
    </a>
  );
};

function urlParts(url: string): { params: string; base: string } {
  const matches = url.match(/^([^?#]*)(.*)/);

  if (!matches) {
    return { base: '', params: '' };
  }

  return {
    base: matches[1],
    params: matches[2],
  };
}
