import * as React from 'react';
import { MarkdownRemark } from '../../../interfaces';
import { MarkdownRemarkRenderer } from '../../../utils';
import './vertical-workflow.scss';

interface VerticalWorkflowProperties {
  items: (BlockItem | SideBlockItem)[];
}

interface BlockItem {
  type: 'block';
  title: string;
  icon: MarkdownRemark;
  text: string;
  dottedline?: boolean;
  noLine?: boolean;
}

interface SideBlockItem {
  type: 'side-block';
  text: string;
}

const Block = ({ title, icon, text, dottedline, noLine }: BlockItem) => (
  <div
    className={`item-block ${dottedline !== undefined ? 'item-block--dotted-line' : ''} ${
      noLine ? 'item-block--no-line' : ''
    }`}
  >
    <div className="item-block__icon">
      <div className="icon_wrap">
        <MarkdownRemarkRenderer markdown={icon} />
      </div>
    </div>
    <div className="item-block__title">{title}</div>
    <div className="item-block__text">{text}</div>
  </div>
);

const SideBlock = ({ text }: SideBlockItem) => (
  <div className="item-side-block">
    <div className="item-side-block__text">{text}</div>
  </div>
);

/* eslint-disable array-callback-return */
export const VerticalWorkflow = ({ items }: VerticalWorkflowProperties) => (
  <div className="component-verticalworkflow">
    {items.map((item, i) => {
      if (item.type === 'side-block') {
        return <SideBlock {...item} />;
      }
      if (item.type === 'block') {
        return <Block {...item} noLine={i === items.length - 1} />;
      }
    })}
  </div>
);
/* eslint-enable array-callback-return */
