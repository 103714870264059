import { Link } from '@reach/router';
import * as React from 'react';
import { CustomComponentWrap, MultiColumn, Translate } from '../../components';
import { ContentfulBlogPost } from '../../interfaces';
import { calculateSrcset, fixContentfulAssetPath, localizeLink } from '../../utils';

export const CarouselBlogPostTemplate = ({
  items: [blogpost],
}: {
  items: ContentfulBlogPost[];
}) => (
  <CustomComponentWrap className={['box-shadow']} style={{ backgroundColor: '#fff' }}>
    <MultiColumn imagecrop="375px" breakOnTablet spaceDistribution="2,1" gap="25px">
      {blogpost.heroImage && (
        <Link to={localizeLink(blogpost.fields.locale, `/blog/${blogpost.slug}`)}>
          <img
            alt={blogpost.heroImage.title}
            src={fixContentfulAssetPath(blogpost.heroImage.file.url)}
            srcSet={calculateSrcset(blogpost.heroImage.file.url, 1000)}
          />
        </Link>
      )}
      <div className="padding-small">
        {blogpost.title && <h3>{blogpost.title}</h3>}
        {blogpost.excerpt && <p>{blogpost.excerpt}</p>}
        <Link to={localizeLink(blogpost.fields.locale, `/blog/${blogpost.slug}`)}>
          <Translate id="component.readMore" />
        </Link>
      </div>
    </MultiColumn>
  </CustomComponentWrap>
);
