import { MarkdownRemark } from '../../../interfaces';
import { getMarkdownText } from '../../../utils';
import { ImageTile } from './image-tiles';

interface ImageTilesContentfulProperties {
  items: {
    figure: MarkdownRemark;
    title: MarkdownRemark;
    subtitle: MarkdownRemark;
    link: MarkdownRemark;
  }[];
}

export const ImageTilesContentfulPropertiesTransformer = ({
  items = [],
}: ImageTilesContentfulProperties) => {
  const transformedTiles: ImageTile[] = items.map(x => ({
    image: x.figure,
    title: x.title,
    subtitle: x.subtitle,
    link: getMarkdownText(x.link),
  }));

  return {
    items: transformedTiles,
  };
};
