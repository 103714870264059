import * as React from 'react';
import { ContentfulAsset } from '../../interfaces';
import './background-wrapper.scss';

const addHash = (color: string) => (color[0] === '#' ? color : `#${color}`);

export const BackgroundWrapper = ({
  scrollable,
  color,
  image,
  children,
}: {
  scrollable: boolean;
  color: string;
  image: ContentfulAsset;
  children: React.ReactNode;
}) => {
  const scrollableImage = {
    backgroundImage: `url(${image && image.file.url})`,
    backgroundColor: addHash(color || ''),
    backgroundRepeat: 'repeat-x',
  };

  return (
    <div style={scrollable ? scrollableImage : {}}>
      {!scrollable && <Background color={color} image={image} />}
      {children}
    </div>
  );
};

const Background = ({ color, image }: { color: string; image: ContentfulAsset }) => (
  <>
    {color && (
      <div
        className="background-wrapper"
        style={{
          backgroundColor: addHash(color),
        }}
      />
    )}
    {image && (
      <div className="background-wrapper">
        <img
          alt={image.title}
          src={`${image.file.url}`}
          srcSet={`
            ${image.file.url}?w=480&fm=jpg&q=80 480w,
            ${image.file.url}?w=850&fm=jpg&q=80 850w,
            ${image.file.url}?w=1400&fm=jpg&q=80 1400w,
            ${image.file.url} 2560w
          `}
        />
      </div>
    )}
  </>
);
