import * as React from 'react';
import { Translate } from '../..';
import { AuthContext } from '../../contexts';

export const UserWelcome = ({}) => {
  const { isAuthenticated, user } = React.useContext(AuthContext);
  if (isAuthenticated && user) {
    return (
      <div>
        <h1>
          <Translate id="component.userWelcome.personalWelcome" />
          {' ' + user.firstName}
        </h1>
        <h2>
          <Translate id="component.userWelcome.mainWelcome" />
        </h2>
      </div>
    );
  }
  return (
    <div>
      <h2>
        <Translate id="component.userWelcome.mainWelcome" />
      </h2>
    </div>
  );
};
