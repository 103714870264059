interface DownloadBlockContentfulProperties {
  product: string;
  availableos?: string;
  productversion?: string;
  redirectlink?: string;
}

export const DownloadBlockContentfulPropertiesTransformer = ({
  availableos = '',
  product,
  redirectlink,
  productversion,
}: DownloadBlockContentfulProperties) => {
  return {
    availableOS: availableos.split(','),
    product,
    redirectLink: redirectlink,
    productVersion: productversion,
  };
};
