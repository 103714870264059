import * as React from 'react';
import { ContentfulFrame } from '../interfaces';
import { FramePageDisplay } from './frame-page-display';

interface FramePageControllerProperties {
  isHomePage: boolean;
  frames: ContentfulFrame[];
  theme?: string;
  useDarkTheme: boolean;
}

export const FramePageController = (props: FramePageControllerProperties) => (
  <FramePageDisplay
    isHomePage={props.isHomePage}
    frames={props.frames}
    theme={props.theme}
    isDarkTheme={props.useDarkTheme}
  />
);
