import { assoc, takeWhile } from 'ramda';
import { MarkdownRemarkChild, MarkdownRemarkElement } from '../../../interfaces';
import { isPropertyNode } from '../is-property-node';

const CHECK_ELEMENTS = ['link'];

export const PatchSelfClosingPropertiesTransformer = (
  root: MarkdownRemarkElement,
): MarkdownRemarkElement => {
  const newChildren: MarkdownRemarkChild[] = [];

  for (let index = 0; index < root.children.length; ) {
    const element = root.children[index++];

    if (
      element.type === 'element' &&
      isPropertyNode(element) &&
      CHECK_ELEMENTS.indexOf(element.tagName) !== -1
    ) {
      const rakoncatlanElementek = takeWhile(
        x => x.type === 'text' || (x.type === 'element' && !isPropertyNode(x)),
        root.children.slice(index),
      );
      element.children.push(...rakoncatlanElementek);
      index += rakoncatlanElementek.length;
    }

    newChildren.push(element);
  }

  return assoc('children', newChildren, root);
};
