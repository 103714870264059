import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Translate } from '../../components';

export const PageFragmentWrap = ({ children }) => (
  <div>
    <Helmet>
      <title>
        <Translate id="pageFragment.recentPosts.title" />
      </title>
      <meta name="robots" content="noindex" />
      <meta name="robots" content="nofollow" />
    </Helmet>
    {children}
  </div>
);
