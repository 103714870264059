import * as React from 'react';

type Position = { x: number; y: number };
export type TouchMove = { up: number; left: number };
export enum Direction {
  Top,
  Right,
  Down,
  Left,
}

export interface GestureProperties {
  swipeTreashold?: number;
  className?: string;
  onSwipe?: (direction: Direction) => void;
  onTouchMove?: (move: TouchMove, speed: number) => void;
}

export class Gesture extends React.PureComponent<GestureProperties> {
  startPosition: Position | null = null;

  onTouchEnd = (event: React.TouchEvent) => {
    if (this.startPosition === null || event.changedTouches.length === 0) {
      return;
    }

    const position: Position = {
      x: event.changedTouches[0].screenX,
      y: event.changedTouches[0].screenY,
    };

    const down = position.y - this.startPosition.y;
    const up = this.startPosition.y - position.y;
    const right = position.x - this.startPosition.x;
    const left = this.startPosition.x - position.x;

    if (this.props.onTouchMove) {
      this.props.onTouchMove({ up, left }, event.timeStamp);
    }

    this.startPosition = null;

    const max = Math.max(down, up, right, left);

    if (this.props.swipeTreashold !== undefined && this.props.swipeTreashold > max) {
      return;
    }

    if (!this.props.onSwipe) {
      return;
    }
    if (max === up) {
      this.props.onSwipe(Direction.Top);
    }
    if (max === down) {
      this.props.onSwipe(Direction.Down);
    }
    if (max === left) {
      this.props.onSwipe(Direction.Left);
    }
    if (max === right) {
      this.props.onSwipe(Direction.Right);
    }
  };

  onTouchStart = (event: React.TouchEvent) => {
    this.startPosition = {
      x: event.changedTouches[0].screenX,
      y: event.changedTouches[0].screenY,
    };
  };

  render() {
    return (
      <div
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
        className={this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}
